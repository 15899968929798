import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty, uniqBy, first } from 'lodash'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import moment from 'moment'
import swal from '@sweetalert/with-react'
import { reset } from 'redux-form'
import { Row, Col, Spin, Steps, Icon, message, Tabs } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import { ROUTH_PATH, functionAlias } from 'routes'
import { formatNumber, getPermissionPage, isOutletIdAuth } from 'helpers'
import RevisionTable from './RevisionTable'
import RejectionModal from './RejectionModal'
import SaleOrderCreateModal from './SaleOrderCreateModal'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    InputSearchCustom,
    NumberFormatCustom,
    InputCustom,
    TextAreaCustom,
    SelectCustom,
} from 'components/common'
import {
    ProductSearchModal,
    QuotationInfoFormRedux,
    SaleCustomerInfoFormRedux,
    StoreLocationModal,
    SaleUnitModal,
    VendorSearchModal,
    PromotionModal,
} from 'components/feature'
import {
    SaleSummary,
    SaleDiscountItemModal,
    JobIdBOQSearchModal,
} from 'components/form'
import './style.css'
import queryString from 'query-string'
const { Step } = Steps
const { TabPane } = Tabs

const initialProduct = {
    barcode: '',
    productCode: '',
}

const initialCustomerInfo = {
    scgId: '',
}

const customerDefault = {
    customerId: 'OTC99',
    customerNameTH: 'ลูกค้าทั่วไป',
    customerTaxType: 'V7',
    customerPaymentTerm: 'NT00',
}

const quotationDocumentTypeCode = 'ZQT'

class QuotationMaintainContainer extends Component {
    constructor(props) {
        super(props)
        this.state = this.getInitialState()
        this.onPrintFormQuotation = this.onPrintFormQuotation.bind(this)
    }

    getInitialState = () => {
        const permissionPage = getPermissionPage(functionAlias.quotationMaintain)
        return {
            quotationDetail: {
                promotionCode: '',
            },
            columns: [
                {
                    title: '',
                    dataIndex: 'delele',
                    key: 'delele',
                    align: 'center',
                    width: 45,
                    render: (props, row, index) => {
                        if (!row.productName) {
                            return null
                        } else if (row.rejectId) {
                            return <Icon type="delete" />
                        } else {
                            return <ButtonCustom
                                text="ลบ"
                                small
                                type="danger"
                                onClick={() => this.deleteProduct(index)}
                                disabled={this.disabledInput(this.state.quotationInfo.documentStatus) || !!row.freeGoodsFlag}
                            />
                        }
                    },
                },
                {
                    title: 'ลำดับ',
                    dataIndex: 'index',
                    key: 'index',
                    align: 'center',
                    render: (props, row, index) => (
                        <div>{index + 1}</div>
                    ),
                },
                {
                    title: 'บาร์โค้ด',
                    dataIndex: 'barcode',
                    key: 'barcode',
                    align: 'center',
                    width: 140,
                    render: (props, row, index) => (
                        (this.state.products.length - 1 === index) ?
                            <InputCustom
                                small
                                onPressEnter={() => this.searchProduct(row)}
                                onChange={(e) => this.changeProductData('barcode', e.target.value, index)}
                                disabled={this.disabledInput(this.state.quotationInfo.documentStatus)}
                            /> : props
                    )
                },
                {
                    title: 'รหัสสินค้า',
                    dataIndex: 'productCode',
                    key: 'productCode',
                    align: 'center',
                    width: 140,
                    render: (value, row, index) => {
                        const disabled = this.disabledInput(this.state.quotationInfo.documentStatus)
                        return (this.state.products.length - 1 === index) ?
                            <InputSearchCustom
                                small
                                onSubmit={() => this.searchProduct(row)}
                                onChange={(e) => this.changeProductData('productCode', e.target.value, index)}
                                onClickSearch={() => this.openModal('product')}
                                disabled={disabled}
                                disabledButton={disabled}
                            /> : value
                    }
                },
                {
                    title: 'ชื่อสินค้า',
                    dataIndex: 'productName',
                    key: 'productName',
                    align: 'center',
                    width: 180,
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'ประเภทสต็อค',
                    dataIndex: 'stockType',
                    key: 'stockType',
                    align: 'center',
                },
                {
                    title: 'คลัง',
                    dataIndex: 'slocId',
                    key: 'slocId',
                    align: 'center',
                    render: (props, row, index) => {
                        if (!row.productName) {
                            return null
                        } else if (this.disabledInput(this.state.quotationInfo.documentStatus)) {
                            return props
                        } else {
                            return <ButtonCustom
                                text={props}
                                small
                                type="link"
                                onClick={() => this.getStoreLocations(row, index)}
                            />
                        }
                    },
                },
                {
                    title: 'จำนวนที่ต้องการ',
                    dataIndex: 'qty',
                    key: 'qty',
                    align: 'center',
                    width: 100,
                    render: (props, row, index) => {
                        if (!row.productName) {
                            return null
                        } else if (this.disabledInput(this.state.quotationInfo.documentStatus)) {
                            return <div className="text-right">{formatNumber(props, 3)}</div>
                        } else {
                            return <NumberFormatCustom
                                className="text-right"
                                small
                                onChange={(e) => this.changeProductData('qty', Number(`${e.target.value}`.replace(/,/g, '')), index)}
                                onKeyPress={(e) => e.key === 'Enter' && this.changeQtyProductData(row, index)}
                                value={props}
                                // disabled={row.rejectId === 1}
                                disabled={!!row.rejectId || !!row.freeGoodsFlag}
                                decimalScale={3}
                            />
                        }
                    },
                },
                {
                    title: 'หน่วย',
                    dataIndex: 'unit',
                    key: 'unit',
                    align: 'center',
                    width: 100,
                    render: (props, row, index) => {
                        if (!row.productName) {
                            return null
                        } else if (this.disabledInput(this.state.quotationInfo.documentStatus)) {
                            return props
                        } else {
                            return <ButtonCustom
                                text={props}
                                small
                                type="link"
                                onClick={() => this.getUnit(row, index)}
                                disabled={!!row.freeGoodsFlag}
                            />
                        }
                    },
                },
                {
                    title: 'ราคา/หน่วย',
                    dataIndex: 'pricePerUnit',
                    key: 'pricePerUnit',
                    align: 'center',
                    width: 100,
                    render: (props, row, index) => {
                        if (!row.productName) {
                            return null
                        } else if (this.state.quotationInfo.requestSpecialPrice || row.flagOpenprice === true) {
                            return <NumberFormatCustom
                                className="text-right"
                                small
                                onChange={(e) => this.changeProductData('pricePerUnit', Number(`${e.target.value}`.replace(/,/g, '')), index)}
                                onKeyPress={(e) => e.key === 'Enter' && this.changePricePerUnitProductData(row, index)}
                                value={props}
                                decimalScale={2}
                            />
                        } else {
                            return <div className="text-right">{formatNumber(props, 2)}</div>
                        }
                    },
                },
                {
                    title: 'ส่วนลด',
                    dataIndex: 'totalDiscAmount',
                    key: 'totalDiscAmount',
                    align: 'center',
                    width: 150,
                    render: (props, row, index) => {
                        const isRequestSpecialPrice = this.state.quotationInfo.requestSpecialPrice;
                        return row.productName && <Row className="text-right" justify="space-between">
                            <Col xs={18} style={{ paddingTop: '3px' }}>
                                <span>{formatNumber(props, 2)}</span>
                            </Col>
                            <Col xs={6}>
                                <ButtonCustom
                                    type="default"
                                    icon="search"
                                    small
                                    onClick={() => this.openModal('discountItem', index)}
                                    disabled={isRequestSpecialPrice}
                                />
                            </Col>
                        </Row>
                    }
                },
                {
                    title: 'จำนวนเงิน',
                    dataIndex: 'netAmount',
                    key: 'netAmount',
                    align: 'center',
                    width: 100,
                    render: (props, row) => (
                        row.productName && <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                },
                {
                    title: 'เพิ่มเติม',
                    dataIndex: 'more',
                    key: 'more',
                    align: 'center',
                    render: (props, row, index) => (
                        row.productName && <ButtonCustom
                            icon="plus"
                            small
                            onClick={() => this.openProductMoreModal(index)}
                            disabled={!this.state.quotationNumber || this.disabledInput(this.state.quotationInfo.documentStatus)}
                        />
                    ),
                },
            ],
            columnsProductSpecialPrice: [
                {
                    title: '',
                    dataIndex: 'delele',
                    key: 'delele',
                    align: 'center',
                    width: 45,
                    render: (props, row, index) => {
                        if (!row.productName) {
                            return null
                        } else if (row.rejectId) {
                            return <Icon type="delete" />
                        } else {
                            return <ButtonCustom
                                text="ลบ"
                                small
                                type="danger"
                                onClick={() => this.deleteProduct(index)}
                                disabled={this.disabledInput(this.state.quotationInfo.documentStatus) || !!row.freeGoodsFlag}
                            />
                        }
                    },
                },
                {
                    title: 'ลำดับ',
                    dataIndex: 'index',
                    key: 'index',
                    align: 'center',
                    render: (props, row, index) => (
                        <div>{index + 1}</div>
                    ),
                },
                {
                    title: 'บาร์โค้ด',
                    dataIndex: 'barcode',
                    key: 'barcode',
                    align: 'center',
                    width: 140,
                    render: (props, row, index) => (
                        (this.state.products.length - 1 === index) ?
                            <InputCustom
                                small
                                onPressEnter={() => this.searchProduct(row)}
                                onChange={(e) => this.changeProductData('barcode', e.target.value, index)}
                                disabled={this.disabledInput(this.state.quotationInfo.documentStatus)}
                            /> : props
                    )
                },
                {
                    title: 'รหัสสินค้า',
                    dataIndex: 'productCode',
                    key: 'productCode',
                    align: 'center',
                    width: 140,
                    render: (value, row, index) => {
                        const disabled = this.disabledInput(this.state.quotationInfo.documentStatus)
                        return (this.state.products.length - 1 === index) ?
                            <InputSearchCustom
                                small
                                onSubmit={() => this.searchProduct(row)}
                                onChange={(e) => this.changeProductData('productCode', e.target.value, index)}
                                onClickSearch={() => this.openModal('product')}
                                disabled={disabled}
                                disabledButton={disabled}
                            /> : value
                    }
                },
                {
                    title: 'ชื่อสินค้า',
                    dataIndex: 'productName',
                    key: 'productName',
                    align: 'center',
                    width: 180,
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'ประเภทสต็อค',
                    dataIndex: 'stockType',
                    key: 'stockType',
                    align: 'center',
                },
                {
                    title: 'คลัง',
                    dataIndex: 'slocId',
                    key: 'slocId',
                    align: 'center',
                    render: (props, row, index) => {
                        if (!row.productName) {
                            return null
                        } else if (this.disabledInput(this.state.quotationInfo.documentStatus)) {
                            return props
                        } else {
                            return <ButtonCustom
                                text={props}
                                small
                                type="link"
                                onClick={() => this.getStoreLocations(row, index)}
                            />
                        }
                    },
                },
                {
                    title: 'จำนวนที่ต้องการ',
                    dataIndex: 'qty',
                    key: 'qty',
                    align: 'center',
                    width: 100,
                    render: (props, row, index) => {
                        if (!row.productName) {
                            return null
                        } else if (this.disabledInput(this.state.quotationInfo.documentStatus)) {
                            return props
                        } else {
                            return <NumberFormatCustom
                                className="text-right"
                                small
                                onChange={(e) => this.changeProductData('qty', Number(`${e.target.value}`.replace(/,/g, '')), index)}
                                onKeyPress={(e) => e.key === 'Enter' && this.changeQtyProductData(row, index)}
                                value={props}
                                disabled={!!row.rejectId || !!row.freeGoodsFlag}
                                decimalScale={3}
                            />
                        }
                    },
                },
                {
                    title: 'หน่วย',
                    dataIndex: 'unit',
                    key: 'unit',
                    align: 'center',
                    width: 100,
                    render: (props, row, index) => {
                        if (!row.productName) {
                            return null
                        } else if (this.disabledInput(this.state.quotationInfo.documentStatus)) {
                            return props
                        } else {
                            return <ButtonCustom
                                text={props}
                                small
                                type="link"
                                onClick={() => this.getUnit(row, index)}
                                disabled={!!row.freeGoodsFlag}
                            />
                        }
                    },
                },
                {
                    title: 'ราคา/หน่วย(Original)',
                    // dataIndex: 'price',
                    // key: 'price',
                    dataIndex: 'priceori',
                    key: 'priceori',
                    align: 'center',
                    width: 100,
                    render: (props, row) => {
                        const isRequestSpecialPrice = this.state.quotationDetail.requestSpecialPrice;
                        let price = 0

                        const saleChannelCode = this.state.quotationInfo.saleChannelCode
                        price = +saleChannelCode === 20 ? row.projectPrice : row.retailPrice

                        return row.productName && <div className="text-right">{formatNumber(price, 2)}</div>
                    },
                },
                {
                    title: 'ราคา/หน่วย',
                    dataIndex: 'price',
                    key: 'price',
                    align: 'center',
                    width: 100,
                    render: (props, row) => {
                        const isRequestSpecialPrice = this.state.quotationDetail.requestSpecialPrice;
                        let price = 0
                        if (this.state.quotationNumber && this.state.outletId) {
                            price = isRequestSpecialPrice === true && row.oldPricePerUnit ? row.oldPricePerUnit : row.pricePerUnit
                        } else {
                            const saleChannelCode = this.state.quotationInfo.saleChannelCode
                            price = +saleChannelCode === 20 ? row.projectPrice : row.retailPrice
                        }
                        return row.productName && <div className="text-right">{formatNumber(price, 2)}</div>
                    },
                },
                {
                    title: 'ราคา/หน่วย(VAT)',
                    dataIndex: 'pricePerUnit',
                    key: 'pricePerUnit',
                    align: 'center',
                    width: 100,
                    render: (props, row, index) => {
                        if (!row.productName) {
                            return null
                        } else if (this.state.quotationInfo.requestSpecialPrice) {
                            return <NumberFormatCustom
                                className="text-right"
                                small
                                onChange={(e) => this.changeProductData('pricePerUnit', Number(`${e.target.value}`.replace(/,/g, '')), index)}
                                onKeyPress={(e) => e.key === 'Enter' && this.changePricePerUnitProductData(row, index)}
                                value={props}
                                decimalScale={2}
                                disabled={this.disabledInput(this.state.quotationInfo.documentStatus) || !!row.rejectId}
                            />
                        } else {
                            return <div className="text-right">{formatNumber(props, 2)}</div>
                        }
                    },
                },
                {
                    title: 'ส่วนลด',
                    dataIndex: 'totalDiscAmount',
                    key: 'totalDiscAmount',
                    align: 'center',
                    width: 100,
                    render: (props, row) => (
                        row.productName && <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                },
                {
                    title: 'ทุน ZCA1 พิเศษ/หน่วย(IN VAT)',
                    dataIndex: 'costPerUnit',
                    key: 'costPerUnit',
                    align: 'center',
                    width: 100,
                    className: permissionPage.authCost ? '' : 'd-none',
                    render: (props, row, index) => {
                        if (isEmpty(row.productCode)) {
                            return null
                        } else {
                            return <NumberFormatCustom
                                className="text-right"
                                small
                                onChange={(e) => {
                                    if (e.target.value !== '') {
                                        return this.changeProductData('costPerUnit', Number(`${e.target.value}`.replace(/,/g, '')), index);
                                    } else if (this.disabledInput(this.state.quotationInfo.documentStatus)) {
                                        return props;
                                    } else {
                                        // เปลี่ยนค่าว่างเป็น null
                                        return this.changeProductData('costPerUnit', null, index);
                                    }
                                }}
                                //onKeyPress={(e) => e.key === 'Enter' && this.changeQtyProductData(row, index)}
                                value={props}
                                disabled={this.disabledInput(this.state.quotationInfo.documentStatus) || !!row.rejectId}
                                decimalScale={2}
                            />
                        }
                    }
                },
                {
                    title: 'ทุน ZCA1 (IN VAT)',
                    dataIndex: 'cost',
                    key: 'cost',
                    align: 'center',
                    className: permissionPage.authCost ? '' : 'd-none',
                    render: (props, row) => {
                        const cost = this.calculateCost(row);
                        return row.productName && <div className="text-right">{formatNumber(cost, 2)}</div>
                    },
                },
                {
                    title: 'จำนวนเงิน',
                    dataIndex: 'netAmount',
                    key: 'netAmount',
                    align: 'center',
                    width: 100,
                    render: (props, row) => (
                        row.productName && <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                },
                {
                    title: 'รหัสผู้ขาย',
                    dataIndex: 'vendorId',
                    key: 'vendorId',
                    align: 'center',
                    width: 120,
                    render: (value, row, index) => {
                        return (index < this.state.products.length - 1) ?
                            <InputSearchCustom
                                small
                                onSubmit={() => this.searchVendor(row, index)}
                                onChange={(e) => this.changeProductData('vendorId', e.target.value, index)}
                                onClickSearch={() => this.openModal('vendor', index)}
                                maxLength={10}
                                value={value}
                                disabled={this.disabledInput(this.state.quotationInfo.documentStatus) || !!row.rejectId}
                                disabledButton={this.disabledInput(this.state.quotationInfo.documentStatus) || !!row.rejectId}
                            /> : value
                    }
                },
                {
                    title: 'ชื่อผู้ขาย',
                    dataIndex: 'vendorName',
                    key: 'vendorName',
                    align: 'left',
                    width: 140,
                },
                {
                    title: 'PO Doc Type',
                    dataIndex: 'poDocType',
                    key: 'poDocType',
                    align: 'center',
                    width: 200,
                    render: (props, row, index) => (
                        !isEmpty(row.productName) &&
                        <SelectCustom
                            small
                            onChange={(val) => this.changeProductData('poDocType', val, index)}
                            options={this.state.poDocTypes}
                            value={props}
                            disabled={this.state.disabledEditData || this.disabledInput(this.state.quotationInfo.documentStatus) || !!row.rejectId}
                        // disabled={this.state.disabledEditData}
                        />
                    ),
                },
                {
                    title: 'เพิ่มเติม',
                    dataIndex: 'more',
                    key: 'more',
                    align: 'center',
                    render: (props, row, index) => {
                        if (!row.productName) {
                            return null
                        } else if (this.disabledInput(this.state.quotationInfo.documentStatus)) {
                            return props
                        } else {
                            return <ButtonCustom
                                icon="plus"
                                small
                                onClick={() => this.openProductMoreModal(index)}
                                disabled={!this.state.quotationNumber || this.disabledInput(this.state.quotationInfo.documentStatus) || !this.state.permissionPage.authDelete}
                            />
                        }
                    },
                    // render: (props, row, index) => (
                    //     row.productName && <ButtonCustom
                    //         icon="plus"
                    //         small
                    //         onClick={() => this.openProductMoreModal(index)}
                    //         disabled={!this.state.quotationNumber || this.disabledInput(this.state.quotationInfo.documentStatus) || !this.state.permissionPage.authDelete}
                    //     />
                    // ),
                },
            ],
            loading: false,
            products: [
                initialProduct,
            ],
            promotions: [],
            productsSO: [],
            poDocTypes: [],
            quotationInfo: {
                quotationNumber: '',
                documentStatus: 'OPEN',
                version: 1,
                saleChannelCode: '',
                startDate: moment(),
                expireDate: moment().add(1, 'month').add(1, 'day'),
                jobId: '',
                solutionType: '',
                shippingPoint: '',
                saleEmployeeCode: '',
                outletId: '',
                requestSpecialPrice: false,
            },
            revisions: [],
            storeLocations: [],
            outlets: [],
            documentStatuses: [],
            solutions: [],
            saleChannels: [],
            shippingPoints: [],
            saleEmployees: [],
            rejections: [],
            isErrorSubmitProduct: false,
            isErrorSubmitCustomer: false,
            isErrorSubmitCustomerShipping: false,
            isErrorSubmitQuotation: false,
            isShowModal: false,
            modalType: '',
            remark: '',
            purchasingContract: '',
            customerInfo: initialCustomerInfo,
            customerIdSearch: customerDefault.customerId,
            customer: customerDefault,
            customerShipping: {},
            customerShippings: [],
            totalSummary: {
                totalAmount: 0,
                totalDiscAmount: 0,
                netAmount: 0,
                vatAmount: 0,
                discPercent: 0,
                discPercentAmount: 0,
                discAmount: 0,
                discPromotionInternal: 0,
                discPromotionExternal: 0,
                discCouponPercent: 0,
                discCouponPercentAmount: 0,
                discSCGIDCode: '',
                discSCGIDAmount: 0,
                discCouponAmount: 0,
            },
            indexRowAction: null,
            quotationNumber: '',
            outletId: '',
            permissionPage,
            isAuthApproveSalesDiscount: false,
        }
    }

    componentDidMount() {
        const outletId = get(this.props, 'match.params.outletId')
        if (outletId && !isOutletIdAuth(outletId)) {
            this.getAlertAccessDenied()
            return
        }

        this.initiatData()
    }

    componentDidUpdate(prevProps) {
        const params = this.props.match.params
        const paramsPrev = prevProps.match.params
        if (params.id !== paramsPrev.id || params.outletId !== paramsPrev.outletId) {
            this.initiatData()
        }
    }

    getAlertAccessDenied = () => {
        swal('Error', 'คุณไม่มีสิทธิ์ในการเข้าถึงข้อมูล', 'error').then(() => {
            this.props.history.push(ROUTH_PATH.ACCESS_DENIED)
        })
    }

    initiatData = () => {
        const { quotationNumber, outletId } = get(this.props, 'match.params', {})
        if (quotationNumber && outletId) {
            if (!this.state.permissionPage.authDisplay) {
                this.getAlertAccessDenied()
                return
            }

            this.setState({ quotationNumber, outletId }, () => this.fetchDataDefault())
        } else {
            this.fetchDataDefault()
        }
    }

    resetState = () => {
        this.setState(this.getInitialState())
    }

    disabledInput = (documentStatus) => {
        return !!(documentStatus === 'COMPLETE' || documentStatus === 'CANCEL' || documentStatus === 'SALE')
    }

    checkValidateApproveSalesDiscountAuth = async () => {
        const { auth } = this.props
        //Get ValidateApproveSalesDiscountAuth
        await apiServices.callApi('get', `${API_PATH.GET_VALIDATE_DISCOUNT_AUTH}?UserId=${auth.userId}`).then(res => {

            if (res.status === 200) {
                let statusResult = get(res, 'data.status')
                if (statusResult === 'Success') {
                    this.setState({ isAuthApproveSalesDiscount: true })
                }
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถตรวจสอบสิทธิ์อนุมัติใบเสนอราคากรณีให้ส่วนลดระดับรายการสินค้า!')
            //swal('Error', message, 'error')
            console.log(`Error: ${message}`);
        })
    }

    getQuotationDetail = async (quotationNumber, outletId) => {
        await apiServices.callApi('get', `${API_PATH.GET_QUOTATION}?quotationnumber=${quotationNumber}&outletId=${outletId}`).then(async (res) => {
            const detail = get(res, 'data.0')
            if (res.status === 200 && !isEmpty(detail)) {
                if (detail.documentStatus === 'BLOCK' && !isEmpty(detail.quotationItemModelList.filter(item => ((item.discAmount || 0) > 0) || ((item.discAmountPerUnitAmount || 0) > 0) || ((item.discPercentAmount || 0) > 0)))) {
                    await this.checkValidateApproveSalesDiscountAuth();
                }

                const partnerModels = detail.quotationPartnerFunctionModelList.map(partner => ({
                    salesQuotationCustomerId: partner.salesQuotationCustomerId,
                    outletId: partner.outletId,
                    quotationNumber: partner.quotationNumber,
                    customerId: partner.customerId,
                    customerRole: partner.customerRole,
                    customerType: partner.customerType,
                    customerTitle: partner.title,
                    customerNameTH: partner.customerName,
                    customerTaxId: partner.taxId,
                    customerTaxBranch: partner.taxBranch,
                    customerScgFamilyId: '',
                    customerAddress: partner.address,
                    customerSubDistrict: partner.subDistrict,
                    customerDistrict: partner.district,
                    customerProvince: partner.province,
                    customerCountry: partner.country,
                    customerPostalCode: partner.postalCode,
                    customerPhone: partner.phone,
                    customerFax: partner.fax,
                    customerEmail: partner.email,
                }))
                const customer = partnerModels.find(partner => partner.customerRole === 'sold_to')
                const customerShipping = partnerModels.find(partner => partner.customerRole === 'ship_to')
                const products = detail.quotationItemModelList.map(product => {
                    const productPriceSellingModel = get(product, 'productPriceSellingModel', {})
                    return {
                        ...product,
                        oldPricePerUnit: product.pricePerUnit,
                        salesQuotationItemId: product.salesQuotationItemId,
                        totalAmount: product.pricePerUnit * product.qty,
                        retailPrice: productPriceSellingModel.retailPrice || 0,
                        projectPrice: productPriceSellingModel.projectPrice || 0,
                        retailCost: productPriceSellingModel.retailCost || 0,
                        projectCost: productPriceSellingModel.projectCost || 0,
                    }
                })
                const lastVersion = detail.quotationRevisionModelList[detail.quotationRevisionModelList.length - 1]
                const {
                    discAmount,
                    discCouponAmount,
                    discCouponPercent,
                    discCouponPercentAmount,
                    discPercent,
                    discPercentAmount,
                    discPromotionInternal,
                    discPromotionExternal,
                    discSCGIDAmount,
                    discSCGIDCode,
                    netAmount,
                    totalAmount,
                    totalDiscAmount,
                    totalExcVatAmount,
                    vatAmount,
                } = lastVersion
                const totalSummary = {
                    discAmount,
                    discCouponAmount,
                    discCouponPercent,
                    discCouponPercentAmount,
                    discPercent,
                    discPercentAmount,
                    discPromotionInternal,
                    discPromotionExternal,
                    discSCGIDAmount,
                    discSCGIDCode,
                    netAmount,
                    totalAmount,
                    totalDiscAmount,
                    totalExcVatAmount,
                    vatAmount,
                }
                this.setState({
                    totalSummary,
                    quotationInfo: {
                        quotationNumber: detail.quotationNumber,
                        documentStatus: detail.documentStatus,
                        version: lastVersion.versionNumber,
                        saleChannelCode: detail.saleChannelCode,
                        startDate: moment(detail.startDate),
                        expireDate: moment(detail.expireDate),
                        jobId: detail.jobId,
                        solutionType: +detail.solutionType,
                        shippingPoint: get(products, '0.shippingPoint'),
                        saleEmployeeCode: detail.saleEmpCode,
                        outletId: detail.outletId,
                        requestSpecialPrice: !!detail.requestSpecialPrice,
                    },
                    customerInfo: {
                        scgId: detail.scgid,
                    },
                    quotationDetail: detail,
                    remark: detail.remark,
                    purchasingContract: detail.purchasingContract,
                    products: [...products, initialProduct],
                    revisions: detail.quotationRevisionModelList,
                }, () => {
                    if (!customer.customerScgFamilyId) {
                        customer.customerScgFamilyId = detail.scgid
                    }
                    //  this.getProducts()
                    this.onSelectedCustomer(customer, customerShipping)
                })
            } else {
                swal({
                    title: 'Error',
                    icon: 'error',
                    text: 'ไม่พบข้อมูลใบเสนอราคา',
                    buttons: {
                        create: { text: 'สร้างใบเสนอราคา', value: true },
                        cancel: 'ปิด',
                    },
                }).then((value) => {
                    if (value) {
                        this.props.history.push(ROUTH_PATH.SALE_ORDER_CREATE)
                    }
                })
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลใบเสนอราคาได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })
    }

    // get data default for dropdown
    fetchDataDefault = async () => {
        this.setState({ loading: true })
        const { auth: { arrayOutlet } } = this.props
        const { quotationNumber, outletId } = this.state
        const isEditMode = !!(quotationNumber && outletId)

        // sale order detail
        if (isEditMode) {
            await this.getQuotationDetail(quotationNumber, outletId)
            if (!this.state.quotationDetail.quotationNumber) {
                this.setState({ loading: false })
                return
            }
        }

        // outlet - get outlets of sale order detail and authorized user
        const outletIds = isEditMode ? [...arrayOutlet, outletId] : arrayOutlet
        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, { outletIds }).then(res => {
            let outlets = get(res, 'data.results', [])
            if (res.status === 200) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                const outletIdDefault = get(outlets, '0.value', '')
                const { quotationInfo } = this.state
                this.setState({
                    outlets,
                    quotationInfo: (isEditMode) ? quotationInfo : { ...quotationInfo, outletId: outletIdDefault },
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // document status
        await apiServices.callApi('get', `${API_PATH.GET_MASTER_SALE_ORDER_STATUS}/QT`).then(res => {
            let documentStatuses = get(res, 'data.results', [])
            if (res.status === 200) {
                documentStatuses = documentStatuses.map(data => (
                    { ...data, label: data.statusDescription, value: data.statusCode }
                ))
                this.setState({ documentStatuses })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลสถานะเอกสารได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // sale channel
        await apiServices.callApi('get', API_PATH.GET_MASTER_SALE_CHANNEL).then(res => {
            let saleChannels = get(res, 'data.results', [])
            if (res.status === 200) {
                saleChannels = saleChannels.map(data => (
                    { ...data, label: `${data.saleChannelCode} - ${data.saleChannelDescription}`, value: data.saleChannelCode }
                ))
                const { quotationInfo } = this.state
                this.setState({
                    saleChannels,
                    quotationInfo: (isEditMode) ? quotationInfo : { ...quotationInfo, saleChannelCode: saleChannels[0].value },
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลช่องทางได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // sale employee
        await this.getEmployees()

        // solution
        await apiServices.callApi('get', `${API_PATH.GET_MASTER_QUOTATION_SOLUTION}/TH`).then(res => {
            let solutions = get(res, 'data.results', [])
            if (res.status === 200) {
                solutions = solutions.map(data => (
                    { ...data, label: data.solutionDescription, value: data.solutionId }
                ))
                this.setState({ solutions })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Solution ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // sale shipping point
        await apiServices.callApi('get', `${API_PATH.GET_MASTER_SALE_SHIPPING_POINT}?ActiveStatus=true`).then(res => {
            let shippingPoints = get(res, 'data.results', [])
            if (res.status === 200) {
                shippingPoints = shippingPoints.map(data => (
                    { ...data, label: `${data.shippingPointCode} - ${data.shippingPointDescription}`, value: data.shippingPointCode }
                ))
                this.setState({ shippingPoints })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลเงื่อนไขการส่งมอบได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        if (isEditMode) {
            await this.calculateTotalSummary()

            // rejections 
            await apiServices.callApi('get', `${API_PATH.GET_MASTER_SALE_REJECT}/QT`).then(res => {
                let rejections = get(res, 'data.results', [])
                if (res.status === 200) {
                    rejections = rejections.map(data => (
                        { ...data, label: data.rejectDescription, value: data.rejectId }
                    ))
                    this.setState({ rejections })
                } else {
                    throw res
                }
            }).catch(error => {
                const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลเหตุผลการปฏิเสธได้ กรุณาลองใหม่อีกครั้ง')
                swal('Error', message, 'error')
            })
        } else {
            // remark
            await this.getRemark()
        }

        //PO Doc Type
        await apiServices.callApi('get', `${API_PATH.GET_MASTER_PO_DOC_TYPE}`).then(res => {
            let poDocTypes = get(res, 'data.results', [])
            if (res.status === 200) {
                poDocTypes = poDocTypes.map(data => (
                    { ...data, label: `${data.poDocType} - ${data.poDocTypeName}`, value: data.poDocType }
                ))

                poDocTypes = [
                    { label: 'กรุณาเลือก', value: null },
                    ...poDocTypes,
                ]
                this.setState({ poDocTypes })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลประเภทใบสั่งซื้อได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        this.setState({ loading: false })
    }

    getRemark = async () => {
        const { quotationInfo: { outletId: quotationInfoOutletId } } = this.state
        await apiServices.callApi('get', `${API_PATH.GET_COMMON_DEFAULT_REMARK}/${quotationInfoOutletId}/${quotationDocumentTypeCode}/true`).then(res => {
            const remark = get(res, 'data.results.0.remarkDescription', '')
            if (res.status === 200) {
                this.setState({ remark })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลหมายเหตุได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })
    }

    getStoreLocations = async (product, index) => {
        this.setState({ loading: true })
        const { auth } = this.props
        const body = {
            productId: product.productCode,
            unitId: product.unit,
            outletId: product.outletId,
            isIncludeStockATP: true,
            storageLocationIds: auth.arrayStorageLocationId,
            limitMaxRange: 100,
            productStatus: true
        }
        await apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCT}?getBy=${auth.userId}`, body).then(res => {
            let storeLocations = get(res, 'data.results')
            if (res.status === 200) {
                storeLocations = uniqBy(storeLocations, 'storageLocationId')
                this.setState({
                    storeLocations,
                    indexRowAction: index,
                }, () => {
                    this.openModal('storeLocation')
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลคลังได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    getEmployees = async () => {
        const { quotationInfo: { outletId } } = this.state
        await apiServices.callApi('get', `${API_PATH.GET_SALE_EMPLOYEE}?OutletId=${outletId}`).then(res => {
            let saleEmployees = get(res, 'data.results', [])
            if (res.status === 200) {
                saleEmployees = saleEmployees.map(data => (
                    { ...data, label: `${data.saleEmployeeId} - ${data.saleEmployeeName}`, value: data.saleEmployeeId }
                ))
                this.setState({ saleEmployees })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลพนักงานขายได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })
    }

    changeQuotationInfo = (key, val) => {
        let { products } = this.state
        const { QuotationInfoFormRedux: { values } } = this.props
        switch (key) {
            case 'outletId':
                // if outlet id changed - clear products, employee, customer and fetch new employees, products, remark
                this.setState({
                    quotationInfo: { ...values, outletId: val, saleEmployeeCode: '' },
                    products: [initialProduct],
                }, () => {
                    this.resetCustomerInfo()
                    this.calculateTotalSummary()
                    this.getEmployees()
                    this.getRemark()
                })
                break
            case 'saleChannelCode':
                // change price products
                this.setState({
                    quotationInfo: { ...values, saleChannelCode: val },
                }, () => {
                    this.setState({
                        products: products.map(product => {
                            const pricePerUnit = this.calculatePrice(product)
                            return ({
                                ...product,
                                oldPricePerUnit: pricePerUnit,
                                pricePerUnit,
                                costPerUnit: this.calculateCost(product),
                                totalAmount: pricePerUnit * product.qty,
                            })
                        }),
                    }, () => {
                        this.calculateTotalSummary()
                    })
                })
                break
            case 'requestSpecialPrice':
                products = products.map(product => {
                    return ({
                        ...product,
                        discPromotionInternal: 0,
                    })
                })
                this.setState({ quotationInfo: { ...values, requestSpecialPrice: val }, products }, () => this.calculateTotalSummary())
                break
            default:
        }
    }

    onSelectedStoreLocation = (data) => {
        const { indexRowAction } = this.state
        const productSelected = data.dataSelected
        this.setState({
            products: this.state.products.map((product, i) => (
                i === indexRowAction ? {
                    ...product,
                    slocId: productSelected.storageLocationId,
                    atp: productSelected.qty,
                } : product
            )),
            indexRowAction: null,
        })
        this.closeModal()
    }

    getUnit = async (product, index) => {
        this.setState({ loading: true })
        const body = {
            productId: product.productCode,
            storageLocationId: product.slocId,
            outletId: product.outletId,
            productStatus: true
        }
        await apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCT}?getBy=${this.props.auth.userId}`, body).then(res => {
            let units = get(res, 'data.results')
            units = units.map(unit => ({
                ...unit,
                oldPricePerUnit: this.calculatePrice(unit),
                pricePerUnit: this.calculatePrice(unit)
            }))
            if (res.status === 200) {
                this.setState({
                    units,
                    indexRowAction: index,
                }, () => {
                    this.openModal('unit')
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลหน่วยสินค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    onSelectedUnit = async (productSelected) => {
        const { indexRowAction, quotationInfo } = this.state
        const { auth } = this.props
        this.setState({ loading: true })
        const body = {
            //barcode: productSelected.barcode,
            unitId: productSelected.unitId,
            productId: productSelected.productId,
            outletId: productSelected.outletId,
            storageLocationId: productSelected.storageLocationId,
            productStatus: true,
        }
        await apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCT}?getBy=${auth.userId}`, body).then(res => {
            const products = get(res, 'data.results')
            if (res.status === 200 && !isEmpty(products)) {
                let product = products.find(product => product.unitId === productSelected.unitId && product.productId === productSelected.productId && product.outletId === productSelected.outletId)
                if (!isEmpty(product)) {
                    productSelected.pricePerUnit = this.calculatePrice(product)
                    productSelected.retailPrice = product.retailPrice || 0
                    productSelected.projectPrice = product.projectPrice || 0
                    productSelected.retailCost = product.retailCost || 0
                    productSelected.projectCost = product.projectCost || 0
                }
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลสินค้าได้ กรุณาลองใหม่อีกครั้ง')
            console.log(message)
        }).finally(() => {
            this.setState({
                products: this.state.products.map((product, i) => (
                    i === indexRowAction ? {
                        ...product,
                        unit: productSelected.unitId,
                        barcode: productSelected.barcode,
                        oldPricePerUnit: productSelected.pricePerUnit,
                        pricePerUnit: productSelected.pricePerUnit,
                        totalAmount: productSelected.pricePerUnit * product.qty,
                        retailPrice: productSelected.retailPrice,
                        projectPrice: productSelected.projectPrice,
                        retailCost: productSelected.retailCost,
                        projectCost: productSelected.projectCost,
                    } : product
                )),
                indexRowAction: null,
                loading: false,
            }, () => this.calculateTotalSummary())
        })
        this.closeModal()
    }

    openModal = (modalType, selectRow) => {
        const row = selectRow !== undefined ? selectRow : this.state.indexRowAction
        this.setState({ isShowModal: true, modalType, indexRowAction: row })
    }

    closeModal = () => {
        this.setState({ isShowModal: false, modalType: '' })
    }

    resetCustomerInfo = () => {
        this.setState({
            customerIdSearch: customerDefault.customerId,
            customer: customerDefault,
            customerShipping: {},
            customerShippings: [],
        })
    }

    changeCustomerIdSearch = (customerId) => {
        this.setState({ customerIdSearch: customerId })
    }

    onSelectedCustomer = async (customer, customerShipping = {}) => {
        this.closeModal()
        const {
            quotationInfo: { outletId },
            customerInfo,
        } = this.state;
        if (isEmpty(customer)) return
        const url = `${API_PATH.GET_CUSTOMER}?OutletId=${outletId}&customerId=${customer.customerId}&RequireShipTo=true`
        await apiServices.callApi('get', url).then(res => {
            const customerShippings = get(res, 'data.results', [])
            if (res.status === 200) {
                this.setState({
                    customer,
                    customerShipping: !isEmpty(customerShipping) ? customerShipping : customer,
                    customerShippings: [...customerShippings, customer],
                    customerInfo: {
                        ...customerInfo,
                        //scgId: customer.customerScgFamilyId ? customer.customerScgFamilyId : customerInfo.scgId,
                        scgId: customer.customerScgFamilyId ? customer.customerScgFamilyId : '',
                        customerCreditLimit: formatNumber(customer.customerCreditLimit, 2),
                        customerUsedCredit: formatNumber(customer.customerUsedCredit, 2),
                        customerCreditRemain: formatNumber(customer.customerCreditLimit || 0 - customer.customerUsedCredit || 0, 2),
                    },
                    customerIdSearch: customer.customerId,
                    isErrorSubmitCustomer: false,
                    isErrorSubmitCustomerShipping: false,
                })
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลลูกค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        try {
            // คำนวน ส่วนลด
            await this.calculateTotalSummary()
        } catch (error) {
            const message = get(error, 'response.data.message', 'ไม่สามารถคำนวนราคาได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }
    }

    onSelectedShipping = (shipping) => {
        this.setState({ customerShipping: shipping })
    }

    changeCustomerInfo = (customer) => {
        this.setState({ customer })
    }

    searchCustomer = async (customerId) => {
        if (!customerId) {
            message.error('กรุณากรอกรหัสลูกค้า')
            return
        } else if (customerId === customerDefault.customerId) {
            this.onSelectedCustomer(customerDefault)
            return
        }

        this.setState({ loading: true })
        const { quotationInfo: { outletId } } = this.state

        const url = `${API_PATH.GET_CUSTOMER}?OutletId=${outletId}&customerId=${customerId}&RequireSoldTo=true`
        await apiServices.callApi('get', url).then(res => {
            const customers = get(res, 'data.results', [])
            if (res.status === 200 && !isEmpty(customers)) {
                this.onSelectedCustomer(customers[0])
                this.setState({ customers })
            } else {
                message.error('ไม่พบข้อมูลลูกค้า')
                this.resetCustomerInfo()
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลลูกค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    submitSearchJobId = async (jobId) => {
        if (!jobId) {
            message.error('กรุณากรอก Job Id!')
            return
        }

        this.setState({ loading: true })
        const { quotationInfo: { outletId } } = this.state
        const { auth } = this.props

        const url = `${API_PATH.GET_JOB_BOQ}?OutletId=${outletId}&JobId=${jobId}&UserId=${auth.userId}`
        await apiServices.callApi('get', url).then(res => {
            const productBOQ = get(res, 'data.results', [])
            if (res.status === 200 && !isEmpty(productBOQ)) {
                this.setState({ productBOQ, isShowModal: true, modalType: 'jobIdBOQ' })
            } else {
                message.error(`ไม่พบข้อมูล Job Id:${jobId}`)
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถค้นหาข้อมูลสินค้า(BOQ) ได้ กรุณาลองใหม่อีกครั้ง!')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    openProductMoreModal = (index) => {
        this.setState({
            isShowModal: true,
            modalType: 'reject',
            indexRowAction: index,
        })
    }

    changeProductMore = (data) => {
        const { indexRowAction, products } = this.state
        let newProducts = []
        if (data.rejectId) {
            newProducts = products.map((product, i) => (
                i === indexRowAction ? {
                    ...product,
                    itemText: data.itemText,
                    rejectId: data.rejectId,
                    oldPricePerUnit: 0,
                    pricePerUnit: 0,
                    totalAmount: 0,
                } : product
            ))
        } else {
            newProducts = products.map((product, i) => {
                if (i === indexRowAction) {
                    const pricePerUnit = this.calculatePrice(product)
                    return {
                        ...product,
                        itemText: data.itemText,
                        rejectId: data.rejectId,
                        oldPricePerUnit: pricePerUnit,
                        pricePerUnit,
                        totalAmount: pricePerUnit * product.qty,
                    }
                } else {
                    return product
                }
            })
        }
        this.setState({
            products: newProducts,
            indexRowAction: null,
        }, () => this.calculateTotalSummary())
        this.closeModal()
    }

    changeRemark = (e) => {
        this.setState({ remark: e.target.value })
    }

    changePurchasingContract = (e) => {
        this.setState({ purchasingContract: e.target.value })
    }

    deleteProduct = (index) => {
        const products = [...this.state.products]
        products.splice(index, 1)
        this.setState({ products }, () => this.calculateTotalSummary())
    }

    changeProductData = (key, value, index) => {
        this.setState({
            products: this.state.products.map((product, i) => (i === index ? { ...product, [key]: value } : product)),
        })
    }

    changeQtyProductData = (row, index) => {
        this.setState({
            products: this.state.products.map((product, i) => (i === index ? { ...product, totalAmount: row.pricePerUnit * row.qty } : product)),
        }, () => {
            this.calculateTotalSummary()
            message.success('แก้ไขจำนวนสินค้าสำเร็จ')
        })
    }

    changePricePerUnitProductData = (row, index) => {
        this.setState({
            products: this.state.products.map((product, i) => (i === index ? { ...product, totalAmount: row.pricePerUnit * row.qty } : product)),
        }, () => {
            this.calculateTotalSummary()
            message.success('แก้ไขราคาสำเร็จ')
        })
    }

    calculatePrice = (product) => {
        const { QuotationInfoFormRedux: { values } } = this.props
        return +values.saleChannelCode === 10 ? product.retailPrice : product.projectPrice
    }

    calculateCost = (product) => {
        const { QuotationInfoFormRedux: { values } } = this.props

        if (product.freeGoodsFlag === true)
            return 0;
        //return +values.saleChannelCode === 10 ? product.retailCost : product.retailCost
        return +product.retailCost
    }

    onSelectedProduct = (product) => {
        const { products } = this.state
        const { QuotationInfoFormRedux: { values }, } = this.props
        if (product.productType === 'service' && product.flagOpenprice === false /*&& product.flagTransportation === false*/ && values.requestSpecialPrice === false) {
            swal('Error', `กรุณาทำรายการสินค้า <${product.productId}> ผ่านใบเสนอราคาแบบขอราคาพิเศษ!`, 'error')
            return;
        }

        if (product.outletRole === 'Franchise' && product.productType === 'goods') {
            const retailCost = product.retailCost || 0;
            // const retailPrice = product.retailPrice || 0;
            // const projectPrice = product.projectPrice || 0;

            if (retailCost <= 0) {
                message.error('สินค้าไม่ได้ maintain ต้นทุน กรุณาตรวจสอบเพิ่มเติม')
                return
            } 
            /* 23-01-2568 ปลด validate ราคาขาย ต่ำกว่า ทุน
            else if ((retailPrice - retailCost) < 0 && values.saleChannelCode === '10') {
                message.error('สินค้ามี margin ติดลบ กรุณาตรวจสอบเพิ่มเติม')
                return
            } else if ((projectPrice - retailCost) < 0 && values.saleChannelCode === '20') {
                message.error('สินค้ามี margin ติดลบ กรุณาตรวจสอบเพิ่มเติม')
                return
            } */
        }

        this.closeModal()

        const newProducts = [...products]
        newProducts.splice(-1, 1)

        const pricePerUnit = this.calculatePrice(product)
        const qty = 1
        // const discount = 0
        const productMapped = {
            barcode: product.barcode,
            productCode: product.productId,
            productName: product.productNameMarket,
            stockType: product.stockType,
            // slocId: product.storageLocationId,
            slocId: product.productType === 'goods' ? product.storageLocationId : null,
            unit: product.unitId,
            outletId: product.outletId,
            confirmQty: product.confirmQty || 0,
            qty,
            oldPricePerUnit: pricePerUnit,
            pricePerUnit,
            retailPrice: product.retailPrice,
            projectPrice: product.projectPrice,
            retailCost: product.retailCost,
            projectCost: product.projectCost,
            totalAmount: pricePerUnit * qty,
            deliveryDate: moment(),
            freeGoodsFlag: product.freeGoodsFlag || false,
            productType: product.productType,
            flagTransportation: product.flagTransportation,
            flagOpenprice: product.flagOpenprice,
        }

        this.setState({
            products: [...newProducts, productMapped, initialProduct],
            isErrorSubmitProduct: false,
        }, () => {
            this.calculateTotalSummary()
        })
    }

    searchProduct = async (product) => {
        const { quotationInfo } = this.state
        const { barcode, productCode } = product
        if (!barcode && !productCode) {
            message.error('กรุณาระบุบาร์โค้ดหรือรหัสสินค้า')
            return
        }
        this.setState({ loading: true })
        const { auth } = this.props
        const body = {
            barcode,
            productId: productCode,
            outletId: quotationInfo.outletId,
            storageLocationIds: auth.arrayStorageLocationId,
            productStatus: true
        }
        await apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCT}?getBy=${auth.userId}`, body).then(res => {
            const products = get(res, 'data.results')
            if (res.status === 200 && !isEmpty(products)) {
                let productSelected = products.find(product => product.unitId === product.salesUnit)
                if (!productSelected) productSelected = products[0]
                this.onSelectedProduct(productSelected)
            } else if (res.status === 200 && (res.data.status === "NotAvaliable" || res.data.status === "NotFound")) {
                const mes = get(res, 'data.message', 'ไม่สามารถดึงข้อมูลสินค้าได้ กรุณาลองใหม่อีกครั้ง')
                message.error(mes)
            } else {
                message.error(`ไม่พบข้อมูลสินค้า`)
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลสินค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    searchVendor = async (product, index) => {
        let { vendorId } = product

        if (!vendorId) {
            message.error('กรุณาระบุรหัสผู้ขาย!')
            return
        }

        this.setState({ loading: true })

        await apiServices.callApi('get', `${API_PATH.GET_VENDOR}?VendorId=${vendorId}`, "").then(res => {
            let vendor = get(res, 'data.results.0')

            if (res.status !== 200 || isEmpty(vendor)) {
                message.error('ไม่พบข้อมูลผู้ขาย')
            }
            this.setState({ indexRowAction: index }, () => this.onSelectedVendor(vendor))
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถค้นหาข้อมูลผู้ขายได้ กรุณาลองใหม่อีกครั้ง!')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false, indexRowAction: index })
        })
    }

    onSelectedVendor = (vendor) => {
        let { indexRowAction, products } = this.state
        products = products.map((product, i) => (
            i === indexRowAction ? {
                ...product,
                vendorId: vendor && vendor.vendorId ? vendor.vendorId : "",
                vendorName: vendor && vendor.vendorNameThai ? vendor.vendorNameThai : "",
            } : product
        ));
        this.setState({
            products,
            indexRowAction: null,
        }, () => this.closeModal())
    }

    onSubmitProductBOQ = () => {
        let { productBOQ } = this.state

        if (!isEmpty(productBOQ)) {
            productBOQ.forEach(async activeProduct => {
                if (activeProduct.status === true) {
                    await this.searchProduct({ productCode: activeProduct.productNumber });
                }
            }, () => this.setState({ productBOQ: [] }));
        }
        this.closeModal();
    }

    getDocumentStatus = () => {
        const {
            quotationNumber,
            outletId,
            products,
            quotationDetail,
        } = this.state
        const {
            QuotationInfoFormRedux: { values },
        } = this.props
        const isEditMode = !!(quotationNumber && outletId)

        if (!isEditMode) {
            // mode - create
            return (values.requestSpecialPrice) ? 'OPEN' : 'APPROVE'
        } else {
            // mode - edit
            const newProducts = [...products]
            newProducts.splice(-1, 1)

            const documentStatus = quotationDetail.documentStatus
            const productsReject = newProducts.filter(product => product.rejectId)
            const isCancelQuotation = newProducts.length === productsReject.length
            if (isCancelQuotation) {
                // reject all product
                return 'REJECT'
            }
            // else if (!quotationDetail.requestSpecialPrice) {
            //     // normal price
            //     return 'APPROVE'
            // }
            /*else if (documentStatus === 'APPROVE') {
                // data changed
                let isChanged = newProducts.length !== quotationDetail.quotationItemModelList.length
                if (isChanged) {
                    return 'BLOCK'
                }
                const dataChanged = quotationDetail.quotationItemModelList.filter((product, index) => {
                    const newData = products[index]
                    if (product.barcode !== newData.barcode
                        || product.productCode !== newData.productCode
                        || product.qty !== newData.qty
                        || product.unit !== newData.unit
                        || product.pricePerUnit !== newData.pricePerUnit
                        || product.costPerUnit !== newData.costPerUnit
                        || product.totalAmount !== newData.totalAmount
                        || product.rejectId !== newData.rejectId
                    ) {
                        return true
                    } else {
                        return false
                    }
                })
                return isEmpty(dataChanged) ? documentStatus : 'BLOCK'
            } */
            else {
                return documentStatus
            }
        }
    }

    getParams = () => {
        const {
            customer,
            customerShipping,
            products,
            saleEmployees,
            totalSummary,
            remark,
            purchasingContract,
            quotationDetail,
            quotationNumber,
            outletId,
        } = this.state
        const isEditMode = !!(quotationNumber && outletId)
        const isCustomerDefault = customer.customerId === customerDefault.customerId
        const newProducts = [...products]
        newProducts.splice(-1, 1)

        const {
            auth: { userId },
            QuotationInfoFormRedux: { values: valuesQuotation = {} },
            SaleCustomerInfoFormRedux: { values: valuesCustomer = {} },
        } = this.props

        let partnersModel = null

        if (!isEmpty(customer)) {
            let partners = []
            if (!isEditMode && isCustomerDefault) {
                partners = [{ ...customer, customerRole: 'sold_to' }, { ...customerDefault, customerRole: 'ship_to' }]
            } else {
                partners = [{ ...customer, customerRole: 'sold_to' }, { ...customerShipping, customerRole: 'ship_to' }]
            }

            partnersModel = partners.map(partner => ({
                salesQuotationCustomerId: partner.salesQuotationCustomerId || 0,
                quotationNumber: valuesQuotation.quotationNumber || '',
                outletId: partner.customerOutletId || valuesQuotation.outletId || '',
                customerId: partner.customerId || '',
                customerRole: partner.customerRole || '',
                customerType: partner.customerType || '',
                title: partner.customerTitle || '',
                customerName: partner.customerNameTH || '',
                taxId: partner.customerTaxId || '',
                taxBranch: partner.customerTaxBranch || '',
                //  scgFamilyId: partner.customerScgFamilyId || '',
                address: partner.customerAddress || '',
                subDistrict: partner.customerSubDistrict || '',
                district: partner.customerDistrict || '',
                province: partner.customerProvince || '',
                country: partner.customerCountry || '',
                postalCode: partner.customerPostalCode || '',
                phone: partner.customerPhone || '',
                fax: partner.customerFax || '',
                email: partner.customerEmail || '',
            }))
        }

        const now = moment().format()
        const productsModel = newProducts.map((product, index) => {
            // const data = {
            return {
                ...product,
                quotationItem: product.quotationItem || index + 1,
                // salesQuotationItemId: product.salesQuotationItemId || 0,
                quotationNumber: quotationDetail.quotationNumber || '',
                outletId: valuesQuotation.outletId,
                createdDatetime: product.createdDate || now,
                createdBy: product.createdBy || userId,
                changedDatetime: now,
                changedBy: userId,
                versionNumber: product.versionNumber || 0,
                // quotationItem: product.quotationItem || 0,
                barcode: product.barcode,
                productCode: product.productCode,
                productName: product.productName,
                stockType: product.stockType,
                slocId: product.slocId,
                shippingPoint: valuesQuotation.shippingPoint,
                qty: product.qty,
                confirmQty: product.confirmQty || 0,
                unit: product.unit,
                freeGoodsFlag: product.freeGoodsFlag || false,
                pricePerUnit: product.pricePerUnit,
                costPerUnit: valuesQuotation.requestSpecialPrice ? product.costPerUnit : this.calculateCost(product),
                discAmountPerUnit: product.discAmountPerUnit,
                discAmountPerUnitAmount: product.discAmountPerUnitAmount,
                discPercent: product.discPercent,
                discPercentAmount: product.discPercentAmount,
                discAmount: product.discAmount,
                //discPromotion: product.discPromotion,
                discPromotionInternal: product.discPromotionInternal,
                discPromotionExternal: product.discPromotionExternal,
                totalDiscAmount: product.totalDiscAmount,
                totalAmount: product.totalAmount,
                vatAmount: product.vatAmount,
                netAmount: product.netAmount,
                itemText: product.itemText || '',
                rejectId: product.rejectId || 0,
                promotionCode: product.promotionCode,
            }
        })

        const saleEmployee = saleEmployees.find(saleEmployee => saleEmployee.saleEmployeeId === valuesQuotation.saleEmployeeCode)
        return {
            quotationHeadId: quotationDetail.quotationHeadId || 0,
            quotationNumber: isEditMode ? quotationDetail.quotationNumber : '',
            outletId: valuesQuotation.outletId,
            saleChannelCode: valuesQuotation.saleChannelCode,
            createdDatetime: isEditMode ? quotationDetail.createdDatetime : now,
            createdBy: isEditMode ? quotationDetail.createdBy : userId,
            changedDatetime: now,
            changedBy: userId,
            startDate: valuesQuotation.startDate.format(),
            expireDate: valuesQuotation.expireDate.format(),
            documentTypeCode: isEditMode ? quotationDetail.documentTypeCode : quotationDocumentTypeCode,
            documentStatus: this.getDocumentStatus(),
            solutionType: valuesQuotation.solutionType,
            // edit mode not change customer use tax type from quotation detail (old data)
            taxType: isEditMode && customer.customerId === quotationDetail.customerId ? quotationDetail.taxType : isCustomerDefault ? customerDefault.customerTaxType : customer.customerTaxType,
            requestSpecialPrice: valuesQuotation.requestSpecialPrice,
            customerId: customer.customerId,
            customerName: customer.customerNameTH,
            shipToId: isCustomerDefault ? customerDefault.customerId : customerShipping.customerId,
            shipToName: isCustomerDefault ? customerDefault.customerNameTH : customerShipping.customerNameTH,
            scgid: valuesCustomer.scgId,
            saleEmpCode: valuesQuotation.saleEmployeeCode,
            saleEmpName: saleEmployee ? saleEmployee.saleEmployeeName : '',
            jobId: valuesQuotation.jobId,
            // edit mode not change customer use payment term code from quotation detail (old data)
            paymentTerm: isEditMode && customer.customerId === quotationDetail.customerId ? quotationDetail.paymentTerm : isCustomerDefault ? customerDefault.customerPaymentTerm : customer.customerPaymentTerm,
            deliveryDate: isEditMode ? quotationDetail.deliveryDate : now,
            remark,
            purchasingContract,
            quotationItemModelList: productsModel,
            quotationPartnerFunctionModelList: partnersModel,
            quotationRevisionModel: this.getRevisionModel(),
            quotationRevisionModelList: isEditMode ? quotationDetail.quotationRevisionModelList : null,
        }
    }

    getRevisionModel = (type = '') => {
        const { quotationInfo, quotationDetail, totalSummary } = this.state
        const { auth } = this.props

        const isApprove = type === 'approve'
        const now = moment().format()
        return {
            quotationRevisionId: 0,
            quotationNumber: quotationDetail.quotationNumber || '',
            outletId: quotationInfo.outletId,
            // versionNumber: 0,
            changedDatetime: now,
            changedBy: auth.userId,
            approvedDate: isApprove ? now : null,
            approvedBy: isApprove ? auth.userId : '',
            discPercent: totalSummary.discPercent,
            discPercentAmount: totalSummary.discPercentAmount,
            discAmount: totalSummary.discAmount,
            //discPromotion: totalSummary.discPromotion,
            discPromotionInternal: totalSummary.discPromotionInternal,
            discPromotionExternal: totalSummary.discPromotionExternal,
            discCouponPercent: totalSummary.discCouponPercent,
            discCouponPercentAmount: totalSummary.discCouponPercentAmount,
            discSCGIDCode: totalSummary.discSCGIDCode,
            discSCGIDAmount: totalSummary.discSCGIDAmount,
            discCouponAmount: totalSummary.discCouponAmount,
            totalDiscAmount: totalSummary.totalDiscAmount,
            totalAmount: totalSummary.totalAmount,
            vatAmount: totalSummary.vatAmount,
            netAmount: totalSummary.netAmount,
        }
    }

    getParamsSaleOrder = (productsSO = null, saleOrderDetail = null) => {
        const {
            customer,
            customerShipping,
            products,
            saleEmployees,
            totalSummary,
            remark,
            quotationDetail,
            quotationNumber,
            outletId,
        } = this.state
        const isEditMode = !!(quotationNumber && outletId)
        const isCustomerDefault = customer.customerId === customerDefault.customerId
        const newProducts = [...products]
        newProducts.splice(-1, 1)
        const {
            auth: { userId },
            QuotationInfoFormRedux: { values: valuesQuotation = {} },
            SaleCustomerInfoFormRedux: { values: valuesCustomer = {} },
        } = this.props

        let partnersModel = null

        if (!isEmpty(customer)) {
            let partners = []
            if (!isEditMode && isCustomerDefault) {
                partners = [{ ...customer, customerRole: 'sold_to' }, { ...customerDefault, customerRole: 'ship_to' }]
            } else {
                partners = [customer, customerShipping]
            }

            partnersModel = partners.map(partner => ({
                salesSOPartnerFunctionId: partner.salesQuotationItemId || 0,
                outletId: partner.customerOutletId || valuesQuotation.outletId || '',
                soNumber: quotationDetail.soNumber || '',
                partnerType: partner.customerRole || '',
                customerType: partner.customerType || '',
                customerId: partner.customerId || '',
                customerName: partner.customerNameTH || '',
                title: partner.customerTitle || '',
                taxId: partner.customerTaxId || '',
                taxBranch: partner.customerTaxBranch || '',
                scgFamilyId: partner.customerScgFamilyId || '',
                address: partner.customerAddress || '',
                subDistrict: partner.customerSubDistrict || '',
                district: partner.customerDistrict || '',
                province: partner.customerProvince || '',
                country: partner.customerCountry || '',
                postalCode: partner.customerPostalCode || '',
                phone: partner.customerPhone || '',
                fax: partner.customerFax || '',
                email: partner.customerEmail || '',
            }))
        }
        const now = moment().format()
        let productsModel = (productsSO ? productsSO : newProducts).map((product, index) => ({
            saleSOItemId: product.saleSOItemId || 0,
            outletId: valuesQuotation.outletId,
            soNumber: quotationDetail.soNumber || '',
            soItem: index + 1,
            barcode: product.barcode,
            productCode: product.productCode,
            productName: product.productName,
            stockType: product.stockType,
            slocId: product.slocId,
            shippingPoint: valuesQuotation.shippingPoint,
            deliveryDate: moment().startOf('day').format(),
            qty: product.qty,
            confirmQty: product.confirmQty || 0,
            unit: product.unit,
            freeGoodsFlag: product.freeGoodsFlag || false,
            specialOrderFlag: saleOrderDetail ? saleOrderDetail.saleType !== 'normal' : false,
            //specialOrderFlag: !isEmpty(foundServiceNotOpenPrice) ? true : (saleOrderDetail ? saleOrderDetail.saleType !== 'normal' : false),
            oldPricePerUnit: product.oldPricePerUnit,
            pricePerUnit: product.pricePerUnit,
            costPerUnit: valuesQuotation.requestSpecialPrice === true && product.costPerUnit != undefined && product.costPerUnit != null && product.costPerUnit >= 0 ? product.costPerUnit : this.calculateCost(product),
            discAmountPerUnit: product.discAmountPerUnit,
            discAmountPerUnitAmount: product.discAmountPerUnitAmount,
            discPercent: product.discPercent,
            discPercentAmount: product.discPercentAmount,
            discAmount: product.discAmount,
            // discPromotion: product.discPromotion,
            discPromotionInternal: product.discPromotionInternal,
            discPromotionExternal: product.discPromotionExternal,
            totalDiscAmount: product.totalDiscAmount,
            totalAmount: product.totalAmount,
            vatAmount: product.vatAmount,
            netAmount: product.netAmount,
            quotationNumber: product.quotationNumber || '',
            quotationItem: product.quotationItem || 0,
            rejectId: product.rejectId || 0,
            itemText: product.itemText || '',
            promotionCode: product.promotionCode,
            receivedQty: 0,
            refDocNumber: product.refDocNumber || '',
            refDocItem: product.refDocItem || '',
            remainQty: 0,
            productType: product.productType,
            flagTransportation: product.flagTransportation,
            flagOpenprice: product.flagOpenprice,
        }))
        const saleEmployee = saleEmployees.find(saleEmployee => saleEmployee.saleEmployeeId === valuesQuotation.saleEmployeeCode)

        //Sort product gift after product priczing
        let tempProducts = [...productsModel]
        let productsGoods = [...tempProducts].filter(p => !p.freeGoodsFlag || p.freeGoodsFlag === false)
        let productsGift = [...tempProducts].filter(p => p.freeGoodsFlag && p.freeGoodsFlag === true)
        productsModel = [...productsGoods, ...productsGift]

        return {
            salesSOHeadId: 0,
            outletId: valuesQuotation.outletId,
            soNumber: '',
            documentTypeCode: saleOrderDetail ? saleOrderDetail.documentType : '',
            documentStatus: 'OPEN',
            documentDate: now,
            saleChannelCode: valuesQuotation.saleChannelCode,
            createdDate: now,
            createdBy: userId,
            changedDate: now,
            changedBy: userId,
            jobId: valuesQuotation.jobId || '',
            taxType: isEditMode && customer.customerId === quotationDetail.customerId ? quotationDetail.taxType : isCustomerDefault ? customerDefault.customerTaxType : customer.customerTaxType,
            scgid: valuesCustomer.scgId,
            paymentTermCode: isEditMode && customer.customerId === quotationDetail.customerId ? quotationDetail.paymentTerm : isCustomerDefault ? customerDefault.customerPaymentTerm : customer.customerPaymentTerm,
            customerId: customer.customerId,
            customerName: customer.customerNameTH,
            shipToId: isCustomerDefault ? customerDefault.customerId : customerShipping.customerId,
            shipToName: isCustomerDefault ? customerDefault.customerNameTH : customerShipping.customerNameTH,
            saleEmpCode: valuesQuotation.saleEmployeeCode,
            saleEmpName: saleEmployee ? saleEmployee.saleEmployeeName : '',
            remark,
            // returnTypeId: 0,
            // returnReasonId: 0,
            discPercent: totalSummary.discPercent,
            discPercentAmount: totalSummary.discPercentAmount,
            discAmount: totalSummary.discAmount,
            //discPromotion: totalSummary.discPromotion,
            discPromotionInternal: totalSummary.discPromotionInternal,
            discPromotionExternal: totalSummary.discPromotionExternal,
            discCouponPercent: totalSummary.discCouponPercent,
            discCouponPercentAmount: totalSummary.discCouponPercentAmount,
            discSCGIDCode: totalSummary.discSCGIDCode,
            discSCGIDAmount: totalSummary.discSCGIDAmount,
            discCouponAmount: totalSummary.discCouponAmount,
            totalDiscAmount: totalSummary.totalDiscAmount,
            totalAmount: totalSummary.totalAmount,
            vatAmount: totalSummary.vatAmount,
            netAmount: totalSummary.netAmount,
            promotionCode: quotationDetail.promotionCode,
            approvedDate: quotationDetail.approvedDate || '',
            approvedBy: quotationDetail.approvedBy || '',
            refDocNumber: quotationDetail.quotationNumber || '',
            refDocSystem: quotationDetail.refDocSystem || '',
            salesSOItemModelList: productsModel,
            salesSOPartnerFunctionModelList: partnersModel,
        }
    }

    validateData = () => {
        const {
            products,
            customer,
            customerShipping,
            quotationDetail,
        } = this.state
        const newProducts = [...products]
        newProducts.splice(-1, 1)

        const {
            QuotationInfoFormRedux: { values: valuesQuotation, syncErrors: syncErrorsQuotation },
        } = this.props

        let result = { isError: false, message: '' }
        const isErrorCustomer = isEmpty(customer)
        const isErrorCustomerShipping = customer.customerId !== customerDefault.customerId && isEmpty(customerShipping)
        const isErrorProduct = isEmpty(newProducts)
        if ((valuesQuotation.expireDate).isBefore(valuesQuotation.startDate)) {
            return { isError: true, message: 'ไม่สามารถเลือกวันหมดอายุเอกสารน้อยกว่าวันที่เอกสารได้' }
        }

        if (syncErrorsQuotation || isErrorCustomer || isErrorCustomerShipping || isErrorProduct) {
            this.setState({
                isErrorSubmitProduct: isErrorProduct,
                isErrorSubmitCustomer: isErrorCustomer,
                isErrorSubmitCustomerShipping: isErrorCustomerShipping,
                isErrorSubmitQuotation: !!syncErrorsQuotation,
            })
            return { isError: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }
        }

        for (const product of newProducts) {
            if ((!product.qty || product.qty <= 0) && product.freeGoodsFlag === false) {
                result = { isError: true, message: 'กรุณาระบุปริมาณ' }
                break
            }
            if ((!product.rejectId && (!product.pricePerUnit || product.pricePerUnit <= 0)) && product.freeGoodsFlag === false) {
                result = { isError: true, message: 'กรุณาระบุราคา/หน่วย' }
                break
            }
            if (!(product.rejectId || 0) && (product.discPromotionInternal < 0 || product.discPromotionExternal < 0) && (!product.freeGoodsFlag || product.freeGoodsFlag === false)) {
                result = { isError: true, message: 'ราคาโปรโมชั่นสูงกว่าราคาขายปกติ, กรุณาแจ้งทีมที่ดูแลเพื่อตรวจสอบราคา' }
                break
            }
        }

        return result
    }

    confirmAction = (type = '') => {
        const valid = this.validateData()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }
        if (type === 'create') {
            swal({
                icon: 'warning',
                text: 'คุณต้องการสร้างใบเสนอราคาหรือไม่ ?',
                buttons: {
                    create: { text: 'สร้างใบเสนอราคา', value: true },
                    cancel: 'ปิด',
                },
            }).then((value) => {
                if (value) this.createQuotation()
            })
        } else if (type === 'edit') {
            swal({
                icon: 'warning',
                text: 'คุณต้องการแก้ไขใบเสนอราคาหรือไม่ ?',
                buttons: {
                    confirm: { text: 'ยืนยัน', value: true },
                    cancel: 'ปิด',
                },
            }).then((value) => {
                if (value) this.updateQuotation()
            })
        }
    }

    createQuotation = async () => {
        await this.calculateTotalSummary()

        this.setState({ loading: true })
        const body = this.getParams();
        // return
        const url = API_PATH.POST_QUOTATION_CREATE_QUOTATION
        if (!isEmpty(body.quotationRevisionModel.discSCGIDCode)) {
            try {
                const resCouponseStatus = await this.updateStatusCoupon(body.quotationRevisionModel.discSCGIDCode, body.scgid, 'Used')
            } catch (error) {
                const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
                swal('Error', message, 'error')
                return
            }
        }
        //console.log(`Json string CreateQuotation Request ==>${JSON.stringify(body)}`)
        await apiServices.callApi('post', url, body).then(res => {
            //console.log(`Json string CreateQuotation Responses ==>${JSON.stringify(body)}`)
            const quotationNumber = get(res, 'data.message')
            if (res.status === 200) {
                swal('Success', `เลขที่ใบเสนอราคา : ${quotationNumber}`, 'success').then(() => {
                    this.props.history.push(`${ROUTH_PATH.QUOTATION_UPDATE_LINK}/${quotationNumber}/${body.outletId}`)
                })
            } else {
                throw res
            }
        }).catch(error => {
            if (error.response.status === 400) {
                const message = error.response.data.errors ? Object.values(error.response.data.errors).flat().join() : 'เกิดข้อผิดพลาดในการสร้างใบเสนอราคา กรุณาลองใหม่อีกครั้ง';
                swal('Error', message, 'error')
            } else {
                const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการสร้างใบเสนอราคา กรุณาลองใหม่อีกครั้ง')
                swal('Error', message, 'error')
            }
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    updateQuotation = async (mode) => {
        await this.calculateTotalSummary()

        this.setState({ loading: true })
        const body = this.getParams()
        const { quotationNumber } = this.state
        const url = `${API_PATH.PUT_QUOTATION_UPDATE_QUOTATION}/${quotationNumber}`
        await apiServices.callApi('put', url, body).then(res => {
            if (res.status === 200) {
                if (mode === 'CreateSO') {
                    swal('Success', `หมายเลขใบเสนอราคา ${quotationNumber} ถูกบันทึกสำเร็จ`, 'success')
                }
                else {
                    swal('Success', `หมายเลขใบเสนอราคา ${quotationNumber} ถูกบันทึกสำเร็จ`, 'success').then(() => {
                        this.initiatData()
                    })
                }
            } else {
                throw res
            }
        }).catch(error => {
            this.setState({ isShowModal: false })
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการบันทึกข้อมูลใบเสนอราคา กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    validateDateApprove = async () => {
        const {
            quotationDetail,
        } = this.state

        const {
            QuotationInfoFormRedux: { values: valuesQuotation, syncErrors: syncErrorsQuotation }, auth
        } = this.props

        const products = [...quotationDetail.quotationItemModelList]

        let result = { isError: false, message: '' }

        for (const product of products) {
            const body = {
                //barcode: productSelected.barcode,
                unitId: product.unit,
                productId: product.productCode,
                outletId: product.outletId,
                storageLocationId: product.slocId,
                productStatus: true,
            }
            await apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCT}?getBy=${auth.userId}`, body).then(res => {
                const productsResult = get(res, 'data.results')
                if (res.status === 200 && !isEmpty(productsResult)) {
                    let productResult = productsResult.find(p => p.unitId === product.unit && p.productId === product.productCode && p.outletId === product.outletId)
                    if (!isEmpty(productResult)) {
                        product.retailPrice = productResult.retailPrice || 0
                        product.projectPrice = productResult.projectPrice || 0
                        product.retailCost = productResult.retailCost || 0
                        product.projectCost = productResult.projectCost || 0
                    }
                }
            }).catch(error => {
                const message = get(error, 'response.data.message', `เกิดข้อผิดพลาดระหว่างการค้นหาสินค้า กรุณาตรวจสอบข้อมูลเพิ่มเติม!`)
                console.log(message)
                result = { isError: true, message: message }
            }).finally(() => {
                const productCost = valuesQuotation.requestSpecialPrice === true && product.costPerUnit != undefined && product.costPerUnit != null && product.costPerUnit >= 0 ? product.costPerUnit : this.calculateCost(product)
                const totalCost = productCost * product.qty;

                /* 23-01-2568 ปลด validate ราคาขาย ต่ำกว่า ทุน
                if (productCost > product.pricePerUnit && !product.rejectId) {
                    result = { isError: true, message: `พบรายการสินค้า(${product.productCode}) ที่ราคาขาย(${product.pricePerUnit}) ต่ำกว่าทุน(${productCost}) กรุณาตรวจสอบข้อมูลเพิ่มเติม!` }
                }
                else */
                if (quotationDetail.requestSpecialPrice && product.productType === 'service' && product.flagOpenprice === false && (product.costPerUnit == null || product.costPerUnit == undefined)) {
                    result = { isError: true, message: 'กรุณาระบุทุน ZCA1 พิเศษ/หน่วย สำหรับสินค้าค่าบริการ' }
                }
                else if (quotationDetail.requestSpecialPrice && product.productType === 'goods' && product.freeGoodsFlag === false && (product.costPerUnit != null && product.costPerUnit != undefined && product.costPerUnit <= 0)) {
                    result = { isError: true, message: 'กรุณาระบุ ทุน ZCA1 พิเศษ/หน่วย(IN VAT) มากกว่า 0' }
                }
                else if (quotationDetail.requestSpecialPrice && product.productType === 'goods' && product.freeGoodsFlag === false && (product.retailCost != null && product.retailCost != undefined && product.retailCost <= 0) && product.costPerUnit <= 0) {
                    result = { isError: true, message: 'ทุน ZCA1 จาก master เป็น 0, กรุณาระบุ ทุน ZCA1 พิเศษ/หน่วย(IN VAT)' }
                }
                /* 23-01-2568 ปลด validate ราคาขาย ต่ำกว่า ทุน
                else if (totalCost > product.netAmount && !product.rejectId) {
                    result = { isError: true, message: `พบรายการสินค้า(${product.productCode}) ที่ราคาขายรวม(${product.netAmount}) ต่ำกว่าทุนรวม(${totalCost}) กรุณาตรวจสอบข้อมูลเพิ่มเติม!` }
                } */
            })

            if (result.isError === true)
                break
        }

        return result
    }

    approveQuotation = async () => {
        // this.setState({ loading: true })
        const { auth } = this.props
        const { quotationDetail, products } = this.state
        const { quotationNumber } = quotationDetail
        const userId = auth.userId
        const valid = await this.validateDateApprove()
        if (valid.isError === true) {
            this.setState({ loading: false })
            swal('Warning', valid.message, 'warning')
            return
        }

        quotationDetail.quotationItemModelList.map((product, index) => {
            product.costPerUnit = product.costPerUnit != null ? product.costPerUnit : product.retailCost
        })

        const now = moment().format()
        const body = {
            ...quotationDetail,
            documentStatus: 'APPROVE',
            changedDate: now,
            changedBy: userId,
            approvedDate: now,
            approvedBy: userId,
            quotationRevisionModel: this.getRevisionModel('approve'),
        }

        const url = `${API_PATH.PUT_QUOTATION_UPDATE_QUOTATION}/${quotationNumber}`

        await apiServices.callApi('put', url, body).then(res => {
            if (res.status === 200) {
                swal('Success', `หมายเลขใบเสนอราคา ${quotationNumber} อนุมัติสำเร็จ`, 'success').then(() => {
                    this.initiatData()
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการอนุมัติใบเสนอราคา กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    openSaleOrderCreateModal = () => {
        const { products } = this.state
        const newProducts = [...products]
        newProducts.splice(newProducts.length - 1, 1)
        let productsSO = newProducts.map((product, index) => ({
            ...product,
            qtyQuotation: product.qty,
            qty: product.qty - product.confirmQty,
            index,
        }))
        //console.log('productsSO==>', productsSO);
        productsSO = productsSO.filter(product => !product.rejectId)
        this.updateQuotation('CreateSO')
        this.setState({
            isShowModal: true,
            modalType: 'saleOrderCreate',
            productsSO,
        })
    }

    validateSaleOrderData = (productsSO) => {
        let result = { isError: false, message: '' }

        const invalidProduct = productsSO.every(item => Number(item.qty) <= 0)
        if (invalidProduct) {
            return { isError: true, message: 'กรุณาระบุจำนวนของสินค้า' }
        }

        for (const product of productsSO) {
            const remainQty = product.qtyQuotation - product.confirmQty
            if (product.qty && (remainQty <= 0 || remainQty - product.qty < 0)) {
                result = { isError: true, message: `สินค้าลำดับที่ ${product.index + 1} จำนวนที่สามารถสร้างใบส่ังขายได้มีจำนวน ${remainQty}` }
                break
            }

            if (!(product.rejectId || 0) && (product.discPromotionInternal < 0 || product.discPromotionExternal < 0) && (!product.freeGoodsFlag || product.freeGoodsFlag === false)) {
                result = { isError: true, message: 'ราคาโปรโมชั่นสูงกว่าราคาขายปกติ, กรุณาแจ้งทีมที่ดูแลเพื่อตรวจสอบราคา' }
                break
            }
        }
        return result
    }

    createSaleOrder = async (productsSO, saleOrderDetail) => {
        const valid = this.validateSaleOrderData(productsSO)
        if (valid.isError) {
            message.error(valid.message)
            return
        }
        const { auth, QuotationInfoFormRedux: { values } } = this.props
        const { quotationDetail } = this.state
        const { quotationNumber } = quotationDetail
        let body = this.getParamsSaleOrder(productsSO, saleOrderDetail)
        //Recalculate
        let bodySaleOrder = await this.calculateSaleOrderAmount(body)
        bodySaleOrder = {
            ...bodySaleOrder,
            documentTypeCode: body.documentTypeCode,
            documentStatus: body.documentStatus
        }
        //Filter item QTY > 0
        const salesSOItem = bodySaleOrder.salesSOItemModelList.filter(item => item.qty > 0)
        bodySaleOrder.salesSOItemModelList = salesSOItem;
        bodySaleOrder.salesSOItemModelList = bodySaleOrder.salesSOItemModelList.map((product, index) => {
            return {
                ...product,
                soItem: index + 1,
            }
        })

        let newProducts = bodySaleOrder.salesSOItemModelList.map((product, index) => ({
            index,
            ...product,
        }))
        // 20069816
        // 20013091
        //const newServiceProducts = newProducts.filter(p => p.productType === 'service' && p.flagOpenprice === false);
        const newServiceTransportations = newProducts.filter(p => p.productType === 'service' && p.flagTransportation === false);
        const newGoodsProducts = newProducts.filter(p => p.productType === 'goods' || (p.productType === 'service' && p.flagTransportation === true));
        // console.log(newGoodsProducts, newServiceTransportations);

        // console.log(!isEmpty(newGoodsProducts));
        // console.log(!isEmpty(newServiceTransportations));

        if (/*!isEmpty(newServiceProducts) &&*/ !isEmpty(newGoodsProducts) && !isEmpty(newServiceTransportations)) {
            const newServiceProduct = first(newServiceTransportations /*newServiceProducts*/);
            swal('Error', `รายการลำดับที่ ${(newServiceProduct.index + 1)} เป็นประเภทค่าบริการ กรุณาแยกเอกสารจากสินค้า`, 'error')
            return;
        }

        //console.log("bodySaleOrder==>", JSON.stringify(bodySaleOrder));
        // return

        this.closeModal()
        this.setState({ loading: true })
        const now = moment().format()
        //Old logic
        /*await apiServices.callApi('post', API_PATH.POST_SALE_ORDER, bodySaleOrder).then(async (res) => {
            const quotationItems = quotationDetail.quotationItemModelList
            const quotationItemModelList = quotationItems.map((item, index) => {
                const saleOrderItem = productsSO.find(data => data.index === index)
                if (saleOrderItem) {
                    return {
                        ...item,
                        confirmQty: Number(item.confirmQty) + Number(saleOrderItem.qty),
                        changedDatetime: now,
                        changedBy: auth.userId,
                    }
                } else {
                    return item
                }
            })
            const isCompleted = !quotationItemModelList.find(item => !item.rejectId && Number(item.qty).toFixed(3) !== Number(item.confirmQty).toFixed(3))
            const bodyQuotation = {
                ...quotationDetail,
                documentStatus: isCompleted ? 'COMPLETE' : 'SALE',
                changedDate: now,
                changedBy: auth.userId,
                quotationItemModelList,
                quotationRevisionModel: this.getRevisionModel(),
            }

            await apiServices.callApi('put', `${API_PATH.PUT_QUOTATION_UPDATE_QUOTATION}/${quotationNumber}`, bodyQuotation).then(() => {
                const saleOrderNumber = get(res, 'data.message')
                swal({
                    title: 'Success',
                    icon: 'success',
                    text: `หมายเลขใบสั่งขายสินค้า: ${saleOrderNumber}`,
                    buttons: {
                        saleOrder: { text: 'เปิดใบขาย', value: true },
                        cancel: 'ปิด',
                    },
                }).then((value) => {
                    if (value) {
                        this.props.history.push(`${ROUTH_PATH.SALE_ORDER_EDIT_LINK}/${saleOrderNumber}/${values.outletId}`)
                    } else {
                        this.props.history.push(ROUTH_PATH.QUOTATION)
                    }
                })
            }).catch(error => { throw error })
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการสร้างใบสั่งขาย กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })*/

        //Revice logic single process Create SalesOrder
        bodySaleOrder = {
            ...bodySaleOrder,
            quotationRevisionModel: this.getRevisionModel(),
        }
        await apiServices.callApi('post', API_PATH.POST_SALE_ORDER, bodySaleOrder).then(async (res) => {
            const saleOrderNumber = get(res, 'data.message')
            swal({
                title: 'Success',
                icon: 'success',
                text: `หมายเลขใบสั่งขายสินค้า: ${saleOrderNumber}`,
                buttons: {
                    saleOrder: { text: 'เปิดใบขาย', value: true },
                    cancel: 'ปิด',
                },
            }).then((value) => {
                if (value) {
                    this.props.history.push(`${ROUTH_PATH.SALE_ORDER_EDIT_LINK}/${saleOrderNumber}/${values.outletId}`)
                } else {
                    this.props.history.push(ROUTH_PATH.QUOTATION)
                }
            })
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการสร้างใบสั่งขาย กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    calculateTotalSummary = async () => {
        let { quotationDetail, quotationNumber, outletId } = this.state
        const {
            QuotationInfoFormRedux: { values: valuesQuotation, syncErrors: syncErrorsQuotation }, auth
        } = this.props

        // วิ่งเส้นไม่คำนวนโปรโมชั่นเมือ
        const isEditMode = !!(quotationNumber && outletId &&
            !quotationDetail.documentStatus === undefined
            && ['COMPLETE', 'SALE', 'REJECT'].includes(quotationDetail.documentStatus)
            // && quotationDetail.documentStatus === "SALE"
            // && quotationDetail.documentStatus === "REJECT"
            // && quotationDetail.documentStatus === "complete"
        )

        const body = this.getParamsSaleOrder()

        if (isEmpty(body) || isEmpty(body.salesSOItemModelList))
            return

        this.setState({ loading: true })
        const url = (isEditMode || valuesQuotation.requestSpecialPrice === true) ? API_PATH.POST_SALE_ORDER_AMOUNT : API_PATH.POST_PROMOTION_TOTAL_AMOUNT
        // console.log('url', url === API_PATH.POST_PROMOTION_TOTAL_AMOUNT);
        await apiServices.callApi('post', url, body).then(res => {
            const saleSummary = get(res, 'data.results.0')
            if (res.status === 200 && saleSummary) {
                let tempProducts = [...this.state.products]
                tempProducts.splice(-1, 1)
                let productsGoods = [...tempProducts].filter(p => !p.freeGoodsFlag || p.freeGoodsFlag === false)
                let productsGift = [...tempProducts].filter(p => p.freeGoodsFlag && p.freeGoodsFlag === true)
                let sortProducts = [...productsGoods, ...productsGift]

                let products = sortProducts.map((product, index) => {
                    const productSummary = saleSummary.salesSOItemModelList.find((item, indexItem) => index === indexItem)
                    if (productSummary) {
                        return {
                            ...product,
                            promotionCode: productSummary.promotionCode,
                            discAmount: productSummary.discAmount,
                            discAmountPerUnit: productSummary.discAmountPerUnit,
                            discAmountPerUnitAmount: productSummary.discAmountPerUnitAmount,
                            discPercent: productSummary.discPercent,
                            discPercentAmount: productSummary.discPercentAmount,
                            // TODO: remove discPromotion when edited api
                            //discPromotion: productSummary.discPromotion,
                            discPromotionInternal: productSummary.discPromotionInternal,
                            discPromotionExternal: productSummary.discPromotionExternal,
                            totalAmount: productSummary.totalAmount,
                            totalDiscAmount: productSummary.totalDiscAmount,
                            vatAmount: productSummary.vatAmount,
                            netAmount: productSummary.netAmount,
                        }
                    } else {
                        return product
                    }
                })
                //จัดการสินค้าของแถม
                let oldProducts = products.filter(p => !p.freeGoodsFlag || p.freeGoodsFlag === false);
                //oldProducts.splice(-1, 1)
                let newProductsFreeGoods = saleSummary.salesSOItemModelList.filter(p => p.freeGoodsFlag && p.freeGoodsFlag === true);

                if (!isEmpty(newProductsFreeGoods)) {
                    newProductsFreeGoods = newProductsFreeGoods.map(product => {
                        const oldFreeProduct = products.find((item, index) => item.productCode === product.productCode && item.freeGoodsFlag === product.freeGoodsFlag && item.promotionCode === product.promotionCode)

                        return {
                            ...product,
                            rejectIdInitial: product.rejectId,
                            deliveryDate: product.deliveryDate && moment(new Date(product.deliveryDate)),
                            slocId: oldFreeProduct && oldFreeProduct.slocId ? oldFreeProduct.slocId : product.slocId,
                        }
                    })
                }

                products = [...oldProducts, ...newProductsFreeGoods, initialProduct]

                quotationDetail = {
                    ...quotationDetail,
                    promotionCode: saleSummary.promotionCode
                }

                this.setState({
                    products,
                    quotationDetail,
                    totalSummary: {
                        totalAmount: saleSummary.totalAmount,
                        totalExcVatAmount: saleSummary.totalExcVatAmount,
                        totalDiscAmount: saleSummary.totalDiscAmount,
                        netAmount: saleSummary.netAmount,
                        vatAmount: saleSummary.vatAmount,
                        discPercent: saleSummary.discPercent,
                        discPercentAmount: saleSummary.discPercentAmount,
                        discAmount: saleSummary.discAmount,
                        //discPromotion: saleSummary.discPromotion,
                        discPromotionInternal: saleSummary.discPromotionInternal,
                        discPromotionExternal: saleSummary.discPromotionExternal,
                        discCouponPercent: saleSummary.discCouponPercent,
                        discCouponPercentAmount: saleSummary.discCouponPercentAmount,
                        discSCGIDCode: saleSummary.discSCGIDCode,
                        discSCGIDAmount: saleSummary.discSCGIDAmount,
                        discCouponAmount: saleSummary.discCouponAmount,
                    },
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการคำนวณราคา กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    calculateSaleOrderAmount = async (body) => {
        let { quotationDetail, quotationNumber, outletId } = this.state
        const {
            QuotationInfoFormRedux: { values: valuesQuotation, syncErrors: syncErrorsQuotation }, auth
        } = this.props

        this.setState({ loading: true })
        const url = API_PATH.POST_SALE_ORDER_AMOUNT
        return await apiServices.callApi('post', url, body).then(res => {
            const saleDetail = get(res, 'data.results.0')
            if (res.status === 200 && saleDetail) {
                return saleDetail;
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการคำนวณราคา กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    onSubmitDiscountItem = async (productDisc) => {
        const { auth } = this.props
        const { indexRowAction, quotationDetail, isEditMode } = this.state
        this.setState({ loading: true })
        const body = {
            usetRoles: auth.arrayRole,
            salesSOItem: productDisc,
            documentTypeCode: 'ZQT'
        }

        const documentTypeCode = isEditMode ? quotationDetail.documentTypeCode : quotationDocumentTypeCode;
        await apiServices.callApi('post', `${API_PATH.POST_MANUAL_PROMOTION_DISCOUNT_ITEM}?Doctype=${documentTypeCode}`, body).then(res => {
            if (res.status === 200 && get(res, 'data.status') === 'Success') {
                const products = this.state.products.map((product, i) => (
                    i === indexRowAction ? {
                        ...product,
                        ...productDisc,
                    } : product
                ));
                this.setState({
                    products,
                    indexRowAction: null,
                }, () => this.calculateTotalSummary())
                this.closeModal()
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการคำนวณราคา กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    // TODO: add logic 
    onSubmitDiscount = (totalSummary) => {
        this.setState({ totalSummary: totalSummary }, this.calculateTotalSummary)
    }

    clearAllData = async () => {
        this.props.resetSaleCustomerInfoForm()
        this.props.resetCustomerDetailForm()
        this.props.resetQuotationInfoForm()
        this.resetState()
        this.initiatData()
    }

    getStepCurrent = () => {
        const { quotationInfo } = this.state
        let step = 0

        switch (quotationInfo.documentStatus) {
            case 'APPROVE' || 'SALE':
                step = 1
                break
            case 'COMPLETE' || 'CANCEL':
                step = 2
                break
            default:
                break
        }
        return step
    }
    async updateStatusCoupon(couponCode, scgid, status) {
        let body = {
            "scgid": scgid,
            "coupon_code": couponCode,
            "status": status
        }
        const resCouponsStatus = await apiServices.callApi('post', API_PATH.POST_SALE_ORDER_UPDATE_COUPON_STATUS, body)
        return resCouponsStatus
    }
    async onPrintFormQuotation() {
        const { outletId, quotationNumber } = this.state
        let body = {
            "DocumentNo": quotationNumber,
            "OutletId": outletId
        }
        try {
            const result = await apiServices.callApi('get', `${API_PATH.GET_REPORT_PRINT_QUOTATION}?${queryString.stringify(body)}`, null, { responseType: 'blob' })
            console.log(result)
            const urlOpen = window.URL.createObjectURL(result.data)
            window.open(urlOpen)
        } catch (error) {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }
    }
    async checkPromotion() {
        let body = this.getParamsSaleOrder()
        try {
            const result = await apiServices.callApi('post', `${API_PATH.POST_CHECK_PROMOTION_AVAILABLE}`, body)
            const promotions = get(result, 'data.results', [])
            this.setState({ promotions }, this.openModal('promotion'))
        } catch (error) {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }
    }
    checkPromotionDisable(data, products) {
        const { values } = data
        if (!isEmpty(values)) {
            if (!isEmpty(values.saleChannelCode) && values.solutionType && !isEmpty(values.shippingPoint)) {
                if (products.length > 1) {
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        } else {
            return true
        }
    }

    render() {
        const {
            auth,
        } = this.props
        const {
            columns,
            columnsProductSpecialPrice,
            quotationDetail,
            products,
            revisions,
            loading,
            quotationNumber,
            outletId,
            outlets,
            saleChannels,
            shippingPoints,
            documentStatuses,
            saleEmployees,
            solutions,
            storeLocations,
            units,
            rejections,
            quotationInfo,
            customerIdSearch,
            customer,
            customerShipping,
            customerShippings,
            customerInfo,
            totalSummary,
            remark,
            purchasingContract,
            isErrorSubmitProduct,
            isErrorSubmitCustomer,
            isErrorSubmitCustomerShipping,
            isErrorSubmitQuotation,
            isShowModal,
            modalType,
            indexRowAction,
            productsSO,
            productBOQ,
            permissionPage,
            promotions,
            isAuthApproveSalesDiscount,
        } = this.state;

        const isEditMode = !!(quotationNumber && outletId)
        const stepsCurrent = this.getStepCurrent()
        const documentStatus = quotationInfo.documentStatus
        const disabledAction = documentStatus === 'COMPLETE' || documentStatus === 'CANCEL'
        // const showApproveBtn = documentStatus !== 'OPEN' || (isEditMode && quotationInfo.requestSpecialPrice)
        const showApproveBtn = isEditMode && documentStatus === 'BLOCK'
        const disabledApproveBtn = (quotationInfo.requestSpecialPrice ? !permissionPage.authApprove : isAuthApproveSalesDiscount === false)/*!permissionPage.authApprove*/ || (documentStatus !== 'BLOCK' && documentStatus !== 'OPEN')
        /*|| (quotationInfo.outletId === auth.userId || quotationInfo.outletId === 'Admin')*/
        const disabledRequestSpecialPrice = documentStatus !== 'OPEN' || quotationInfo.requestSpecialPrice
        const disableSCGID = disabledAction ? true : !isEmpty(customerInfo.scgId) && !isEmpty(totalSummary.discSCGIDCode)
        const disablePromotion = disabledAction ? true : !isEmpty(this.props.QuotationInfoFormRedux) && this.checkPromotionDisable(this.props.QuotationInfoFormRedux, products)

        return (
            <div className="quotation-create-container" >
                <Spin spinning={loading}>
                    <Row justify="center" type="flex" className="step-box">
                        <Col sm={12}>
                            <Steps current={stepsCurrent} size="small">
                                <Step title="เสนอราคา" />
                                <Step title="อนุมัติ" />
                                <Step title="เสร็จสิ้น" />
                            </Steps>
                        </Col>
                    </Row>
                    <TitleHead
                        icon="file-text"
                        text="ใบเสนอราคา"
                        nodeRight={
                            <div className="text-remark">
                                * กรณีสร้างเอกสารอ้างอิง JOB ID กรุณาระบุ JOB ID ก่อนใส่รายการสินค้า
                        </div>
                        }
                    />
                    <div className="middle-content">
                        <Row gutter={8}>
                            <Col sm={24} md={10}>
                                <SaleCustomerInfoFormRedux
                                    initialValues={customerInfo}
                                    customerInfo={customerInfo}
                                    isErrorSubmitCustomer={isErrorSubmitCustomer}
                                    isErrorSubmitCustomerShipping={isErrorSubmitCustomerShipping}
                                    customerIdSearch={customerIdSearch}
                                    customer={customer}
                                    customerShipping={customerShipping}
                                    customerShippings={customerShippings}
                                    outletId={quotationInfo.outletId}
                                    showApproveBtn={showApproveBtn}
                                    disabledApproveBtn={disabledApproveBtn}
                                    disabledAction={disabledAction}
                                    disableSCGID={disableSCGID}
                                    approveQuotation={this.approveQuotation}
                                    changeCustomerIdSearch={this.changeCustomerIdSearch}
                                    changeCustomerInfo={this.changeCustomerInfo}
                                    searchCustomer={this.searchCustomer}
                                    onSelectedCustomer={this.onSelectedCustomer}
                                    onSelectedShipping={this.onSelectedShipping}
                                    widthLabel="90px"
                                    type="quotation"
                                />
                            </Col>
                            <Col sm={24} md={14}>
                                <QuotationInfoFormRedux
                                    initialValues={quotationInfo}
                                    isErrorSubmit={isErrorSubmitQuotation}
                                    isEditMode={isEditMode}
                                    customer={customer}
                                    outlets={outlets}
                                    saleChannels={saleChannels}
                                    shippingPoints={shippingPoints}
                                    documentStatuses={documentStatuses}
                                    solutions={solutions}
                                    saleEmployees={saleEmployees}
                                    disabledRequestSpecialPrice={disabledRequestSpecialPrice}
                                    disabledAction={disabledAction}
                                    changeQuotationInfo={this.changeQuotationInfo}
                                    submitSearchJobId={this.submitSearchJobId}
                                />
                            </Col>
                        </Row>
                        {isErrorSubmitProduct && <span style={{ color: 'red', fontSize: '12px' }}>* กรุณาเลือกสินค้า</span>}
                        {!isEditMode && <div className="table-layout">
                            <TableCustom
                                columns={columns}
                                data={products}
                                //isFullContent                                
                                rowKey={(row, index) => index}
                                small
                                pagination={false}
                            />
                        </div>}
                        {isEditMode && <Tabs type="card" style={{ padding: '15px 0' }}>
                            <TabPane tab="รายละเอียดการขาย" key="1">
                                <div className="table-layout" style={{ paddingTop: '5px' }}>
                                    <TableCustom
                                        columns={quotationDetail.requestSpecialPrice ? columnsProductSpecialPrice : columns}
                                        data={products}
                                        isFullContent
                                        rowKey={(row, index) => index}
                                        small
                                        pagination={false}
                                    />
                                </div>
                            </TabPane>
                            <TabPane tab="Version เอกสาร" key="2">
                                <Row>
                                    <Col sm={24} md={12}>
                                        <RevisionTable revisions={revisions} />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Purchasing Contract" key="3">
                                <Row>
                                    <Col sm={24} md={12}>
                                        <InputCustom
                                            label="Purchasing Contract"
                                            small
                                            widthLabel="140px"
                                            value={purchasingContract}
                                            onChange={this.changePurchasingContract}
                                            disabled={disabledAction}
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                        </Tabs>}
                        <Row style={{ marginTop: '20px' }}>
                            <Col sm={24} md={12}>
                                <TextAreaCustom
                                    label="หมายเหตุ"
                                    small
                                    widthLabel="140px"
                                    onChange={this.changeRemark}
                                    value={remark && ReactHtmlParser(remark.replace(/\n|\/n/g, '&#13;').replace(/\t|\/t/g, '&#09;'))}
                                    disabled={disabledAction}
                                />
                            </Col>
                            <Col sm={24} md={10}>
                                <SaleSummary
                                    type="Quotation"
                                    totalSummary={totalSummary}
                                    onSubmitDiscount={this.onSubmitDiscount}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className="bottom-content">
                        <div className="left-zone">
                            <div className="button">
                                <ButtonCustom
                                    text="ล้างหน้าจอ"
                                    icon="reload"
                                    type="danger"
                                    onClick={this.clearAllData}
                                    disabled={disabledAction}
                                />
                            </div>
                        </div>
                        <div className="right-zone">
                            <div className="button">
                                <ButtonCustom
                                    text="เช็คโปรโมชั่น"
                                    icon="check-square"
                                    yellow
                                    onClick={this.checkPromotion.bind(this)}
                                    disabled={disablePromotion}
                                />
                            </div>
                            <div className="button">
                                <Link to={ROUTH_PATH.HOME} className="footer-button">
                                    <ButtonCustom
                                        text="ยกเลิก"
                                        icon="close"
                                        type="danger"
                                    />
                                </Link>
                            </div>
                            <div className="button">
                                <ButtonCustom
                                    text="บันทึก"
                                    icon="save"
                                    onClick={() => this.confirmAction(isEditMode ? 'edit' : 'create')}
                                    disabled={disabledAction || (isEditMode ? !permissionPage.authUpdate : !permissionPage.authCreate)}
                                />
                            </div>
                            {(documentStatus === 'APPROVE' || documentStatus === 'SALE') && <div className="button">
                                <ButtonCustom
                                    text="ยืนยันสร้างใบสั่งขาย"
                                    icon="save"
                                    onClick={() => this.openSaleOrderCreateModal()}
                                    disabled={disabledAction || !permissionPage.authUpdate}
                                />
                            </div>}
                            {(documentStatus === 'APPROVE' || documentStatus === 'SALE') && <div className="button">
                                <ButtonCustom
                                    text="พิมพ์"
                                    icon="printer"
                                    green
                                    onClick={this.onPrintFormQuotation}
                                    disabled={!permissionPage.authPrint}
                                />
                            </div>}
                        </div>
                    </div>
                    <PromotionModal
                        isShowModal={isShowModal && modalType === 'promotion'}
                        onCloseModal={this.closeModal}
                        promotions={promotions}
                    />
                    <ProductSearchModal
                        isShowModal={isShowModal && modalType === 'product'}
                        onCloseModal={this.closeModal}
                        onSelected={this.onSelectedProduct}
                        outletId={quotationInfo.outletId}
                        channelCode={quotationInfo.saleChannelCode || '10'}
                        isIncludeStockATP
                    />
                    <StoreLocationModal
                        isShowModal={isShowModal && modalType === 'storeLocation'}
                        handleCloseModal={this.closeModal}
                        dataStorages={storeLocations}
                        onSelected={this.onSelectedStoreLocation}
                    />
                    <SaleUnitModal
                        isShowModal={isShowModal && modalType === 'unit'}
                        onCloseModal={this.closeModal}
                        units={units}
                        onSelected={this.onSelectedUnit}
                    />
                    <RejectionModal
                        isShowModal={isShowModal && modalType === 'reject'}
                        onCloseModal={this.closeModal}
                        onClickSave={this.changeProductMore}
                        rejections={rejections}
                        data={indexRowAction ? products[indexRowAction] : {}}
                        permissionPage={permissionPage}
                    />
                    {isShowModal && modalType === 'discountItem' && <SaleDiscountItemModal
                        isShowModal={isShowModal && modalType === 'discountItem'}
                        onCloseModal={this.closeModal}
                        onSubmitDiscount={this.onSubmitDiscountItem}
                        product={products.find((product, index) => indexRowAction === index) || {}}
                    />}
                    {!isEmpty(productsSO) && isShowModal && modalType === 'saleOrderCreate' && <SaleOrderCreateModal
                        isShowModal={isShowModal && modalType === 'saleOrderCreate'}
                        onCloseModal={this.closeModal}
                        onClickSave={this.createSaleOrder}
                        outletId={quotationInfo.outletId}
                        products={productsSO}
                    />}
                    <VendorSearchModal
                        isShowModal={isShowModal && modalType === 'vendor'}
                        onCloseModal={this.closeModal}
                        onSelected={this.onSelectedVendor}
                        outletId={quotationInfo.outletId}
                        channelCode={quotationInfo.saleChannelCode || '10'}
                    />
                    <JobIdBOQSearchModal
                        isShowModal={isShowModal && modalType === 'jobIdBOQ'}
                        onCloseModal={this.closeModal}
                        loading={loading}
                        onSubmitJobIdModal={this.onSubmitProductBOQ}
                        products={productBOQ}
                    />
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        QuotationInfoFormRedux: state.form.QuotationInfoFormRedux,
        SaleCustomerInfoFormRedux: state.form.SaleCustomerInfoFormRedux,
    }
}

const mapDispatchToProps = (dispatch) => ({
    resetQuotationInfoForm: () => dispatch(reset('QuotationInfoFormRedux')),
    resetSaleCustomerInfoForm: () => dispatch(reset('SaleCustomerInfoFormRedux')),
    resetCustomerDetailForm: () => dispatch(reset('CustomerDetailFormRedux')),
})

export default connect(mapStateToProps, mapDispatchToProps)(QuotationMaintainContainer)
