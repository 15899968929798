import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import moment from 'moment'
import swal from '@sweetalert/with-react'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import { get, isEmpty, uniqBy, first, sortBy, uniqWith, isEqual } from 'lodash'
import { Link } from 'react-router-dom'
import { Row, Col, Spin, Badge, Icon, message } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import { ROUTH_PATH, functionAlias } from 'routes'
import NotFoundContainer from "../../../NotFound";
import { formatNumber, saleTypes, getPermissionPage, isOutletIdAuth, sortDataColumn } from 'helpers'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    SelectCustom,
    DatePickerCustom,
    InputSearchCustom,
    NumberFormatCustom,
    InputCustom,
    TextAreaCustom,
} from 'components/common'
import {
    ProductSearchModal,
    SaleOrderInfoFormRedux,
    SaleCustomerInfoFormRedux,
    StoreLocationModal,
    SaleUnitModal,
    PromotionModal
} from 'components/feature'
import {
    SaleSummary,
    SaleDiscountItemModal,
} from 'components/form'
import DocumentFlowTable from './DocumentFlowTable'

import './style.css'

const initialProduct = {
    barcode: '',
    productCode: '',
}

const initialCustomerInfo = {
    scgId: '',
    jobId: '',
    customerCreditRemain: '',
    customerCreditLimit: '',
    customerUsedCredit: '',
}

const customerDefault = {
    customerId: 'OTC99',
    customerNameTH: 'ลูกค้าทั่วไป',
    customerCreditAllow: false,
    customerCreditLimit: 0,
    customerUsedCredit: 0,
}

class SaleOrderMaintainContainer extends Component {
    constructor(props) {
        super(props)
        this.state = this.getInitialState()
    }

    getInitialState = () => {
        const queryStringValue = queryString.parse(this.props.location.search)
        return {
            columns: [
                {
                    title: '',
                    dataIndex: 'delele',
                    key: 'delele',
                    align: 'center',
                    width: 45,
                    render: (props, row, index) => (
                        row.productName && <ButtonCustom
                            text="ลบ"
                            small
                            type="danger"
                            onClick={this.deleteProduct.bind(this, index)}
                            disabled={!!row.saleSOItemId || !!row.rejectId || !!row.freeGoodsFlag}
                        />
                    ),
                },
                {
                    title: '#',
                    dataIndex: 'soItem',
                    key: 'soItem',
                    align: 'center',
                    render: (props, row, index) => (
                        <div>{props || index + 1}</div>
                    ),
                },
                {
                    title: 'บาร์โค้ด',
                    dataIndex: 'barcode',
                    key: 'barcode',
                    align: 'center',
                    width: 140,
                    render: (props, row, index) => (
                        (this.state.products.length - 1 === index) ?
                            <InputCustom
                                small
                                onPressEnter={() => this.searchProduct(row)}
                                onChange={(e) => this.changeProductData('barcode', e.target.value, index)}
                                disabled={this.state.disabledEditData}
                            /> : props
                    )
                },
                {
                    title: 'รหัสสินค้า',
                    dataIndex: 'productCode',
                    key: 'productCode',
                    align: 'center',
                    width: 140,
                    render: (value, row, index) => (
                        (this.state.products.length - 1 === index) ?
                            <InputSearchCustom
                                small
                                onSubmit={() => this.searchProduct(row)}
                                onChange={(e) => this.changeProductData('productCode', e.target.value, index)}
                                onClickSearch={() => this.openModal('product')}
                                disabled={this.state.disabledEditData}
                                disabledButton={this.state.disabledEditData}
                            /> : value
                    )
                },
                {
                    title: 'ชื่อสินค้า',
                    dataIndex: 'productName',
                    key: 'productName',
                    align: 'center',
                    width: 180,
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'ประเภทสต็อค',
                    dataIndex: 'stockType',
                    key: 'stockType',
                    align: 'center',
                },
                {
                    title: 'คลัง',
                    dataIndex: 'slocId',
                    key: 'slocId',
                    align: 'center',
                    render: (props, row, index) => (
                        row.productName && <ButtonCustom
                            text={props}
                            small
                            type="link"
                            onClick={() => this.getStoreLocations(row, index)}
                            disabled={this.state.disabledEditData || !!row.rejectId}
                        />
                    ),
                },
                {
                    title: 'จำนวนที่ยืนยัน',
                    dataIndex: 'confirmQty',
                    key: 'confirmQty',
                    align: 'center',
                    width: 80,
                    render: (props, row) => (
                        row.productName && <div className="text-right">{formatNumber(props, 3)}</div>
                    ),
                },
                {
                    title: 'จำนวน',
                    dataIndex: 'qty',
                    key: 'qty',
                    align: 'center',
                    width: 100,
                    render: (props, row, index) => (
                        row.productName && <NumberFormatCustom
                            className="text-right"
                            small
                            onChange={(e) => this.changeProductData('qty', Number(`${e.target.value}`.replace(/,/g, '')), index)}
                            onKeyPress={(e) => e.key === 'Enter' && this.checkProductStockATP(row, index)}
                            value={props}
                            decimalScale={3}
                            disabled={this.state.disabledEditData || !!row.rejectId || !!row.freeGoodsFlag}
                        />
                    ),
                },
                {
                    title: 'หน่วยขาย',
                    dataIndex: 'unit',
                    key: 'unit',
                    align: 'center',
                    width: 100,
                    render: (props, row, index) => (
                        row.productName && <ButtonCustom
                            text={props}
                            small
                            type="link"
                            onClick={() => this.getUnit(row, index)}
                            disabled={this.state.disabledEditData || !!row.rejectId || !!row.freeGoodsFlag}
                        />
                    ),
                },
                {
                    title: 'ราคา/หน่วย',
                    dataIndex: 'pricePerUnit',
                    key: 'pricePerUnit',
                    align: 'center',
                    width: 100,
                    render: (props, row, index) => (
                        row.productName && row.flagOpenprice === true ? <NumberFormatCustom
                            className="text-right"
                            small
                            onChange={(e) => this.changeProductData('pricePerUnit', Number(`${e.target.value}`.replace(/,/g, '')), index)}
                            onKeyPress={(e) => e.key === 'Enter' && this.checkProductStockATP(row, index)}
                            value={props}
                            decimalScale={2}
                            disabled={this.state.disabledEditData || !!row.rejectId}
                        /> : row.productName && <div className="text-right">{formatNumber(props, 2)}</div>
                    )
                },
                {
                    title: 'ส่วนลดรวม',
                    dataIndex: 'totalDiscAmount',
                    key: 'totalDiscAmount',
                    align: 'center',
                    width: 150,
                    render: (props, row, index) => (
                        row.productName && <Row className="text-right" justify="space-between">
                            <Col xs={18} style={{ paddingTop: '3px' }}>
                                <span>{formatNumber(props, 2)}</span>
                            </Col>
                            <Col xs={6}>
                                <ButtonCustom
                                    type="default"
                                    icon="search"
                                    small
                                    onClick={() => {
                                        this.setState({ indexRowAction: index }, () => {
                                            this.openModal('discountItem')
                                        })
                                    }}
                                    disabled={this.state.disabledEditData || !!row.rejectId}
                                />
                            </Col>
                        </Row>
                    ),
                },
                {
                    title: 'จำนวนเงิน',
                    dataIndex: 'netAmount',
                    key: 'netAmount',
                    align: 'center',
                    width: 100,
                    render: (props, row) => (
                        row.productName && <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                },
                {
                    title: 'วันที่ส่งมอบ',
                    dataIndex: 'deliveryDate',
                    key: 'deliveryDate',
                    align: 'center',
                    width: 120,
                    render: (props, row, index) => (
                        row.productName && <DatePickerCustom
                            small
                            onChange={(date) => this.changeProductData('deliveryDate', date, index)}
                            value={props}
                            allowClear={false}
                            disabled={this.state.disabledEditData || !!row.rejectId || !!row.freeGoodsFlag}
                        />
                    ),
                },
                // {
                //     title: 'Type',
                //     dataIndex: 'specialOrderFlag',
                //     key: 'specialOrderFlag',
                //     align: 'center',
                //     width: 120,
                //     render: (props, row, index) => (
                //         row.productName && <SelectCustom
                //             small
                //             onChange={(val) => this.changeProductData('specialOrderFlag', val, index)}
                //             options={saleTypes}
                //             value={props}
                //             disabled={this.state.disabledEditData || !!row.rejectId}
                //         />
                //     ),
                // },
                {
                    title: 'ข้อมูลเพิ่มเติม/เหตุผลการปฏิเสธ',
                    dataIndex: 'rejectId',
                    key: 'rejectId',
                    align: 'center',
                    width: 180,
                    render: (props, row, index) => (
                        row.productName && <SelectCustom
                            small
                            onChange={(val) => this.changeRejectIdProduct(row, val, index)}
                            options={!!row.rejectIdInitial ? this.state.rejections : this.state.rejections.filter(o => o.rejectId !== 5)}
                            optionsDefault={[{ label: '-', value: 0 }]}
                            value={props}
                            disabled={!(this.state.soNumber && this.state.outletId) || this.state.disabledEditData || !!row.rejectIdInitial}
                        />
                    ),
                },
                {
                    title: 'รับแล้ว',
                    dataIndex: 'receivedQty',
                    key: 'receivedQty',
                    align: 'center',
                    width: 80,
                    render: (props, row) => (
                        row.productName && <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                },
                {
                    title: 'ค้างรับ',
                    dataIndex: 'remainQty',
                    key: 'remainQty',
                    align: 'center',
                    width: 80,
                    render: (props, row) => (
                        row.productName && <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                },
            ],
            columnsDiscount: [
                {
                    title: '#',
                    align: 'center',
                    dataIndex: 'action',
                    key: 'action',
                },
                {
                    title: 'โค้ดส่วนลด',
                    align: 'center',
                    dataIndex: 'discountCode',
                    key: 'discountCode',
                    ellipsis: true,
                },
            ],
            loading: false,
            products: [
                initialProduct,
            ],
            saleOrderSearch: {
                soNumber: '',
                outletId: '',
                outlets: [],
            },
            saleOrderInfo: {
                quotationNumber: '',
                documentDate: moment(),
                documentTypeCode: '',
                saleChannelCode: '',
                outletId: '',
                outletRole: '',
                saleEmployeeCode: '',
                shippingPoint: '',
                paymentTermCode: '',
                taxType: '',
                specialOrderFlag: get(saleTypes, '0.value')
            },
            promotions: [],
            storeLocations: [],
            outlets: [],
            documentTypes: [],
            taxTypes: [],
            saleChannels: [],
            shippingPoints: [],
            paymentTerms: [],
            saleEmployees: [],
            rejections: [],
            saleTypes,
            isErrorSubmitProduct: false,
            isErrorSubmitCustomer: false,
            isErrorSubmitCustomerShipping: false,
            isErrorSubmitSaleOrder: false,
            isShowDiscountTable: false,
            isShowModal: false,
            modalType: '',
            remark: '',
            customerInfo: initialCustomerInfo,
            customerIdSearch: customerDefault.customerId,
            customer: customerDefault,
            customerShipping: {},
            customerShippings: [],
            totalSummary: {
                totalAmount: 0,
                totalDiscAmount: 0,
                netAmount: 0,
                vatAmount: 0,
                discPercent: 0,
                discPercentAmount: 0,
                discAmount: 0,
                discPromotion: 0,
                discPromotionInternal: 0,
                discPromotionExternal: 0,
                discCouponPercent: 0,
                discCouponPercentAmount: 0,
                discSCGIDCode: '',
                discSCGIDAmount: 0,
                discCouponAmount: 0,
            },
            indexRowAction: null,
            soNumber: '',
            outletId: '',
            saleOrderDetail: {
                promotionCode: ''
            },
            disabledEditData: false,
            disabledAllButton: false,
            disabledReprocessButton: false,
            documentFlows: [],
            queryStringValue,
            permissionPage: getPermissionPage(functionAlias.saleOrderMaintain),
        }
    }

    componentDidMount() {
        const outletId = get(this.props, 'match.params.outletId')
        if (outletId && !isOutletIdAuth(outletId)) {
            this.getAlertAccessDenied()
            return
        }

        this.initiatData()
    }

    componentDidUpdate(prevProps) {
        const params = this.props.match.params
        const paramsPrev = prevProps.match.params
        if (params.soNumber !== paramsPrev.soNumber || params.outletId !== paramsPrev.outletId) {
            this.initiatData()
        }
    }

    initiatData = () => {
        const { soNumber, outletId } = get(this.props, 'match.params', {})
        if (soNumber && outletId) {
            if (!this.state.permissionPage.authDisplay) {
                this.getAlertAccessDenied()
                return
            }

            this.setState({ soNumber, outletId }, () => this.fetchDataDefault())
        } else {
            this.fetchDataDefault()
        }
    }

    getAlertAccessDenied = () => {
        swal('Error', 'คุณไม่มีสิทธิ์ในการเข้าถึงข้อมูล', 'error').then(() => {
            this.props.history.push(ROUTH_PATH.ACCESS_DENIED)
        })
    }

    resetState = () => {
        this.setState(this.getInitialState())
    }

    getSaleOrderDetail = async (soNumber, outletId) => {
        await apiServices.callApi('post', API_PATH.POST_GET_DETAIL_SALE_ORDER, { soNumber, outletId }).then(async (res) => {
            const detail = get(res, 'data.results.0')
            if (!isEmpty(detail)) {
                const { customerInfo } = this.state
                let customerData = await this.getCustomerData(detail.customerId || 'OTC99', outletId)
                const partnerModels = detail.salesSOPartnerFunctionModelList.map(partner => ({
                    salesSOPartnerFunctionId: partner.salesSOPartnerFunctionId,
                    customerOutletId: partner.outletId,
                    soNumber,
                    customerId: partner.customerId,
                    customerRole: partner.partnerType,
                    customerType: partner.customerType,
                    customerTitle: partner.title,
                    customerNameTH: partner.customerName,
                    customerTaxId: partner.taxId,
                    customerTaxBranch: partner.taxBranch,
                    customerScgFamilyId: '',
                    customerAddress: partner.address,
                    customerSubDistrict: partner.subDistrict,
                    customerDistrict: partner.district,
                    customerProvince: partner.province,
                    customerCountry: partner.country,
                    customerPostalCode: partner.postalCode,
                    customerPhone: partner.phone,
                    customerFax: partner.fax,
                    customerEmail: partner.email,
                    customerCreditAllow: !isEmpty(customerData) ? customerData.customerCreditAllow : false,
                    customerCreditLimit: !isEmpty(customerData) ? customerData.customerCreditLimit : 0,
                    customerUsedCredit: !isEmpty(customerData) ? customerData.customerUsedCredit : 0,
                    customerAvailableCredit: !isEmpty(customerData) ? customerData.customerAvailableCredit : 0,
                    customerPaymentTerm: !isEmpty(customerData) ? customerData.customerPaymentTerm : '',
                }))
                const customer = partnerModels.find(partner => partner.customerRole === 'sold_to')
                const customerShipping = partnerModels.find(partner => partner.customerRole === 'ship_to')
                // get stock atp, price
                const productsModel = await this.getProducts(detail.salesSOItemModelList, outletId)
                const products = productsModel.map(product => ({
                    ...product,
                    saleSOItemId: product.saleSOItemId,
                    totalAmount: product.pricePerUnit * product.qty,
                    deliveryDate: product.deliveryDate ? moment(product.deliveryDate) : null,
                    // cannot change from input screen
                    rejectIdInitial: product.rejectId,
                    pecialOrderFlag: product.flagOpenprice === false && product.productType === 'service' ? true : false,//product.specialOrderFlag ? 'special' : 'normal',
                    outletRole: product.outletRole
                }))

                /*
                // if use state should set intial state on top!
                if (detail.documentStatus === 'BLOCK' && permissionPage.authApprove === true) {
                    disabledApproveBtn = false;
                    showApproveBtn = true;
                } else if (detail.documentStatus === 'BLOCK' && permissionPage.authApprove === false) {
                    showApproveBtn = true;
                }
                */
                const {
                    discAmount,
                    discCouponAmount,
                    discCouponPercent,
                    discCouponPercentAmount,
                    discPercent,
                    discPercentAmount,
                    discPromotion,
                    discPromotionInternal,
                    discPromotionExternal,
                    discSCGIDAmount,
                    discSCGIDCode,
                    netAmount,
                    totalAmount,
                    totalDiscAmount,
                    totalExcVatAmount,
                    vatAmount,
                } = detail
                const totalSummary = {
                    discAmount,
                    discCouponAmount,
                    discCouponPercent,
                    discCouponPercentAmount,
                    discPercent,
                    discPercentAmount,
                    discPromotion,
                    discPromotionInternal,
                    discPromotionExternal,
                    discSCGIDAmount,
                    discSCGIDCode,
                    netAmount,
                    totalAmount,
                    totalDiscAmount,
                    totalExcVatAmount,
                    vatAmount,
                }
                this.setState({
                    totalSummary,
                    saleOrderInfo: {
                        quotationNumber: detail.refDocNumber,
                        documentDate: moment(detail.documentDate),
                        outletId: detail.outletId,
                        documentTypeCode: detail.documentTypeCode,
                        saleChannelCode: detail.saleChannelCode,
                        saleEmployeeCode: detail.saleEmpCode,
                        shippingPoint: get(detail.salesSOItemModelList, '0.shippingPoint'), //get(products, '0.shippingPoint'),
                        paymentTermCode: detail.paymentTermCode,
                        taxType: detail.taxType,
                        specialOrderFlag: get(detail.salesSOItemModelList, '0.specialOrderFlag') ? 'special' : 'normal',//get(products, '0.specialOrderFlag') ? 'special' : 'normal',
                    },
                    customerInfo: {
                        ...customerInfo,
                        scgId: detail.scgid,
                        jobId: detail.jobId,
                    },
                    saleOrderDetail: detail,
                    remark: detail.remark,
                    products: [...products, initialProduct],
                }, () => {
                    if (!!customer && !customer.customerScgFamilyId) {
                        customer.customerScgFamilyId = detail.scgid
                    }
                    this.onSelectedCustomer(customer, customerShipping, true)
                })
            } else {
                swal({
                    title: 'Error',
                    icon: 'error',
                    text: 'ไม่พบข้อมูลใบสั่งขายสินค้า',
                    buttons: {
                        create: { text: 'สร้างใบสั่งขายสินค้า', value: true },
                        cancel: 'ปิด',
                    },
                }).then((value) => {
                    if (value) {
                        this.props.history.push(ROUTH_PATH.SALE_ORDER_CREATE)
                    }
                })
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลใบสั่งขายสินค้าได้ กรุณาโหลดข้อมูลใหม่อีกครั้ง')
            swal('Error', message, 'error')
            this.setState({ disabledEditData: true, disabledAllButton: true })
        })
    }

    getProducts = async (products, outletId) => {
        let productsModel = []
        const body = {
            productFilters: products.map(product => ({
                productId: product.productCode,
                outletId: outletId,
                storageLocationId: product.slocId,
                unitId: product.unit,
            })),
            isIncludeStockATP: true,
        }
        await apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCTS}?getBy=${this.props.auth.userId}`, body).then(res => {
            const productsData = get(res, 'data.results', [])
            if (res.status === 200 && !isEmpty(productsData)) {
                productsModel = products.map(product => {
                    const data = productsData.find(productData =>
                        product.productCode === productData.productId
                        && product.unit === productData.unitId
                        && product.outletId === productData.outletId
                        && (product.slocId ? product.slocId === productData.storageLocationId : true)
                    )
                    // get product detail from this data
                    if (data) {
                        return {
                            ...product,
                            retailPrice: data.retailPrice,
                            projectPrice: data.projectPrice,
                            retailCost: data.retailCost,
                            projectCost: data.projectCost,
                            productType: data.productType,
                            stockQty: data.qty,
                            outletRole: data.outletRole,
                        }
                    } else {
                        return product
                    }
                })
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการดึงข้อมูลสินค้า กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        return productsModel
    }

    // get data default for dropdown
    fetchDataDefault = async () => {
        this.setState({ loading: true })
        const { auth } = this.props
        const { arrayOutlet } = auth
        const { soNumber, outletId } = this.state
        let isEditMode = false

        if (!isEmpty(soNumber) && !isEmpty(outletId)) {
            isEditMode = true
            await this.getSaleOrderDetail(soNumber, outletId)
            if (!this.state.saleOrderDetail.soNumber) {
                this.setState({ loading: false })
                return
            }
        }
        // outlet - get outlets of sale order detail and authorized user
        const outletIds = isEditMode ? [...arrayOutlet, outletId] : arrayOutlet
        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, { outletIds }).then(res => {
            let outlets = get(res, 'data.results', [])
            outlets = outlets.map(data => (
                { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
            ))
            const outletIdDefault = get(outlets, '0.value', '')
            const outletRoldDefault = get(outlets, '0.outletRole', '')
            const outletsAuth = outlets.filter(outlet => arrayOutlet.includes(outlet.outletId))
            const { saleOrderSearch, saleOrderInfo } = this.state
            this.setState({
                outlets,
                saleOrderSearch: {
                    ...saleOrderSearch,
                    outletId: get(outletsAuth, '0.value', ''),
                    outlets: outletsAuth,
                },
                saleOrderInfo: (isEditMode) ? saleOrderInfo : { ...saleOrderInfo, outletId: outletIdDefault, outletRold: outletRoldDefault },
            })
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // document type
        await apiServices.callApi('get', `${API_PATH.GET_MASTER_DOCUMENT_TYPE}?SubModule=so`).then(res => {
            let documentTypes = get(res, 'data.results', [])
            const { saleOrderInfo: { outletId } } = this.state

            documentTypes = documentTypes.map(data => (
                { ...data, label: `${data.documentTypeCode} - ${data.documentTypeDescription}`, value: data.documentTypeCode }
            ))

            let documentTypeDefault = {}
            if (!isEmpty(documentTypes)) {
                documentTypeDefault = documentTypes.find(type => type.documentTypeCode === 'ZOCS') || {}
                if (outletId === 'A020') {
                    if (documentTypeDefault) documentTypes = [documentTypeDefault]
                }
            }

            const { saleOrderInfo } = this.state
            this.setState({
                documentTypes,
                saleOrderInfo: (isEditMode) ? saleOrderInfo : { ...saleOrderInfo, documentTypeCode: documentTypeDefault.value },
            })
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลประเภทการขายได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // tax type
        await apiServices.callApi('get', API_PATH.GET_MASTER_TAX_TYPE).then(res => {
            let taxTypes = get(res, 'data.results', [])
            const auhtTaxTypeIds = auth.taxTypeId.split(',')
            // filter tax type by auth
            taxTypes = taxTypes.filter(taxType => auhtTaxTypeIds.includes(taxType.taxTypeId))

            if (!isEmpty(taxTypes)) {
                taxTypes = taxTypes.map(data => (
                    { ...data, label: `${data.taxDescription} %`, value: data.taxTypeId }
                ))
                // default vat 7 percent
                const dataVat7 = taxTypes.find(taxType => taxType.taxTypeId === 'V7')
                const { saleOrderInfo } = this.state

                this.setState({
                    taxTypes,
                    saleOrderInfo: (isEditMode) ? saleOrderInfo : { ...saleOrderInfo, taxType: dataVat7 ? dataVat7.value : taxTypes[0].value },
                })
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลประเภทภาษีได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // sale channel
        await apiServices.callApi('get', API_PATH.GET_MASTER_SALE_CHANNEL).then(res => {
            let saleChannels = get(res, 'data.results', [])
            saleChannels = saleChannels.map(data => (
                { ...data, label: `${data.saleChannelCode} - ${data.saleChannelDescription}`, value: data.saleChannelCode }
            ))
            const { saleOrderInfo } = this.state
            this.setState({
                saleChannels,
                saleOrderInfo: (isEditMode) ? saleOrderInfo : { ...saleOrderInfo, saleChannelCode: saleChannels[0].value },
            })
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลช่องทางได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // sale employee
        await this.getEmployees()

        // sale shipping point
        await apiServices.callApi('get', `${API_PATH.GET_MASTER_SALE_SHIPPING_POINT}?ActiveStatus=true`).then(res => {
            let shippingPoints = get(res, 'data.results', [])
            shippingPoints = shippingPoints.map(data => (
                { ...data, label: `${data.shippingPointCode} - ${data.shippingPointDescription}`, value: data.shippingPointCode }
            ))
            this.setState({ shippingPoints })
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลเงื่อนไขการส่งมอบได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // payment term
        await apiServices.callApi('get', `${API_PATH.GET_MASTER_PAYMENT_TERM}?ActiveStatus=true`).then(res => {
            let paymentTerms = get(res, 'data.results', [])
            paymentTerms = paymentTerms.map(data => (
                {
                    ...data,
                    label: `${data.paymentTermCode} - ${data.paymentTermDescription}`,
                    value: data.paymentTermCode,
                    paymentTermValue: data.value,
                }
            ))
            const { saleOrderInfo } = this.state
            this.setState({
                paymentTerms,
                saleOrderInfo: (isEditMode) ? saleOrderInfo : { ...saleOrderInfo, paymentTermCode: paymentTerms[0].value },
            })
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Credit Term ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        if (!isEditMode) {
            // remark
            await this.getRemark()
        }

        if (isEditMode) {
            this.disabledEditData();
            await this.getDocFlow();
            await this.calculateTotalSummary();

            // rejections 
            await apiServices.callApi('get', `${API_PATH.GET_MASTER_SALE_REJECT}/SO`).then(res => {
                let rejections = get(res, 'data.results', [])
                rejections = rejections.map(data => (
                    { ...data, label: data.rejectDescription, value: data.rejectId }
                ))
                this.setState({ rejections })
            }).catch(error => {
                const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลเหตุผลการปฏิเสธได้ กรุณาลองใหม่อีกครั้ง')
                swal('Error', message, 'error')
            })

            if (!isEmpty(this.state.saleOrderDetail) && !isEmpty(this.state.saleOrderDetail.customerId) && this.state.saleOrderDetail.customerId !== 'OTC99') {
                await this.searchCustomer(this.state.saleOrderDetail.customerId, isEditMode);
            }
        }

        this.setState({ loading: false })
    }

    getDocFlow = async () => {
        const { outletId, soNumber, saleOrderDetail: { refCreditNotes } } = this.state
        const body = {
            outletId,
            soNumber,
        }
        await apiServices.callApi('post', API_PATH.POST_GET_SALES_DOCUMENT_FLOWS, body).then(async res => {
            let documentFlows = get(res, 'data.results', [])
            if (res.status === 200) {
                const docFlowCheck = documentFlows.map(async (item, data) => {
                    if (item.documentTypeCode === 'ZDO') {
                        const GIres = await apiServices.callApi('post', API_PATH.POST_GET_SALE_DO_HEAD, { outletId, doNumber: item.documentNo })
                        const giStatus = get(GIres, 'data.results.0.giStatus', false)
                        if (!giStatus) {
                            item.documentTypeCode = 'SHIPMENT'
                            item.documentTypeDesc = 'ใบจัดสินค้า'
                        }
                    }
                    return item
                })
                documentFlows = await Promise.all(docFlowCheck)
                this.setState({ documentFlows }, () => {
                    //this.disabledEditData()
                })
            }
        }).catch(error => {
            console.log(error)
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการดึงข้อมูล Document Flow กรุณารีเฟรชใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        for (const createNoteNo of refCreditNotes) {
            await apiServices.callApi('post', API_PATH.POST_GET_SALES_DOCUMENT_FLOWS, {
                outletId,
                soNumber: createNoteNo
            }).then(async (res) => {
                let documentFlows = get(res, 'data.results', [])
                if (res.status === 200) {
                    const docFlowCheck = documentFlows.map(async (item, data) => {
                        if (item.documentTypeCode === 'ZDO') {
                            const GIres = await apiServices.callApi('post', API_PATH.POST_GET_SALE_DO_HEAD, { outletId, doNumber: item.documentNo })
                            const giStatus = get(GIres, 'data.results.0.giStatus', false)
                            if (!giStatus) {
                                item.documentTypeCode = 'SHIPMENT'
                                item.documentTypeDesc = 'ใบจัดสินค้า'
                            }
                        }
                        return item
                    })
                    documentFlows = await Promise.all(docFlowCheck)
                    let filteredList = [...this.state.documentFlows, ...documentFlows];
                    let newFilter = []
                    // let filteredListN = [...new Set(this.state.documentFlows.map(JSON.stringify))
                    //     , ...new Set(documentFlows.map(JSON.stringify))].map(JSON.parse);
                    // console.log('filteredListN', filteredListN);
                    // documentNo: "3200000181"
                    // documentStatus: "OPEN"
                    // documentTypeCode: "ZINT"
                    filteredList.map(item => {
                        //console.log(newFilter.findIndex(itemFilter => itemFilter.documentNo === item.documentNo &&
                        //    itemFilter.documentTypeCode === item.documentTypeCode));

                        if (newFilter.findIndex(itemFilter => itemFilter.documentNo === item.documentNo &&
                            itemFilter.documentTypeCode === item.documentTypeCode) === -1) {
                            newFilter.push(item)
                        }
                    })

                    // filteredList = [...filteredList,...new Set(documentFlows.map(JSON.stringify))].map(JSON.parse);

                    // [, ...documentFlows]
                    // filteredList = uniqWith(filteredList, isEqual);

                    this.setState({ documentFlows: newFilter }, () => {
                        //this.disabledEditData()
                    })
                }
            }).catch(error => {
                console.log(error)
                const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการดึงข้อมูล Document Flow กรุณาลองใหม่อีกครั้ง')
                swal('Error', message, 'error')
            })
        }

    }

    getRemark = async () => {
        const { saleOrderInfo: { outletId, documentTypeCode } } = this.state
        await apiServices.callApi('get', `${API_PATH.GET_COMMON_DEFAULT_REMARK}/${outletId}/${documentTypeCode}/true`).then(res => {
            const remark = get(res, 'data.results.0.remarkDescription', '')
            if (res.status === 200) {
                this.setState({ remark })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลหมายเหตุได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })
    }

    getStoreLocations = async (product, index) => {
        this.setState({ loading: true })
        const { auth } = this.props
        const body = {
            productId: product.productCode,
            unitId: product.unit,
            outletId: product.outletId,
            isIncludeStockATP: true,
            storageLocationIds: auth.arrayStorageLocationId,
            limitMaxRange: 100,
            productStatus: true
        }

        await apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCT}?getBy=${auth.userId}`, body).then(res => {
            let storeLocations = get(res, 'data.results')

            if (res.status === 200) {
                storeLocations = uniqBy(storeLocations, 'storageLocationId')
                this.setState({
                    storeLocations,
                    indexRowAction: index,
                }, () => {
                    this.openModal('storeLocation')
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลคลังได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    getCustomerData = async (customerId, outletId) => {
        if (isEmpty(customerId)) return

        if (customerId === "OTC99")
            return customerDefault;

        const { saleOrderInfo } = this.state

        try {
            let url = `${API_PATH.GET_CUSTOMER}?OutletId=${outletId}&customerId=${customerId}`
            if (saleOrderInfo.outletRold && saleOrderInfo.outletRold.toUpperCase() === "BOUTIQUE" && saleOrderInfo.documentTypeCode === "ZOCR") {
                url = `${url}&ScgFlag=${true}`
            }

            const res = await apiServices.callApi('get', url)
            if (res.status === 200) {
                return get(res, 'data.results.0', {})
            }
        } catch (error) {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลลูกค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }
    }

    getEmployees = async () => {
        const { saleOrderInfo: { outletId } } = this.state
        await apiServices.callApi('get', `${API_PATH.GET_SALE_EMPLOYEE}?OutletId=${outletId}`).then(res => {
            let saleEmployees = get(res, 'data.results', [])
            saleEmployees = saleEmployees.map(data => (
                { ...data, label: `${data.saleEmployeeId} - ${data.saleEmployeeName}`, value: data.saleEmployeeId }
            ))
            this.setState({ saleEmployees })
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลพนักงานขายได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })
    }

    changeSaleOrderInfo = (key, val) => {
        const { products, outlets, customer } = this.state
        const { SaleOrderInfoFormRedux: { values } } = this.props
        switch (key) {
            case 'outletId':
                // if outlet id changed - clear products, employee, customer and fetch new employees, products, remark
                let outletRoldDefault = outlets.find(type => type.value === val).outletRole;
                this.setState({
                    saleOrderInfo: { ...values, outletId: val, saleEmployeeCode: '', outletRold: outletRoldDefault },
                    products: [initialProduct],
                }, () => {
                    this.resetCustomerInfo()
                    this.calculateTotalSummary()
                    this.getEmployees()
                    this.getRemark()
                })
                break
            case 'saleChannelCode':
                // change price products
                this.setState({
                    saleOrderInfo: { ...values, saleChannelCode: val },
                }, () => {
                    this.setState({
                        products: products.map(product => {
                            const pricePerUnit = this.calculatePrice(product)
                            return ({
                                ...product,
                                pricePerUnit,
                                costPerUnit: this.calculateCost(product),
                                totalAmount: pricePerUnit * product.qty,
                            })
                        }),
                    }, () => {
                        this.calculateTotalSummary()
                    })
                })
                break
            case 'documentTypeCode':
                this.setState({
                    saleOrderInfo: {
                        ...values,
                        documentTypeCode: val,
                        paymentTermCode: val === 'ZOCS' ? 'NT00' : customer.customerPaymentTerm ? customer.customerPaymentTerm : values.paymentTermCode,
                    },
                }, () => {
                    this.getRemark()
                })
                break
            default:
                this.setState({ saleOrderInfo: { ...values, [key]: val } })
        }
    }

    onSelectedStoreLocation = (data) => {
        const { indexRowAction } = this.state
        const productSelected = data.dataSelected
        this.setState({
            products: this.state.products.map((product, i) => (
                i === indexRowAction ? {
                    ...product,
                    slocId: productSelected.storageLocationId,
                    stockQty: productSelected.qty,
                } : product
            )),
            indexRowAction: null,
        })
        this.closeModal()
    }

    getUnit = async (product, index) => {
        this.setState({ loading: true })
        const body = {
            productId: product.productCode,
            storageLocationId: product.slocId,
            outletId: product.outletId,
            limitMaxRange: 100,
            productStatus: true,
        }
        await apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCT}?getBy=${this.props.auth.userId}`, body).then(res => {
            let units = get(res, 'data.results')
            units = units.map(unit => ({
                ...unit,
                pricePerUnit: this.calculatePrice(unit)
            }))
            // units = uniqBy(units, 'unitId')
            // console.log('units',units);
            if (res.status === 200) {
                this.setState({
                    units,
                    indexRowAction: index,
                }, () => {
                    this.openModal('unit')
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลหน่วยสินค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    onSelectedUnit = async (productSelected) => {
        const { indexRowAction } = this.state
        const { auth } = this.props
        this.setState({ loading: true })
        const body = {
            //barcode: productSelected.barcode,
            unitId: productSelected.unitId,
            productId: productSelected.productId,
            outletId: productSelected.outletId,
            storageLocationId: productSelected.storageLocationId,
            productStatus: true,
        }

        await apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCT}?getBy=${auth.userId}`, body).then(res => {
            const products = get(res, 'data.results')
            if (res.status === 200 && !isEmpty(products)) {
                let product = products.find(product => product.unitId === productSelected.unitId && product.productId === productSelected.productId && product.outletId === productSelected.outletId)
                if (!isEmpty(product)) {
                    productSelected.pricePerUnit = this.calculatePrice(product)
                    productSelected.retailPrice = product.retailPrice || 0
                    productSelected.projectPrice = product.projectPrice || 0
                    productSelected.retailCost = product.retailCost || 0
                    productSelected.projectCost = product.projectCost || 0
                    productSelected.costPerUnit = product.retailCost || 0
                }
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลสินค้าได้ กรุณาลองใหม่อีกครั้ง')
            console.log(message)
        }).finally(() => {
            this.setState({
                products: this.state.products.map((product, i) => (
                    i === indexRowAction ? {
                        ...product,
                        unit: productSelected.unitId,
                        barcode: productSelected.barcode,
                        pricePerUnit: productSelected.pricePerUnit,
                        totalAmount: productSelected.pricePerUnit * product.qty,
                        retailPrice: productSelected.retailPrice,
                        projectPrice: productSelected.projectPrice,
                        retailCost: productSelected.retailCost,
                        projectCost: productSelected.projectCost,
                        costPerUnit: productSelected.costPerUnit,
                    } : product
                )),
                indexRowAction: null,
                loading: false,
            }, () => {
                this.calculateTotalSummary()
            })
        })

        this.closeModal()
    }

    toggleIsShowDiscountTable = () => {
        this.setState({ isShowDiscountTable: !this.state.isShowDiscountTable })
    }

    openModal = (modalType) => {
        this.setState({ isShowModal: true, modalType })
    }

    closeModal = () => {
        this.setState({ isShowModal: false, modalType: '' })
    }

    changeRejectIdProduct = (row, rejectId, index) => {
        const products = this.state.products.map((product, i) => {
            if (i === index) {
                return {
                    ...product,
                    rejectId,
                }
            } else {
                return product
            }
        })
        this.setState({ products }, () => {
            this.calculateTotalSummary()
        }
        )
    }

    resetCustomerInfo = () => {
        this.setState({
            customerIdSearch: customerDefault.customerId,
            customer: customerDefault,
            customerShipping: {},
            customerShippings: [],
        })
    }

    changeCustomerIdSearch = (customerId) => {

        this.setState({ customerIdSearch: customerId })
    }

    filterPaymentTerms = (customerPaymentTermCode) => {
        const { paymentTerms } = this.state
        let paymentTermsFiltered = paymentTerms.filter(paymentTerm => paymentTerm.paymentTermCode === 'NT00')
        if (customerPaymentTermCode) {
            const data = paymentTerms.find(paymentTerm => paymentTerm.paymentTermCode === customerPaymentTermCode)
            if (data) {
                paymentTermsFiltered = paymentTerms.filter(paymentTerm => paymentTerm.paymentTermValue <= data.paymentTermValue)
            }
        }
        return paymentTermsFiltered
    }

    onSelectedCustomer = async (customer, customerShipping = {}, editMode = false) => {
        this.closeModal()
        const {
            saleOrderInfo: { outletId },
            customerInfo,
            documentTypes,
            soNumber,
            taxTypes,
            paymentTerms,
        } = this.state
        const { SaleOrderInfoFormRedux: { values } } = this.props

        if (isEmpty(customer)) return

        // create mode - default tax type by customer auth
        if (!(soNumber && this.state.outletId) && customer.customerTaxType && taxTypes.find(taxType => taxType.taxTypeId === customer.customerTaxType)) {
            this.changeSaleOrderInfo('taxType', customer.customerTaxType)
        }

        // change payment term when payment term of customer auth lower than payment term selected
        if (customer.customerPaymentTerm && !isEmpty(paymentTerms)) {
            const customerPaymentTermData = paymentTerms.find(paymentTerm => paymentTerm.paymentTermCode === customer.customerPaymentTerm)
            const selectedPaymentTermData = paymentTerms.find(paymentTerm => paymentTerm.paymentTermCode === values.paymentTermCode)
            // if (customerPaymentTermData && selectedPaymentTermData
            //     && customerPaymentTermData.paymentTermValue < selectedPaymentTermData.paymentTermValue) {
            //     this.changeSaleOrderInfo('paymentTermCode', customer.customerPaymentTerm)
            // }
            if (customerPaymentTermData && selectedPaymentTermData) {
                if (values.documentTypeCode === 'ZOCR') {
                    this.changeSaleOrderInfo('paymentTermCode', customer.customerPaymentTerm)
                }
            }
        }

        const url = `${API_PATH.GET_CUSTOMER}?OutletId=${outletId}&customerId=${customer.customerId}&RequireShipTo=true`
        await apiServices.callApi('get', url).then(async res => {
            const customerShippings = get(res, 'data.results', [])
            if (res.status === 200) {
                this.setState({
                    customer,
                    customerShipping: !isEmpty(customerShipping) ? customerShipping : customer,
                    customerShippings: [...customerShippings, customer],
                    customerInfo: {
                        ...customerInfo,
                        ...customer,
                        //scgId: customer.customerScgFamilyId ? customer.customerScgFamilyId : customerInfo.scgId,
                        scgId: customer.customerScgFamilyId ? customer.customerScgFamilyId : '',
                        customerCreditLimit: formatNumber((customer.customerCreditLimit || 0), 2),
                        customerUsedCredit: formatNumber((customer.customerUsedCredit || 0), 2),
                        customerCreditRemain: formatNumber((customer.customerAvailableCredit || 0), 2),
                    },
                    customerIdSearch: customer.customerId,
                    isErrorSubmitCustomer: false,
                    isErrorSubmitCustomerShipping: false,
                })

                if (!customer.customerCreditAllow && values && values.documentTypeCode === 'ZOCR') {
                    const documentTypeDefault = documentTypes.find(type => type.documentTypeCode === 'ZOCS') || {}
                    this.changeSaleOrderInfo('documentTypeCode', documentTypeDefault.value)
                }
            }

        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลลูกค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        if (!editMode) {
            try {
                // คำนวน ส่วนลด
                await this.calculateTotalSummary()
            } catch (error) {
                const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลลูกค้าได้ กรุณาลองใหม่อีกครั้ง')
                swal('Error', message, 'error')
            }
        }
    }

    onSelectedShipping = (shipping) => {
        this.setState({ customerShipping: shipping })
    }

    changeCustomerInfo = (customer) => {
        this.setState({ customer })
    }

    searchCustomer = async (customerId, isEditMode = false) => {
        if (!customerId) {
            message.error('กรุณากรอกรหัสลูกค้า')
            return
        } else if (customerId === customerDefault.customerId) {
            this.onSelectedCustomer(customerDefault, {}, isEditMode)
            return
        }

        this.setState({ loading: true })
        const { saleOrderInfo: { outletId, outletRold, documentTypeCode } } = this.state
        let url = `${API_PATH.GET_CUSTOMER}?OutletId=${outletId}&customerId=${customerId}&RequireSoldTo=true`
        if (outletRold && outletRold.toUpperCase() === "BOUTIQUE" && documentTypeCode === "ZOCR") {
            url = `${url}&ScgFlag=${true}`;
        }
        await apiServices.callApi('get', url).then(res => {
            const customers = get(res, 'data.results', [])
            if (res.status === 200 && !isEmpty(customers)) {
                this.onSelectedCustomer(customers[0], {}, isEditMode)
                this.setState({ customers })
            } else {
                message.error('ไม่พบข้อมูลลูกค้า')
                this.resetCustomerInfo()
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลลูกค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
            this.props.history.push(ROUTH_PATH.NOTFOUND)
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    changeRemark = (e) => {
        this.setState({ remark: e.target.value })
    }

    deleteProduct = (index) => {
        const products = [...this.state.products]
        products.splice(index, 1)
        this.setState({ products }, () => {
            this.calculateTotalSummary()
        })
    }

    changeProductData = (key, value, index) => {
        this.setState({
            products: this.state.products.map((product, i) => (i === index ? { ...product, [key]: value } : product)),
        })
    }

    changeQtyProductData = (row, index) => {
        this.setState({
            products: this.state.products.map((product, i) => (i === index ? { ...product, totalAmount: row.pricePerUnit * row.qty } : product)),
        }, () => {
            this.calculateTotalSummary()
        })
    }

    checkProductStockATP = (row, index) => {
        if (Number(row.qty) > Number(row.stockQty || 0)) {
            swal({
                title: 'Warnning',
                icon: 'error',
                text: 'สินค้าในคลังมีจำนวนไม่เพียงพอ อาจจะทำให้ไม่สามารถส่งของได้ คุณต้องการสั่งหรือไม่?',
                buttons: {
                    create: { text: 'ยืนยัน', value: true },
                    cancel: 'ยกเลิก',
                },
            }).then((value) => {
                if (value) {
                    this.changeQtyProductData(row, index)
                } else {
                    const { saleOrderDetail } = this.state
                    const qtyDefault = saleOrderDetail ? get(saleOrderDetail, `salesSOItemModelList.${index}.qty`, 1) : 1
                    this.setState({
                        products: this.state.products.map((product, i) => (i === index ? { ...product, qty: qtyDefault } : product)),
                    }, () => {
                        this.changeQtyProductData({ ...row, qty: qtyDefault }, index)
                    })
                }
            })
        } else {
            this.changeQtyProductData(row, index)
        }
    }

    calculatePrice = (product) => {
        const { SaleOrderInfoFormRedux: { values } } = this.props
        return +values.saleChannelCode === 10 ? product.retailPrice : product.projectPrice
    }

    calculateCost = (product) => {
        const { SaleOrderInfoFormRedux: { values } } = this.props
        //return +values.saleChannelCode === 10 ? product.retailCost : product.retailCost
        if (product.freeGoodsFlag === true)
            return 0;

        return product.costPerUnit || product.retailCost
    }

    onSelectedProduct = async (product) => {
        this.closeModal()
        const {
            SaleOrderInfoFormRedux: { values },
        } = this.props
        const { products } = this.state

        if (product.productType === 'service' && product.flagOpenprice === false /*&& product.flagTransportation === false*/) {
            message.error(`กรุณาทำรายการสินค้า <${product.productId}> ผ่านใบเสนอราคาแบบขอราคาพิเศษ!`)
            return
        }

        if (product.outletRole === 'Franchise' && product.productType === 'goods') {
            const retailCost = product.retailCost || 0;
            const retailPrice = product.retailPrice || 0;
            const projectPrice = product.projectPrice || 0;

            if (retailCost <= 0) {
                message.error('สินค้าไม่ได้ maintain ต้นทุน กรุณาตรวจสอบเพิ่มเติม')
                return
            } 
            /* 23-01-2568 ปลด validate ราคาขาย ต่ำกว่า ทุน
            else if ((retailPrice - retailCost) < 0 && values.saleChannelCode === '10') {
                message.error('สินค้ามี margin ติดลบ กรุณาตรวจสอบเพิ่มเติม')
                return
            } else if ((projectPrice - retailCost) < 0 && values.saleChannelCode === '20') {
                message.error('สินค้ามี margin ติดลบ กรุณาตรวจสอบเพิ่มเติม')
                return
            } */
        }

        if (product.retailPrice <= 0 && product.productType === 'goods') {
            message.error('สินค้าไม่มีราคาขาย กรุณาตรวจสอบเพิ่มเติม')
            return
        }

        const newProducts = [...products]
        newProducts.splice(-1, 1)

        const pricePerUnit = this.calculatePrice(product)
        const qty = 1

        // const discount = 0
        const productMapped = {
            barcode: product.barcode,
            productCode: product.productId,
            productName: product.productNameMarket,
            stockType: product.stockType,
            slocId: product.productType === 'goods' ? product.storageLocationId : null,
            unit: product.unitId,
            outletId: product.outletId,
            stockQty: product.qty,
            // qtyConfirm: product.qtyConfirm,
            qty,
            pricePerUnit,
            retailPrice: product.retailPrice,
            projectPrice: product.projectPrice,
            retailCost: product.retailCost,
            projectCost: product.projectCost,
            // totalAmount: (pricePerUnit * qty) - discount,
            totalAmount: pricePerUnit * qty,
            deliveryDate: moment(),
            // specialOrderFlag: 'normal',
            specialOrderFlag: values.specialOrderFlag !== 'normal',
            productType: product.productType,
            freeGoodsFlag: product.freeGoodsFlag,
            flagTransportation: product.flagTransportation,
            flagOpenprice: product.flagOpenprice,
            outletRole: product.outletRole
        }
        this.setState({
            products: [...newProducts, productMapped, initialProduct],
            isErrorSubmitProduct: false,
        }, async () => {
            await this.calculateTotalSummary()
        })
    }

    searchProduct = async (product) => {
        const { saleOrderInfo } = this.state
        const { auth } = this.props
        const { barcode, productCode } = product
        if (!barcode && !productCode) {
            message.error('กรุณาระบุบาร์โค้ดหรือรหัสสินค้า')
            return
        }
        this.setState({ loading: true })

        const body = {
            barcode,
            productId: productCode,
            outletId: saleOrderInfo.outletId,
            isIncludeStockATP: true,
            storageLocationIds: auth.arrayStorageLocationId,
            limitMaxRange: 100,
            productStatus: true
        }

        await apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCT}?getBy=${auth.userId}`, body).then(async res => {
            const products = get(res, 'data.results')
            if (res.status === 200 && !isEmpty(products)) {
                let productSelected = products.find(product => product.unitId === product.salesUnit)
                if (!productSelected) productSelected = products[0]

                await this.onSelectedProduct(productSelected)
            } else if (res.status === 200 && (res.data.status === "NotAvaliable" || res.data.status === "NotFound")) {
                const mes = get(res, 'data.message', 'ไม่สามารถดึงข้อมูลสินค้าได้ กรุณาลองใหม่อีกครั้ง')
                message.error(mes)
            } else {
                message.error(`ไม่พบข้อมูลสินค้า`)
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลสินค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    getParams = () => {
        const {
            customer,
            customerShipping,
            products,
            saleEmployees,
            totalSummary,
            remark,
            saleOrderDetail,
            soNumber,
            outletId,
        } = this.state
        const isEditMode = !!(soNumber && outletId)
        const isCustomerDefault = customer.customerId === customerDefault.customerId
        //let newProducts = [...products]
        //newProducts.splice(-1, 1)
        let tempProducts = [...products]
        tempProducts.splice(-1, 1)
        let productsGoods = [...tempProducts].filter(p => !p.freeGoodsFlag || p.freeGoodsFlag === false)
        let productsGift = [...tempProducts].filter(p => p.freeGoodsFlag && p.freeGoodsFlag === true)
        let newProducts = [...productsGoods, ...productsGift]

        //newProducts = sortBy(newProducts, ['freeGoodsFlag', 'soItem'], ['asc','asc']).reverse()
        const {
            auth: { userId },
            SaleOrderInfoFormRedux: { values: valuesSaleOrder = {} },
            SaleCustomerInfoFormRedux: { values: valuesCustomer = {} },
        } = this.props

        let partnersModel = null

        if (!isEmpty(customer)) {
            let partners = []
            if (!isEditMode && isCustomerDefault) {
                partners = [{ ...customer, customerRole: 'sold_to' }, { ...customerDefault, customerRole: 'ship_to' }]
            } else {
                partners = [{ ...customer, customerRole: 'sold_to' }, { ...customerShipping, customerRole: 'ship_to' }]
            }

            partnersModel = partners.map(partner => ({
                salesSOPartnerFunctionId: partner.salesSOPartnerFunctionId || 0,
                outletId: partner.customerOutletId || valuesSaleOrder.outletId || '',
                soNumber: saleOrderDetail.soNumber || '',
                partnerType: partner.customerRole || '',
                customerType: partner.customerType || '',
                customerId: partner.customerId || '',
                customerName: partner.customerNameTH || '',
                title: partner.customerTitle || '',
                taxId: partner.customerTaxId || '',
                taxBranch: partner.customerTaxBranch || '',
                scgFamilyId: partner.customerScgFamilyId || '',
                address: partner.customerAddress || '',
                subDistrict: partner.customerSubDistrict || '',
                district: partner.customerDistrict || '',
                province: partner.customerProvince || '',
                country: partner.customerCountry || '',
                postalCode: partner.customerPostalCode || '',
                phone: partner.customerPhone || '',
                fax: partner.customerFax || '',
                email: partner.customerEmail || '',
            }))
        }

        const foundServiceNotOpenPrice = newProducts.filter(pro => pro.productType === 'service' && pro.flagOpenprice === false)

        let maxrow = Math.max(...newProducts.map(item => item.soItem || 0))

        const productsModel = newProducts.map((product, index) => ({
            ...product,
            saleSOItemId: product.saleSOItemId || 0,
            // outletId: valuesSaleOrder.outletId,
            outletId: product.outletId || valuesSaleOrder.outletId,
            soNumber: saleOrderDetail.soNumber || '',
            soItem: product.soItem || (!isEmpty(isEditMode) && isEditMode === true ? ++maxrow : index + 1),
            barcode: product.barcode,
            productCode: product.productCode,
            productName: product.productName,
            stockType: product.stockType,
            slocId: product.slocId,
            shippingPoint: valuesSaleOrder.shippingPoint,
            deliveryDate: product.deliveryDate && product.deliveryDate.startOf('day').format(),
            qty: product.qty,
            confirmQty: product.confirmQty || 0,
            unit: product.unit,
            freeGoodsFlag: product.freeGoodsFlag || false,
            // freeGoodsFlag: false,
            specialOrderFlag: !isEmpty(foundServiceNotOpenPrice) ? true : valuesSaleOrder.specialOrderFlag !== 'normal',
            pricePerUnit: product.pricePerUnit,
            costPerUnit: this.calculateCost(product),
            discAmountPerUnit: product.discAmountPerUnit,
            discAmountPerUnitAmount: product.discAmountPerUnitAmount,
            discPercent: product.discPercent,
            discPercentAmount: product.discPercentAmount,
            discAmount: product.discAmount,
            discPromotionInternal: product.discPromotionInternal,
            discPromotionExternal: product.discPromotionExternal,
            // discPromotion: product.discPromotion,
            totalDiscAmount: product.totalDiscAmount,
            totalAmount: product.totalAmount, //product.pricePerUnit * product.qty,
            vatAmount: product.vatAmount,
            netAmount: product.netAmount,
            quotationNumber: product.quotationNumber || '',
            quotationItem: product.quotationItem || 0,
            rejectId: product.rejectId || 0,
            itemText: product.itemText || '',
            promotionCode: product.promotionCode,
            receivedQty: product.receivedQty,
            refDocNumber: product.refDocNumber || '',
            refDocItem: product.refDocItem || '',
            remainQty: product.remainQty,
        }))

        const saleEmployee = saleEmployees.find(saleEmployee => saleEmployee.saleEmployeeId === valuesSaleOrder.saleEmployeeCode)
        const now = moment().format()
        // console.log(saleOrderDetail);
        // confirmSpecialorder
        return {
            confirmSpecialorder: saleOrderDetail.confirmSpecialorder || false,
            salesSOHeadId: saleOrderDetail.salesSOHeadId || 0,
            outletId: saleOrderDetail.outletId || valuesSaleOrder.outletId || outletId,
            soNumber: isEditMode ? saleOrderDetail.soNumber : '',
            documentTypeCode: valuesSaleOrder.documentTypeCode,
            documentStatus: isEditMode ? saleOrderDetail.documentStatus : 'OPEN',
            documentDate: isEditMode ? saleOrderDetail.documentDate : valuesSaleOrder.documentDate,
            saleChannelCode: valuesSaleOrder.saleChannelCode,
            createdDate: isEditMode ? saleOrderDetail.createdDate : now,
            createdBy: isEditMode ? saleOrderDetail.createdBy : userId,
            changedDate: now,
            changedBy: userId,
            jobId: valuesCustomer.jobId,
            taxType: valuesSaleOrder.taxType,
            scgid: valuesCustomer.scgId,
            paymentTermCode: valuesSaleOrder.paymentTermCode,
            customerId: customer.customerId,
            customerName: customer.customerNameTH,
            shipToId: isCustomerDefault ? customerDefault.customerId : customerShipping.customerId,
            shipToName: isCustomerDefault ? customerDefault.customerNameTH : customerShipping.customerNameTH,
            saleEmpCode: valuesSaleOrder.saleEmployeeCode,
            saleEmpName: saleEmployee ? saleEmployee.saleEmployeeName : '',
            remark,
            returnTypeId: 0,
            returnReasonId: 0,
            discPercent: totalSummary.discPercent,
            discPercentAmount: totalSummary.discPercentAmount,
            discAmount: totalSummary.discAmount,
            //discPromotion: totalSummary.discPromotion,
            discPromotionInternal: totalSummary.discPromotionInternal,
            discPromotionExternal: totalSummary.discPromotionExternal,
            discCouponPercent: totalSummary.discCouponPercent,
            discCouponPercentAmount: totalSummary.discCouponPercentAmount,
            discSCGIDCode: totalSummary.discSCGIDCode,
            discSCGIDAmount: totalSummary.discSCGIDAmount,
            discCouponAmount: totalSummary.discCouponAmount,
            totalDiscAmount: totalSummary.totalDiscAmount,
            totalAmount: totalSummary.totalAmount,
            vatAmount: totalSummary.vatAmount,
            netAmount: totalSummary.netAmount,
            promotionCode: saleOrderDetail.promotionCode,
            approvedDate: saleOrderDetail.approvedDate || '',
            approvedBy: saleOrderDetail.approvedBy || '',
            refDocNumber: saleOrderDetail.refDocNumber || '',
            refDocSystem: saleOrderDetail.refDocSystem || '',
            salesSOItemModelList: productsModel,
            salesSOPartnerFunctionModelList: partnersModel,
        }
    }

    validateData = (val) => {
        const {
            products,
            customer,
            customerShipping,
        } = this.state

        let newProducts = products.map((product, index) => ({
            row: index,
            ...product,
        }))
        newProducts.splice(-1, 1)

        const {
            SaleOrderInfoFormRedux: { values, syncErrors: syncErrorsSaleOrder },
        } = this.props

        let result = { isError: false, message: '' }
        const isErrorCustomer = isEmpty(customer)
        const isErrorCustomerShipping = customer.customerId !== customerDefault.customerId && isEmpty(customerShipping)
        const isErrorProduct = isEmpty(newProducts)
        if (syncErrorsSaleOrder || isErrorCustomer || isErrorCustomerShipping || isErrorProduct) {
            this.setState({
                isErrorSubmitProduct: isErrorProduct,
                isErrorSubmitCustomer: isErrorCustomer,
                isErrorSubmitCustomerShipping: isErrorCustomerShipping,
                isErrorSubmitSaleOrder: !!syncErrorsSaleOrder,
            })
            return { isError: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }
        }

        for (const product of newProducts) {
            if ((!product.qty || product.qty <= 0) && product.freeGoodsFlag === false) {
                result = { isError: true, message: 'กรุณาระบุปริมาณ' }
                break
            }
            if ((!product.rejectId && (!product.pricePerUnit || product.pricePerUnit <= 0)) && (!product.freeGoodsFlag || product.freeGoodsFlag === false)) {
                result = { isError: true, message: 'กรุณาระบุราคา/หน่วย' }
                break
            }
            if ((!product.rejectId && product.outletRole === 'Franchise' && product.productType === 'goods' && ((!product.retailCost || product.retailCost <= 0))) && product.freeGoodsFlag === false && val !== 'reprocess') {
                result = { isError: true, message: 'สินค้าไม่ได้ maintain ต้นทุน กรุณาตรวจสอบเพิ่มเติม' }
                break
            }
            if (!(product.rejectId || 0) && (product.discPromotionInternal < 0 || product.discPromotionExternal < 0) && (!product.freeGoodsFlag || product.freeGoodsFlag === false)) {
                result = { isError: true, message: 'ราคาโปรโมชั่นสูงกว่าราคาขายปกติ, กรุณาแจ้งทีมที่ดูแลเพื่อตรวจสอบราคา' }
                break
            }
            // if ((!product.rejectId && product.productType === 'goods' && ((!product.retailPrice || product.retailPrice <= 0))) && (!product.freeGoodsFlag || product.freeGoodsFlag === false) && values.saleChannelCode === '10') {
            //     result = { isError: true, message: 'สินค้าไม่มีราคาขาย กรุณาตรวจสอบเพิ่มเติม' }
            //     break
            // }
            // if ((!product.rejectId && product.productType === 'goods' && ((!product.projectPrice || product.projectPrice <= 0))) && (!product.freeGoodsFlag || product.freeGoodsFlag === false) && values.saleChannelCode === '20') {
            //     result = { isError: true, message: 'สินค้าไม่มีราคาขาย กรุณาตรวจสอบเพิ่มเติม' }
            //     break
            // }
        }

        //const newServiceProducts = newProducts.filter(p => p.productType === 'service' && p.flagOpenprice === false);
        const newServiceNotTransportations = newProducts.filter(p => p.productType === 'service' && p.flagTransportation === false);
        const newGoodsProducts = newProducts.filter(p => p.productType === 'goods' || (p.productType === 'service' && p.flagTransportation === true));

        if (!isEmpty(newServiceNotTransportations) && !isEmpty(newGoodsProducts)) {
            const newServiceProduct = first(newServiceNotTransportations);
            result = { isError: true, message: `รายการลำดับที่ ${(newServiceProduct.row + 1)} เป็นประเภทค่าบริการ กรุณาแยกเอกสารจากสินค้า` }
        }

        return result
    }

    validateShippingPoint = () => {
        const { SaleOrderInfoFormRedux: { values } } = this.props
        let result = { isError: false }

        if (values.shippingPoint === 'SHPD') {
            const slocIdSHPC = 'DS01'
            const newProducts = [...this.state.products]
            newProducts.splice(-1, 1)

            const invalidSloc = newProducts.find(product => product.productType === 'goods' && product.slocId !== slocIdSHPC)
            if (invalidSloc) {
                swal('Warning', `กรณีระบุเงื่อนไข SHPD ส่งตรง กรุณาระบุคลังสินค้า ${slocIdSHPC}`, 'warning').then(() => {
                    // change sloc of all item to ${slocIdSHPC}
                    const products = newProducts.map(product => ({
                        ...product,
                        slocId: product.productType === 'goods' ? slocIdSHPC : product.slocId,
                    }))
                    this.setState({
                        products: [...products, initialProduct]
                    })
                })
                return { isError: true }
            }
        }
        return result
    }

    confirmCreateSaleOrder = (createType = '') => {
        const valid = this.validateData()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        const validShipping = this.validateShippingPoint()
        if (validShipping.isError) {
            return
        }

        swal({
            icon: 'warning',
            text: 'คุณต้องการสร้างใบสั่งขายหรือไม่ ?',
            buttons: {
                create: { text: 'สร้างใบสั่งขายสินค้า', value: true },
                cancel: 'ปิด',
            },
        }).then((value) => {
            if (value) {
                this.createSaleOrder(createType)
            }
        })
    }

    createSaleOrder = async (createType) => {
        await this.calculateTotalSummary()

        this.setState({ loading: true })
        const body = this.getParams()
        // return

        const url = API_PATH.POST_SALE_ORDER
        //console.log("createSaleOrder==>", JSON.stringify(body))
        if (!isEmpty(body.discSCGIDCode)) {
            try {
                const resCouponseStatus = await this.updateStatusCoupon(body.discSCGIDCode, body.scgid, 'Used')
            } catch (error) {
                const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
                swal('Error', message, 'error')
                return
            }
        }
        await apiServices.callApi('post', url, body).then(res => {
            const soNumber = get(res, 'data.message')
            if (res.status === 200) {
                swal('Success', `เลขที่ใบสั่งขายสินค้า : ${soNumber}`, 'success')
                    .then(() => {
                        if (createType === 'saveAndPayment') {
                            this.props.history.push(`${ROUTH_PATH.PAYMENT}/${soNumber}/${body.outletId}`)
                        } else {
                            this.props.history.push(`${ROUTH_PATH.SALE_ORDER_EDIT_LINK}/${soNumber}/${body.outletId}`)
                        }
                    })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    updateSaleOrder = async (createType) => {
        await this.calculateTotalSummary()

        const valid = this.validateData()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        const validShipping = this.validateShippingPoint()
        if (validShipping.isError) {
            return
        }

        this.setState({ loading: true })
        const body = this.getParams()
        // console.log('body update SO==>',JSON.stringify(body))
        //return
        const { soNumber } = this.state
        const url = `${API_PATH.PUT_SALE_ORDER}?soNumber=${soNumber}`
        if (!isEmpty(body.discSCGIDCode)) {
            try {
                const resCouponseStatus = await this.updateStatusCoupon(body.discSCGIDCode, body.scgid, 'Used')
            } catch (error) {
                const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
                swal('Error', message, 'error')
                return
            }
        }
        await apiServices.callApi('put', url, body).then(res => {
            if (res.status === 200) {
                swal('Success', `หมายเลขใบสั่งขายสินค้า ${soNumber} ถูกบันทึกสำเร็จ`, 'success').then(() => {
                    if (createType === 'saveAndPayment') {
                        this.props.history.push(`${ROUTH_PATH.PAYMENT}/${soNumber}/${body.outletId}`)
                    } else {
                        this.initiatData()
                    }
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการบันทึกข้อมูลใบสั่งขายสินค้า กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    reprocessSaleOrder = async () => {
        //let valid = {}
        //this.setState({ disabledReprocessButton: true }, () => valid = this.validateData('reprocess'))
        await this.setState({ disabledReprocessButton: true })
        const valid = this.validateData('reprocess')

        if (valid.isError) {
            await this.setState({ disabledReprocessButton: false })
            swal('Warning', valid.message, 'warning')
            return
        }
        
        this.setState({ loading: true })
        const body = this.getParams()
        // console.log('body', body)
        // return

        const { soNumber } = this.state
        const url = `${API_PATH.POST_SALE_ORDER_REPROCESS}?soNumber=${soNumber}`
        await apiServices.callApi('post', url, body).then(res => {
            if (res.status === 200) {
                swal('Success', `หมายเลขใบสั่งขายสินค้า ${soNumber} Reprocess สำเร็จ`, 'success').then(() => {
                    this.initiatData()
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการ Reprocess กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false, disabledReprocessButton: false })
        })
    }

    confirmSpecialOrder = async () => {
        this.setState({ loading: true })

        const valid = this.validateData()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            this.setState({ loading: false })
            return
        }
        await this.calculateTotalSummary()
        const body = this.getParams()
        //console.log(`ConfirmSpecialOrder param ==> ${JSON.stringify(body)}`)
        //return

        //#region issue #1531 พบค่าคลังที่ยืนยันสั่งพิเศษไม่ตรงกับค่าคลังที่ SO บันทึก เพราะมีการเปลี่ยนค่าใน SO แล้วกดยืนยันสั่งพิเศษเลย แล้วมากดบันทึก SO ทีหลัง
        const validShipping = this.validateShippingPoint()
        if (validShipping.isError) {
            return
        }

        this.setState({ loading: true })
        const { soNumber } = this.state
        if (!isEmpty(body.discSCGIDCode)) {
            try {
                const resCouponseStatus = await this.updateStatusCoupon(body.discSCGIDCode, body.scgid, 'Used')
            } catch (error) {
                const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
                swal('Error', message, 'error')
                return
            }
        }
        await apiServices.callApi('put', `${API_PATH.PUT_SALE_ORDER}?soNumber=${soNumber}`, body).then(res => {
            swal('Success', `หมายเลขใบสั่งขายสินค้า ${soNumber} ถูกบันทึกสำเร็จ`, 'success')
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการบันทึกข้อมูลใบสั่งขายสินค้า กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })
        //#endregion issue #1531

        const url = `${API_PATH.POST_SALE_ORDER_CONFIRM_SPECIAL_ORDER}`
        await apiServices.callApi('post', url, body).then(res => {
            if (res.status === 200) {
                swal('Success', get(res, 'data.message', 'บันทึกข้อมูลสำเร็จ'), 'success').then(() => {
                    this.initiatData()
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการยืนยันสินค้าสั่งพิเศษ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    changeSaleOrderSearch = (key, value) => {
        const { saleOrderSearch } = this.state
        this.setState({ saleOrderSearch: { ...saleOrderSearch, [key]: value } })
    }

    searchSaleOrder = async () => {
        const { saleOrderSearch: { soNumber, outletId } } = this.state
        if (!soNumber || !outletId) {
            message.error('กรุณาระบุเลขที่ขอใบเอกสารและร้านค้า')
            return
        }
        this.props.history.push(`${ROUTH_PATH.SALE_ORDER_EDIT_LINK}/${soNumber}/${outletId}`)
        // Focus refrech
        window.location.reload();
    }

    calculateTotalSummary = async () => {
        let { saleOrderDetail, soNumber, outletId, disabledEditData } = this.state
        const isEditMode = !!(soNumber && outletId)
        const body = this.getParams()
        if (isEmpty(body) || isEmpty(body.salesSOItemModelList))
            return

        this.setState({ loading: true })
        const url = (isEditMode === true && (disabledEditData === true || saleOrderDetail.requestSpecialPrice === true)) ? API_PATH.POST_SALE_ORDER_AMOUNT : API_PATH.POST_PROMOTION_TOTAL_AMOUNT
        await apiServices.callApi('post', url, body).then(res => {
            const saleSummary = get(res, 'data.results.0')
            if (res.status === 200 && saleSummary) {
                let tempProducts = [...this.state.products]
                tempProducts.splice(-1, 1)
                let productsGoods = [...tempProducts].filter(p => !p.freeGoodsFlag || p.freeGoodsFlag === false)
                let productsGift = [...tempProducts].filter(p => p.freeGoodsFlag && p.freeGoodsFlag === true)
                let sortProducts = [...productsGoods, ...productsGift]

                //let sortProducts = [...this.state.products].filter(p => !p.freeGoodsFlag || p.freeGoodsFlag === false)
                //sortProducts = [...this.state.products].filter(p => p.freeGoodsFlag && p.freeGoodsFlag === true)
                //sortProducts.splice(-1, 1)
                //sortProducts = sortBy(sortProducts, ['freeGoodsFlag', 'soItem'], ['asc','asc']).reverse()
                let products = sortProducts.map((product, index) => {
                    const productSummary = saleSummary.salesSOItemModelList.find((item, indexItem) => index === indexItem)
                    if (productSummary) {
                        return {
                            ...product,
                            promotionCode: productSummary.promotionCode,
                            discAmount: productSummary.discAmount,
                            discAmountPerUnit: productSummary.discAmountPerUnit,
                            discAmountPerUnitAmount: productSummary.discAmountPerUnitAmount,
                            discPercent: productSummary.discPercent,
                            discPercentAmount: productSummary.discPercentAmount,
                            // TODO: remove discPromotion when edited api
                            //discPromotion: productSummary.discPromotion,
                            discPromotionInternal: productSummary.discPromotionInternal,
                            discPromotionExternal: productSummary.discPromotionExternal,
                            totalAmount: productSummary.totalAmount,
                            totalDiscAmount: productSummary.totalDiscAmount,
                            vatAmount: productSummary.vatAmount,
                            netAmount: productSummary.netAmount,
                        }
                    } else {
                        return product
                    }
                })

                //Fixed issue #1121 Quantity Promotion ไม่แสดงราคาโปรโมชั่น/ของแถมในหน้า Quatation และ SO Promotion code : PR20200723000288 ร้าน A022 QAS
                //จัดการสินค้าของแถม
                let oldProducts = products.filter(p => !p.freeGoodsFlag || p.freeGoodsFlag === false);
                //oldProducts.splice(-1, 1)
                let newProductsFreeGoods = saleSummary.salesSOItemModelList.filter(p => p.freeGoodsFlag && p.freeGoodsFlag === true);
                if (!isEmpty(newProductsFreeGoods)) {
                    newProductsFreeGoods = newProductsFreeGoods.map(product => {
                        const oldFreeProduct = products.find((item, index) => item.productCode === product.productCode && item.freeGoodsFlag === product.freeGoodsFlag && item.promotionCode === product.promotionCode)
                        return {
                            ...product,
                            rejectIdInitial: product.rejectId,
                            deliveryDate: product.deliveryDate && moment(new Date(product.deliveryDate)),
                            slocId: oldFreeProduct && oldFreeProduct.slocId ? oldFreeProduct.slocId : product.slocId,
                        }
                    })
                }

                products = [...oldProducts, ...newProductsFreeGoods, initialProduct]

                saleOrderDetail = {
                    ...saleOrderDetail,
                    promotionCode: saleSummary.promotionCode
                }

                this.setState({
                    products,
                    saleOrderDetail,
                    totalSummary: {
                        totalAmount: saleSummary.totalAmount,
                        totalExcVatAmount: saleSummary.totalExcVatAmount,
                        totalDiscAmount: saleSummary.totalDiscAmount,
                        netAmount: saleSummary.netAmount,
                        vatAmount: saleSummary.vatAmount,
                        discPercent: saleSummary.discPercent,
                        discPercentAmount: saleSummary.discPercentAmount,
                        discAmount: saleSummary.discAmount,
                        //discPromotion: saleSummary.discPromotion,
                        discPromotionInternal: saleSummary.discPromotionInternal,
                        discPromotionExternal: saleSummary.discPromotionExternal,
                        discCouponPercent: saleSummary.discCouponPercent,
                        discCouponPercentAmount: saleSummary.discCouponPercentAmount,
                        discSCGIDCode: saleSummary.discSCGIDCode,
                        discSCGIDAmount: saleSummary.discSCGIDAmount,
                        discCouponAmount: saleSummary.discCouponAmount,
                    }
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการคำนวณราคา กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    clearAllData = async () => {
        this.props.resetSaleCustomerInfoForm()
        this.props.resetCustomerDetailForm()
        this.props.resetSaleOrderInfoForm()
        this.resetState()
        this.initiatData()
    }

    disabledEditData = () => {
        const { saleOrderDetail } = this.state
        if (saleOrderDetail.confirmSpecialorder) {
            this.setState({ disabledEditData: true })
            return
        }
        const documentStatus = (saleOrderDetail.documentStatus || '').toUpperCase()
        if ((documentStatus !== 'OPEN' && documentStatus !== 'APPROVE' && documentStatus !== 'BLOCK')) {
            this.setState({ disabledEditData: true })
            return
        }
        // if (!isEmpty(documentFlows)) {
        //     const disabled = documentFlows.filter(documentFlow => documentFlow.refDocumentNo)
        //     this.setState({ disabledEditData: !isEmpty(disabled) })
        // }
    }

    disabledConfirmSpecialOrder = () => {
        const { SaleOrderInfoFormRedux: { values } } = this.props
        const { products, saleOrderDetail } = this.state
        const newProducts = [...products]
        newProducts.splice(-1, 1)
        if (!isEmpty(newProducts)) {
            if (!isEmpty(values) && values.specialOrderFlag === 'normal') {
                return true
            }

            if (!saleOrderDetail.confirmSpecialorder) {
                const documentTypeCode = saleOrderDetail.documentTypeCode.toUpperCase()
                const documentStatus = saleOrderDetail.documentStatus.toUpperCase()
                if (documentTypeCode === 'ZOCS' && (documentStatus === 'DEPOSIT' || documentStatus === 'PAYMENT' || documentStatus === 'DELIVERY')) {
                    return false
                } else if (documentTypeCode === 'ZOCR' && (documentStatus === 'APPROVE' || documentStatus === 'DELIVERY')) {
                    return false
                }
            }
        }

        return true
    }

    approveCreditNote = async () => {
        this.setState({ loading: true })
        const { saleOrderDetail } = this.state
        const { auth } = this.props

        const body = [{
            soNumber: saleOrderDetail.soNumber,
            outletId: saleOrderDetail.outletId,
            approvedBy: auth.userId,
        }]

        const url = `${API_PATH.PUT_APPROVE_SALES_ORDERS}`
        await apiServices.callApi('put', url, body).then(res => {
            if (res.status === 200) {
                swal('Success', `เลขที่ใบสั่งขาย ${saleOrderDetail.soNumber} อนุมัติสำเร็จ`, 'success').then(async () => {
                    await this.getSaleOrderDetail(saleOrderDetail.soNumber, saleOrderDetail.outletId)
                    this.getDocFlow()
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการอนุมัติใบสั่งขาย กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }
    onSubmitDiscount = (totalSummary) => {
        this.setState({ totalSummary: totalSummary }, () => {
            this.calculateTotalSummary()
        })
    }
    async updateStatusCoupon(couponCode, scgid, status) {
        let body = {
            "scgid": scgid,
            "coupon_code": couponCode,
            "status": status
        }
        const resCouponsStatus = await apiServices.callApi('post', API_PATH.POST_SALE_ORDER_UPDATE_COUPON_STATUS, body)
        return resCouponsStatus
    }

    onSubmitDiscountItem = async (productDisc) => {
        const { auth } = this.props
        const { indexRowAction, saleOrderInfo } = this.state

        this.setState({ loading: true })
        const body = {
            usetRoles: auth.arrayRole,
            salesSOItem: productDisc,
            documentTypeCode: saleOrderInfo.documentTypeCode,
        }

        await apiServices.callApi('post', API_PATH.POST_MANUAL_PROMOTION_DISCOUNT_ITEM, body).then(res => {
            if (res.status === 200 && get(res, 'data.status') === 'Success') {
                const products = this.state.products.map((product, i) => (
                    i === indexRowAction ? {
                        ...product,
                        ...productDisc,
                    } : product
                ));
                this.setState({ products }, () => {
                    this.calculateTotalSummary()
                })
                this.closeModal()
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการคำนวณราคา กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }
    async checkPromotion() {
        let body = this.getParams()
        try {
            const result = await apiServices.callApi('post', `${API_PATH.POST_CHECK_PROMOTION_AVAILABLE}`, body)
            const promotions = get(result, 'data.results', [])
            this.setState({ promotions }, this.openModal('promotion'))
        } catch (error) {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }
    }
    checkPromotionDisable(data, products) {
        const { values } = data
        if (!isEmpty(values)) {
            if (!isEmpty(values.documentTypeCode) && !isEmpty(values.saleChannelCode) && !isEmpty(values.taxType) && !isEmpty(values.shippingPoint) && !isEmpty(values.outletId)) {
                if (products.length > 1) {
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        } else {
            return true
        }

    }

    render() {
        const {
            columns,
            columnsDiscount,
            products,
            loading,
            soNumber,
            outletId,
            outlets,
            documentTypes,
            taxTypes,
            saleChannels,
            shippingPoints,
            saleEmployees,
            storeLocations,
            units,
            saleOrderSearch,
            saleOrderInfo,
            saleOrderDetail,
            saleOrderInfo: { outletRold, documentTypeCode },
            customerIdSearch,
            customer,
            customerShipping,
            customerShippings,
            customerInfo,
            totalSummary,
            remark,
            isErrorSubmitProduct,
            isErrorSubmitCustomer,
            isErrorSubmitCustomerShipping,
            isErrorSubmitSaleOrder,
            isShowDiscountTable,
            isShowModal,
            modalType,
            disabledEditData,
            disabledAllButton,
            disabledReprocessButton,
            documentFlows,
            queryStringValue,
            indexRowAction,
            permissionPage,
            promotions
        } = this.state

        const isEditMode = soNumber && outletId
        const disableSCGID = disabledEditData ? true : !isEmpty(customerInfo.scgId) && !isEmpty(saleOrderDetail.discSCGIDCode)
        const disablePromotion = disabledEditData ? true : !isEmpty(this.props.SaleOrderInfoFormRedux) && this.checkPromotionDisable(this.props.SaleOrderInfoFormRedux, products)
        const newProduct = JSON.parse(JSON.stringify(products))
        const isRehectAllInProducts = newProduct.slice(0, newProduct.length - 1).every(product => product.rejectId === 4)

        return (
            <div className="sale-order-create-container">
                <Spin spinning={loading}>
                    <TitleHead
                        icon="file-text"
                        text={
                            <span>ใบสั่งขายสินค้า : เลขที่ {soNumber ? soNumber : '(Auto)'}
                                <Badge
                                    showZero
                                    style={{ background: '#777', marginLeft: '7px' }}
                                    count={products.length - 1}
                                />
                            </span>
                        }
                        nodeRight={
                            <div className="search-sale-order-box">
                                <div className="text-search">เลขที่ขอใบเอกสาร</div>
                                <div className="text-search" style={{ width: '180px' }}>
                                    <SelectCustom
                                        options={outlets}
                                        value={saleOrderSearch.outletId}
                                        small
                                        onChange={(val) => this.changeSaleOrderSearch('outletId', val)}
                                        disabled={!permissionPage.authDisplay}
                                    />
                                </div>
                                <InputSearchCustom
                                    small
                                    value={saleOrderSearch.soNumber}
                                    onChange={(e) => this.changeSaleOrderSearch('soNumber', e.target.value)}
                                    onClickSearch={this.searchSaleOrder}
                                    onSubmit={this.searchSaleOrder}
                                    disabled={!permissionPage.authDisplay}
                                    disabledButton={!permissionPage.authDisplay}
                                />
                            </div>
                        }
                    />
                    <div className="middle-content">
                        <Row gutter={8}>
                            <Col sm={24} md={12}>
                                <SaleCustomerInfoFormRedux
                                    initialValues={customerInfo}
                                    isErrorSubmitCustomer={isErrorSubmitCustomer}
                                    isErrorSubmitCustomerShipping={isErrorSubmitCustomerShipping}
                                    customerIdSearch={customerIdSearch}
                                    customer={customer}
                                    customerInfo={customerInfo}
                                    customerShipping={customerShipping}
                                    customerShippings={customerShippings}
                                    outletId={saleOrderInfo.outletId}
                                    changeCustomerIdSearch={this.changeCustomerIdSearch}
                                    changeCustomerInfo={this.changeCustomerInfo}
                                    searchCustomer={this.searchCustomer}
                                    onSelectedCustomer={this.onSelectedCustomer}
                                    onSelectedShipping={this.onSelectedShipping}
                                    scgFlag={outletRold && outletRold.toUpperCase() === "BOUTIQUE" && documentTypeCode === "ZOCR" ? true : ''}
                                    disabledAction={disabledEditData}
                                    disableSCGID={disableSCGID}
                                    approveCreditNote={this.approveCreditNote}
                                    showApproveBtn={saleOrderDetail.documentStatus === 'BLOCK' && !isRehectAllInProducts}
                                    disabledApproveBtn={!permissionPage.authApprove}
                                    type='salesorder'
                                />
                            </Col>
                            <Col sm={24} md={12}>
                                <SaleOrderInfoFormRedux
                                    initialValues={saleOrderInfo}
                                    isErrorSubmit={isErrorSubmitSaleOrder}
                                    isEditMode={isEditMode}
                                    customer={customer}
                                    outlets={outlets}
                                    documentTypes={documentTypes}
                                    disabledDocumentTypes={outletRold === "COCO"}
                                    saleChannels={saleChannels}
                                    taxTypes={taxTypes}
                                    shippingPoints={shippingPoints}
                                    paymentTerms={this.filterPaymentTerms(customer.customerPaymentTerm)}
                                    saleEmployees={saleEmployees}
                                    changeSaleOrderInfo={this.changeSaleOrderInfo}
                                    disabledAction={disabledEditData}
                                />
                            </Col>
                        </Row>
                        {isErrorSubmitProduct && <span style={{ color: 'red', fontSize: '12px' }}>* กรุณาเลือกสินค้า</span>}
                        <div className="table-layout">
                            <TableCustom
                                columns={columns}
                                data={products}
                                isFullContent
                                rowKey={(row, index) => index}
                                small
                                pagination={false}
                            />
                        </div>
                        <Row style={{ marginTop: '20px' }}>
                            <Col sm={24} md={12}>
                                <TextAreaCustom
                                    small
                                    label="หมายเหตุ"
                                    onChange={this.changeRemark}
                                    value={remark && ReactHtmlParser(remark.replace(/\n|\/n/g, '&#13;').replace(/\t|\/t/g, '&#09;'))}
                                    disabled={disabledEditData}
                                />
                            </Col>
                            <Col sm={24} md={10}>
                                <SaleSummary
                                    type="SaleOrder"
                                    totalSummary={totalSummary}
                                    onSubmitDiscount={this.onSubmitDiscount.bind(this)}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col sm={24} md={24}>
                                <ButtonCustom
                                    style={{ padding: 0 }}
                                    text={<span>โค้ดส่วนลด <Icon type="plus-circle" /></span>}
                                    type="link"
                                    small
                                    onClick={this.toggleIsShowDiscountTable}
                                />
                            </Col>
                            <Col sm={12} md={8}>
                                {isShowDiscountTable && <TableCustom columns={columnsDiscount} small />}
                            </Col>
                        </Row>
                        {isEditMode && !isEmpty(documentFlows) && <Row style={{ marginTop: '20px' }}>
                            <Col sm={24} md={24}>
                                <DocumentFlowTable
                                    documentFlows={documentFlows}
                                    getDocFlow={this.getDocFlow}
                                    openPrint={queryStringValue.print}
                                    permissionPage={permissionPage}
                                    customer={customer}
                                    getSaleOrderDetail={this.getSaleOrderDetail}
                                    soNumber={soNumber}
                                    outletId={outletId}
                                    initiatData={this.initiatData}
                                />
                            </Col>
                        </Row>}
                    </div>
                    <div className="bottom-content">
                        <div className="left-zone">
                            <div className="button">
                                <ButtonCustom
                                    text="ล้างหน้าจอ"
                                    icon="reload"
                                    type="danger"
                                    onClick={this.clearAllData}
                                />
                            </div>
                        </div>
                        <div className="right-zone">
                            {isEditMode && <div className="button">
                                <ButtonCustom
                                    text="ยืนยันสินค้าสั่งพิเศษ"
                                    onClick={this.confirmSpecialOrder}
                                    disabled={this.disabledConfirmSpecialOrder() || disabledAllButton}
                                />
                            </div>}
                            <div className="button">
                                <Link to={ROUTH_PATH.HOME} className="footer-button">
                                    <ButtonCustom
                                        text="ยกเลิก"
                                        icon="close"
                                        type="danger"
                                    />
                                </Link>
                            </div>
                            {isEditMode && <div className="button">
                                <ButtonCustom
                                    text="Reprocess"
                                    icon="redo"
                                    yellow
                                    onClick={this.reprocessSaleOrder}
                                    disabled={!(saleOrderInfo.documentTypeCode === 'ZOCS' || saleOrderInfo.documentTypeCode === 'ZOCR') || disabledAllButton || disabledReprocessButton}
                                />
                            </div>}
                            <div className="button">
                                <ButtonCustom
                                    text="บันทึกและชำระเงิน"
                                    icon="save"
                                    onClick={() => (isEditMode) ? this.updateSaleOrder('saveAndPayment') : this.confirmCreateSaleOrder('saveAndPayment')}
                                    disabled={!(saleOrderInfo.documentTypeCode === 'ZOCS' && (!isEditMode || (isEditMode && saleOrderDetail.documentStatus.toUpperCase() === 'OPEN')))
                                        || (isEditMode ? !permissionPage.authUpdate : !permissionPage.authCreate) || disabledAllButton}
                                />
                            </div>
                            <div className="button">
                                <ButtonCustom
                                    text="บันทึก"
                                    icon="save"
                                    onClick={(isEditMode) ? this.updateSaleOrder : this.confirmCreateSaleOrder}
                                    disabled={isEmpty(products) || (isEditMode ? !permissionPage.authUpdate : !permissionPage.authCreate) || disabledAllButton}
                                />
                            </div>
                            <div className="button">
                                <ButtonCustom
                                    text="เช็คโปรโมชั่น"
                                    icon="check-square"
                                    yellow
                                    onClick={this.checkPromotion.bind(this)}
                                    disabled={disablePromotion}
                                />
                            </div>
                        </div>
                    </div>
                    {isShowModal && modalType === 'discountItem' && <SaleDiscountItemModal
                        isShowModal={isShowModal && modalType === 'discountItem'}
                        onCloseModal={this.closeModal}
                        onSubmitDiscount={this.onSubmitDiscountItem}
                        product={products.find((product, index) => indexRowAction === index) || {}}
                    />}
                    <PromotionModal
                        isShowModal={isShowModal && modalType === 'promotion'}
                        onCloseModal={this.closeModal}
                        promotions={promotions}
                    />
                    <ProductSearchModal
                        isShowModal={isShowModal && modalType === 'product'}
                        onCloseModal={this.closeModal}
                        onSelected={this.onSelectedProduct}
                        outletId={saleOrderInfo.outletId}
                        channelCode={saleOrderInfo.saleChannelCode || '10'}
                        isIncludeStockATP
                    />
                    <StoreLocationModal
                        isShowModal={isShowModal && modalType === 'storeLocation'}
                        handleCloseModal={this.closeModal}
                        dataStorages={storeLocations}
                        onSelected={this.onSelectedStoreLocation}
                    />
                    <SaleUnitModal
                        isShowModal={isShowModal && modalType === 'unit'}
                        onCloseModal={this.closeModal}
                        units={units}
                        onSelected={this.onSelectedUnit}
                    />
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        SaleOrderInfoFormRedux: state.form.SaleOrderInfoFormRedux,
        SaleCustomerInfoFormRedux: state.form.SaleCustomerInfoFormRedux,
    }
}

const mapDispatchToProps = (dispatch) => ({
    resetSaleOrderInfoForm: () => dispatch(reset('SaleOrderInfoFormRedux')),
    resetSaleCustomerInfoForm: () => dispatch(reset('SaleCustomerInfoFormRedux')),
    resetCustomerDetailForm: () => dispatch(reset('CustomerDetailFormRedux')),
})

export default connect(mapStateToProps, mapDispatchToProps)(SaleOrderMaintainContainer)
