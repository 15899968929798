import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty, isBoolean, last, isUndefined } from 'lodash'
import { bindActionCreators } from 'redux'
import {
    authData,
    resetReportProductSharings,
    setReportProductSharings,
} from 'actions/actionsCreator'
import { getPermissionPage } from 'helpers'
import { ROUTH_PATH, functionAlias } from 'routes'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    CheckboxCustom,
    SelectCustom,
    InputSearchCustom,
    NumberFormatCustom,
} from 'components/common'
import {
    ProductSearchModal,
} from 'components/feature'
import {
    ProductHierarchyModal,
} from 'components/form'
import { Row, Col, Spin, message } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import swal from '@sweetalert/with-react'

import ConfirmProductModal from './ConfirmProductModal'
import './style.css'

const initialProduct = {
    outletId: '',
    saleChannelCode: '',
    categoryCode: '',
    subCategoryCode: '',
    classCode: '',
    subClassCode: '',
    productId: '',
    productName: '',
    percentSharing: '',
    deleteFlag: false,
}

class ProductPercentMarginMaintainContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [
                {
                    title: '#',
                    dataIndex: 'delele',
                    key: 'delele',
                    align: 'center',
                    width: 45,
                    render: (props, row, index) => (
                        this.state.isEditMode && this.state.products.length - 1 !== index
                        && !this.state.isEditMode && <ButtonCustom
                            text="ลบ"
                            small
                            type="danger"
                            onClick={() => this.deleteProduct(index)}
                        />
                    ),
                },
                {
                    title: 'ร้าน',
                    dataIndex: 'outletId',
                    key: 'outletId',
                    align: 'center',
                    width: 150,
                    render: (props, row, index) => (
                        <SelectCustom
                            options={this.state.outlets}
                            small
                            onChange={(val) => this.changeProductData('outletId', val, index)}
                            value={props}
                        />
                    ),
                },
                {
                    title: 'สินค้า',
                    dataIndex: 'productId',
                    key: 'productId',
                    align: 'center',
                    width: 150,
                    render: (props, row, index) => (
                        row.outletId && <InputSearchCustom
                            onClickSearch={() => this.openModal('product', index)}
                            onSubmit={() => this.searchProduct(row, index)}
                            onChange={(e) => this.changeProductData('productId', e.target.value, index)}
                            small
                            value={props}
                        />
                    ),
                },
                {
                    title: 'ชื่อสินค้า',
                    dataIndex: 'productName',
                    key: 'productName',
                    align: 'center',
                    width: 200,
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'ช่องทาง',
                    dataIndex: 'saleChannelCode',
                    key: 'saleChannelCode',
                    align: 'center',
                    width: 150,
                    render: (props, row, index) => (
                        row.outletId && <SelectCustom
                            options={this.state.saleChannels}
                            small
                            onChange={(val) => this.changeProductData('saleChannelCode', val, index)}
                            value={props}
                        />
                    ),
                },
                {
                    title: 'Category',
                    dataIndex: 'categoryCode',
                    key: 'categoryCode',
                    align: 'center',
                    width: 170,
                    // render: (props, row, index) => (
                    //     row.outletId && <SelectCustom
                    //         options={this.state.productCategories}
                    //         small
                    //         onChange={(val) => this.changeProductData('categoryCode', val, index)}
                    //         value={props}
                    //         optionFilterProp="children"
                    //         filterOption={(input, option) => get(option, 'props.children.props.children', '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    //         showSearch
                    //     />
                    // ),
                    render: (props, row, index) => {
                        const desc = this.state.productCategories.find(productCategory => productCategory.categoryCode === props)
                        return row.outletId && <InputSearchCustom
                            onClickSearch={() => this.openModalProductHierarchy('categoryCode', row, index)}
                            small
                            disabled
                            value={desc ? `${props} - ${desc.descriptionThai}` : props}
                        />
                    },
                },
                {
                    title: 'Sub Category',
                    dataIndex: 'subCategoryCode',
                    key: 'subCategoryCode',
                    align: 'center',
                    width: 170,
                    // render: (props, row, index) => (
                    //     row.outletId && <SelectCustom
                    //         //  options={row.categoryCode ? this.filterSubCatagories('XA') : this.state.productSubCategories}
                    //         small
                    //         onChange={(val) => this.changeProductData('subCategoryCode', val, index)}
                    //         value={props}
                    //         optionFilterProp="children"
                    //         filterOption={(input, option) => get(option, 'props.children.props.children', '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    //         showSearch
                    //     />
                    // ),
                    render: (props, row, index) => {
                        const desc = this.state.productSubCategories.find(productSubCategory => productSubCategory.subCategoryCode === props)
                        return row.outletId && <InputSearchCustom
                            onClickSearch={() => this.openModalProductHierarchy('subCategoryCode', row, index)}
                            small
                            disabled
                            value={desc ? `${props} - ${desc.descriptionThai}` : props}
                        />
                    },
                },
                {
                    title: 'Class',
                    dataIndex: 'classCode',
                    key: 'classCode',
                    align: 'center',
                    width: 170,
                    // render: (props, row, index) => (
                    //     row.outletId && <SelectCustom
                    //         options={this.state.productClasses}
                    //         small
                    //         onChange={(val) => this.changeProductData('classCode', val, index)}
                    //         value={props}
                    //         optionFilterProp="children"
                    //         filterOption={(input, option) => get(option, 'props.children.props.children', '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    //         showSearch
                    //     />
                    // ),
                    render: (props, row, index) => {
                        const desc = this.state.productClasses.find(productClass => productClass.classCode === props)
                        return row.outletId && <InputSearchCustom
                            onClickSearch={() => this.openModalProductHierarchy('classCode', row, index)}
                            small
                            disabled
                            value={desc ? `${props} - ${desc.descriptionThai}` : props}
                        />
                    },
                },
                {
                    title: 'Sub Class',
                    dataIndex: 'subClassCode',
                    key: 'subClassCode',
                    align: 'center',
                    width: 170,
                    // render: (props, row, index) => (
                    //     row.outletId && <SelectCustom
                    //         options={this.state.productSubClasses}
                    //         small
                    //         onChange={(val) => this.changeProductData('subClassCode', val, index)}
                    //         value={props}
                    //         optionFilterProp="children"
                    //         filterOption={(input, option) => get(option, 'props.children.props.children', '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    //         showSearch
                    //     />
                    // ),
                    render: (props, row, index) => {
                        const desc = this.state.productSubClasses.find(productSubClass => productSubClass.subClassCode === props)
                        return row.outletId && <InputSearchCustom
                            onClickSearch={() => this.openModalProductHierarchy('subClassCode', row, index)}
                            small
                            disabled
                            value={desc ? `${props} - ${desc.descriptionThai}` : props}
                        />
                    },
                },
                {
                    title: 'Percent Sharing',
                    dataIndex: 'percentSharing',
                    key: 'percentSharing',
                    align: 'center',
                    width: 100,
                    render: (props, row, index) => (
                        row.outletId && <NumberFormatCustom
                            className="text-right"
                            small
                            onChange={(e) => this.changeProductData('percentSharing', e.target.value, index)}
                            value={props}
                            decimalScale={0}
                            isAllowed={(values) => {
                                const { formattedValue, floatValue } = values;
                                return formattedValue === '' || floatValue <= 100
                            }}
                        />
                    ),
                },
                {
                    title: 'ยกเลิก',
                    dataIndex: 'deleteFlag',
                    key: 'deleteFlag',
                    align: 'center',
                    width: 50,
                    render: (props, row, index) => (
                        row.outletId && <CheckboxCustom
                            style={{ paddingLeft: '10px' }}
                            onChange={(e) => this.changeProductData('deleteFlag', e.target.checked, index)}
                            checked={props}
                        />
                    ),
                },
                {
                    title: 'ข้อความระบบ',
                    dataIndex: 'remark',
                    key: 'remark',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
            ],
            loading: false,
            isEditMode: false,
            outlets: [],
            saleChannels: [],
            productCategories: [],
            productSubCategories: [],
            productClasses: [],
            productSubClasses: [],
            products: [initialProduct],
            productsDuplicate: [],
            indexRowAction: null,
            productHierarchies: [],
            productHierarchyKey: '',
            permissionPage: getPermissionPage(functionAlias.reportPercentMargin),
        }
    }

    componentDidMount() {
        const mode = last(get(this.props, 'match.path', '').split('/'))
        this.fetchDataDefault()
        if (!isEmpty(this.props.reportProductSharings) && mode === 'edit') {
            this.setState({
                products: this.props.reportProductSharings,
                isEditMode: true,
            })
        }
    }

    componentWillUnmount() {
        this.props.resetReportProductSharings()
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })

        const { auth } = this.props
        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, {
            outletIds: auth.arrayOutlet,
        }).then(res => {
            let outlets = get(res, 'data.results')
            if (res.status === 200 && !isEmpty(outlets)) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                this.setState({ outlets })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // sale channel
        await apiServices.callApi('get', API_PATH.GET_MASTER_SALE_CHANNEL).then(res => {
            let saleChannels = get(res, 'data.results', [])
            if (res.status === 200) {
                saleChannels = saleChannels.map(data => (
                    { ...data, label: `${data.saleChannelCode} - ${data.saleChannelDescription}`, value: data.saleChannelCode }
                ))
                this.setState({ saleChannels })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลประเภทการขายได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // product category
        await apiServices.callApi('get', API_PATH.GET_MASTER_PRODUCT_CATEGORY).then(res => {
            let productCategories = get(res, 'data.results', [])
            if (res.status === 200) {
                productCategories = productCategories.map(data => (
                    { ...data, label: `${data.categoryCode} - ${data.descriptionThai}`, value: data.categoryCode }
                ))
                this.setState({ productCategories })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Category ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // product sub category
        await apiServices.callApi('get', API_PATH.GET_MASTER_PRODUCT_SUB_CATEGORY).then(res => {
            let productSubCategories = get(res, 'data.results', [])
            if (res.status === 200) {
                productSubCategories = productSubCategories.map(data => (
                    { ...data, label: `${data.subCategoryCode} - ${data.descriptionThai}`, value: data.subCategoryCode }
                ))
                this.setState({ productSubCategories })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Sub Category ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // product class
        await apiServices.callApi('get', API_PATH.GET_MASTER_PRODUCT_CLASS).then(res => {
            let productClasses = get(res, 'data.results', [])
            if (res.status === 200) {
                productClasses = productClasses.map(data => (
                    { ...data, label: `${data.classCode} - ${data.descriptionThai}`, value: data.classCode }
                ))
                this.setState({ productClasses })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Class ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // product sub class
        await apiServices.callApi('get', API_PATH.GET_MASTER_PRODUCT_SUB_CLASS).then(res => {
            let productSubClasses = get(res, 'data.results', [])
            if (res.status === 200) {
                productSubClasses = productSubClasses.map(data => (
                    { ...data, label: `${data.subClassCode} - ${data.descriptionThai}`, value: data.subClassCode }
                ))
                this.setState({ productSubClasses })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Sub Class ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        this.setState({ loading: false })
    }

    filterSubCatagories = (text) => {
        return this.state.productSubCategories.filter(subCategory =>
            new RegExp('^' + text, 'gi').test(subCategory.subCategoryCode)
        )
    }

    filterClasses = (text) => {
        return this.state.productClasses.filter(classData =>
            new RegExp('^' + text, 'gi').test(classData.classCode)
        )
    }

    filterSubClasses = (text) => {
        return this.state.productSubClasses.filter(subClassData =>
            new RegExp('^' + text, 'gi').test(subClassData.subClassCode)
        )
    }

    deleteProduct = (index) => {
        const products = [...this.state.products]
        products.splice(index, 1)
        this.setState({ products })
    }

    getProductDataInitial = () => {
        return {
            productId: '',
            productName: '',
            categoryCode: '',
            subCategoryCode: '',
            classCode: '',
            subClassCode: '',
        }
    }

    changeProductData = (key, value, index) => {
        const { products } = this.state
        const productDataInitial = this.getProductDataInitial()
        switch (key) {
            case 'outletId':
                // clear product
                this.setState({
                    products: products.map((product, i) =>
                        (i === index ? {
                            ...product,
                            ...productDataInitial,
                            outletId: value,
                        } : product)
                    ),
                }, () => {
                    // add initial product
                    if (products.length === (index + 1)) {
                        this.setState({
                            products: [...this.state.products, initialProduct]
                        })
                    }
                })
                break
            case 'productId':
                this.setState({
                    products: products.map((product, i) =>
                        (i === index ? {
                            ...product,
                            ...productDataInitial,
                            productId: value,
                        } : product)
                    ),
                })
                break
            case 'categoryCode':
                this.setState({
                    products: products.map((product, i) =>
                        (i === index ? {
                            ...product,
                            categoryCode: value,
                            subCategoryCode: '',
                            classCode: '',
                            subClassCode: '',
                        } : product)
                    ),
                })
                break
            case 'subCategoryCode':
                this.setState({
                    products: products.map((product, i) =>
                        (i === index ? {
                            ...product,
                            subCategoryCode: value,
                            classCode: '',
                            subClassCode: '',
                        } : product)
                    ),
                })
                break
            case 'classCode':
                this.setState({
                    products: products.map((product, i) =>
                        (i === index ? {
                            ...product,
                            classCode: value,
                            subClassCode: '',
                        } : product)
                    ),
                })
                break
            default:
                this.setState({
                    products: products.map((product, i) => (i === index ? { ...product, [key]: value } : product)),
                })
        }
    }

    searchProduct = async (row, index) => {
        const { productId, outletId } = row
        if (!productId) {
            message.error('กรุณาระบุรหัสสินค้า')
            return
        }
        this.setState({ loading: true })
        const { auth } = this.props

        const body = {
            productId,
            outletId,
            storageLocationIds: auth.arrayStorageLocationId,
            productStatus: true
        }

        await apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCT}?getBy=${auth.userId}`, body).then(res => {
            const product = get(res, 'data.results.0')
            if (res.status === 200 && !isEmpty(product)) {
                this.setState({ indexRowAction: index }, () => {
                    this.onSelectedProduct(product)
                })
            } else {
                message.error('ไม่พบข้อมูลสินค้า')
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลสินค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    onSelectedProduct = (productSelected) => {
        const { indexRowAction, products } = this.state

        this.setState({
            products: products.map((product, i) =>
                (i === indexRowAction ? {
                    ...product,
                    index: i,
                    productId: productSelected.productId,
                    productName: productSelected.productNameMarket,
                    categoryCode: productSelected.category,
                    subCategoryCode: productSelected.subCategory,
                    classCode: productSelected.classCode,
                    subClassCode: productSelected.subClass,
                } : product)
            ),
        })
        this.closeModal()
    }

    openModalProductHierarchy = (type = '', row, index) => {
        const {
            productCategories,
        } = this.state

        let productHierarchies = []
        let textFilter = ''
        switch (type) {
            case 'categoryCode':
                productHierarchies = productCategories
                break
            case 'subCategoryCode':
                productHierarchies = this.filterSubCatagories((row.categoryCode || '').substring(0, 2))
                break
            case 'classCode':
                if (row.subCategoryCode) textFilter = (row.subCategoryCode || '').substring(0, 4)
                else if (row.categoryCode) textFilter = (row.categoryCode || '').substring(0, 2)
                productHierarchies = this.filterClasses(textFilter)
                break
            case 'subClassCode':
                if (row.classCode) textFilter = (row.classCode || '').substring(0, 6)
                else if (row.subCategoryCode) textFilter = (row.subCategoryCode || '').substring(0, 4)
                else if (row.categoryCode) textFilter = (row.categoryCode || '').substring(0, 2)
                productHierarchies = this.filterSubClasses(textFilter)
                break
            default:
                break
        }

        this.setState({
            productHierarchies,
            productHierarchyKey: type,
        }, () => {
            this.openModal('productHierarchy', index)
        })
    }

    closeModalProductHierarchy = () => {
        this.setState({
            productHierarchies: [],
            productHierarchyKey: '',
        })
        this.closeModal()
    }

    onSelectedProductHierarchy = (data) => {
        const { productHierarchyKey, indexRowAction } = this.state
        this.changeProductData(productHierarchyKey, data[productHierarchyKey], indexRowAction)
        this.closeModalProductHierarchy()
    }

    openModal = (modalType, indexRowAction = null) => {
        this.setState({ isShowModal: true, modalType, indexRowAction })
    }

    closeModal = () => {
        this.setState({ isShowModal: false, modalType: '', indexRowAction: null })
    }

    onSelectedProductsDuplicate = (productsDup) => {
        this.closeModalConfirmProductsDuplicate(true)

        if (!isEmpty(productsDup)) {
            const productsMapped = this.state.products.map((product, index) => {
                const duplicate = productsDup.find(productDup => productDup.index === index)
                return duplicate ? duplicate : product
            })
            this.setState({ products: productsMapped }, () => {
                this.createProductMargin()
            })
        }
    }

    validateData = () => {
        const { products } = this.state
        const newProducts = products.filter(product => product.outletId)

        // newProducts.splice(-1, 1)
        let result = { isError: false, message: '' }
        for (const product of newProducts) {

            if (!product.outletId) {
                result = { isError: true, message: 'กรุณาระบุร้าน' }
                break
            }
            // if (!product.productId || !product.productName) {
            //     result = { isError: true, message: 'กรุณาระบุสินค้าที่มีในระบบ' }
            //     break
            // }
            if (product.percentSharing === "") {
                result = { isError: true, message: 'กรุณาระบุ Percent Sharing' }
                break
            }

            if (product.percentSharing < 0) {
                result = { isError: true, message: 'Percent Sharing ต้องไม่ติดลบ' }
                break
            }
        }

        return result
    }

    getBody = () => {
        const { auth } = this.props
        const { products, isEditMode } = this.state
        // const newProducts = [...products]
        // newProducts.splice(-1, 1)
        const newProducts = products.filter(product => product.outletId)

        return newProducts.map(product => {
            const data = {
                outletId: product.outletId,
                saleChannelCode: product.saleChannelCode,
                category: product.categoryCode,
                subCategoryCode: product.subCategoryCode,
                classCode: product.classCode,
                subClassCode: product.subClassCode,
                productId: product.productId,
                percentSharing: product.percentSharing,
                changedBy: auth.userId,
                deleteFlag: product.deleteFlag,
                updateFlag: product.deleteFlag ? true : isBoolean(product.updateFlag) ? product.updateFlag : null,
                duplicateFlag: isEditMode ? true : isBoolean(product.duplicateFlag) ? product.duplicateFlag : null,
            }
            if (isEditMode) {
                data.masterProfitSharingId = product.masterProfitSharingId
            }
            return data
        })
    }

    createProductMargin = async () => {
        const valid = this.validateData()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }
        // return
        this.setState({ loading: true })
        const body = this.getBody()
        const url = API_PATH.POST_CREATE_MASTER_PROFIT_SHARING
        await apiServices.callApi('post', url, body).then(res => {
            if (res.status === 200) {
                swal('Success', `บันทึกข้อมูลสำเร็จ`, 'success').then(() => {
                    this.props.history.push(`${ROUTH_PATH.REPORT_PRODUCT_PERCENT_MARGIN}`)
                })
            } else {
                throw res
            }
        }).catch(error => {
            const results = get(error, 'response.data.results', [])
            if (!isEmpty(results)) {
                this.handleDuplicateRows(results)
            } else {
                const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการสร้างข้อมูล กรุณาลองใหม่อีกครั้ง')
                swal('Error', message, 'error')
            }
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    handleDuplicateRows = (results) => {
        const productsDuplicate = []
        const newProducts = [...this.state.products]
        const productsMapped = newProducts.map((product, index) => {
            const result = results[index]
            if (result) {
                const data = {
                    ...product,
                    ...result,
                    index,
                }
                if (result.duplicateFlag) {
                    productsDuplicate.push(data)
                }
                return data
            } else {
                return product
            }
        })

        this.setState({
            products: productsMapped,
            productsDuplicate: this.mappingProducts(productsDuplicate),
        }, () => {
            this.openModal('productDuplicate')
        })
    }

    closeModalConfirmProductsDuplicate = (isSelected) => {
        if (isSelected !== true) {
            this.resetStatusProducts()
        }
        this.closeModal()
    }

    resetStatusProducts = () => {
        const products = this.state.products.map((product) => ({
            ...product,
            updateFlag: null,
            duplicateFlag: null,
        }))

        this.setState({ products })
    }

    mappingProducts = (products) => {
        const {
            productCategories,
            productSubCategories,
            productClasses,
            productSubClasses,
            outlets,
            saleChannels,
        } = this.state

        return products.map(product => {
            const categoryData = productCategories.find(category => category.categoryCode === product.category) || {}
            const subCategoryData = productSubCategories.find(subCategory => subCategory.subCategoryCode === product.subCategoryCode) || {}
            const classData = productClasses.find(classData => classData.classCode === product.classCode) || {}
            const subClassData = productSubClasses.find(subClassData => subClassData.subClassCode === product.subClassCode) || {}
            const saleChannelData = saleChannels.find(saleChannel => saleChannel.saleChannelCode === product.saleChannelCode) || {}
            const outletData = outlets.find(outlet => outlet.outletId === product.outletId) || {}

            return {
                ...product,
                outletName: outletData.outletName || '',
                saleChannelDescription: saleChannelData.saleChannelDescription || '',
                categoryDescription: categoryData.descriptionThai || '',
                subCategoryDescription: subCategoryData.descriptionThai || '',
                classDescription: classData.descriptionThai || '',
                subClassDescription: subClassData.descriptionThai || '',
            }
        })
    }

    render() {
        const {
            columns,
            loading,
            isShowModal,
            modalType,
            products,
            permissionPage,
            indexRowAction,
            isEditMode,
            productsDuplicate,
            productHierarchies,
            productHierarchyKey,
        } = this.state

        return (
            <div className="product-percent-margin-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text={(isEditMode) ? `แก้ไข Percent Margin` : `สร้าง Percent Margin`}
                        icon="percentage"
                    />
                    <div className="middle-content">
                        <div className="table-layout">
                            <TableCustom
                                columns={columns}
                                data={products}
                                isFullContent
                                rowKey={(row, index) => index}
                                small
                                pagination={false}
                            />
                        </div>
                        <Row gutter={15}>
                            <Col sm={24} className="search-box text-right">
                                <div className="action-box">
                                    <div className="button">
                                        <ButtonCustom
                                            text="บันทึก"
                                            icon="save"
                                            onClick={this.createProductMargin}
                                            disabled={!permissionPage.authCreate || !permissionPage.authUpdate}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {indexRowAction !== null && <ProductSearchModal
                        isShowModal={isShowModal && modalType === 'product'}
                        onCloseModal={this.closeModal}
                        onSelected={this.onSelectedProduct}
                        outletId={get(products, `[${indexRowAction}].outletId`, '')}
                    />}
                    {!isEmpty(productsDuplicate) && isShowModal && modalType === 'productDuplicate'
                        && <ConfirmProductModal
                            isShowModal={isShowModal && modalType === 'productDuplicate'}
                            onCloseModal={this.closeModalConfirmProductsDuplicate}
                            onSelected={this.onSelectedProductsDuplicate}
                            products={productsDuplicate}
                        />
                    }
                    <ProductHierarchyModal
                        isShowModal={isShowModal && modalType === 'productHierarchy'}
                        onCloseModal={this.closeModalProductHierarchy}
                        onSelected={this.onSelectedProductHierarchy}
                        data={productHierarchies}
                        productHierarchyKey={(productHierarchyKey || '').replace(/Code$/, '')}
                    />
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        reportProductSharings: state.reportProductSharings,
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData,
        resetReportProductSharings,
        setReportProductSharings,
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPercentMarginMaintainContainer)